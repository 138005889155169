import React, { createContext, useContext, useState } from "react";

interface AudioPlayerContextType {
  isPlaying: boolean;
  setIsPlaying: (isPlaying: boolean) => void;
  isLoaded: boolean;
  setIsLoaded: (isLoaded: boolean) => void;
  elapsedTimeInSeconds: number;
  setElapsedTimeInSeconds: (seconds: number) => void;
  totalDurationInSeconds: number;
  setTotalDurationInSeconds: (seconds: number) => void;
  activeAudioTime: number;
  setActiveAudioTime: (time: number) => void;
  seekToProgress?: number;
  setSeekToProgress: (fraction?: number) => void;
  markers: number[];
  setMarkers: (markers: number[]) => void;
  handleMarkerClick: Function;
  setHandleMarkerClick: (handleClick: Function) => void;
}

export const AudioPlayerContext = createContext<AudioPlayerContextType>({
  isPlaying: false,
  setIsPlaying: () => {},
  isLoaded: false,
  setIsLoaded: () => {},
  elapsedTimeInSeconds: 0,
  setElapsedTimeInSeconds: () => {},
  totalDurationInSeconds: 0,
  setTotalDurationInSeconds: () => {},
  activeAudioTime: 0,
  setActiveAudioTime: () => {},
  seekToProgress: undefined,
  setSeekToProgress: () => {},
  markers: [],
  setMarkers: () => {},
  handleMarkerClick: () => {},
  setHandleMarkerClick: () => {},
});

export function AudioPlayerProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [elapsedTimeInSeconds, setElapsedTimeInSeconds] = useState(0);
  const [totalDurationInSeconds, setTotalDurationInSeconds] = useState(0);
  const [activeAudioTime, setActiveAudioTime] = useState(0);
  const [seekToProgress, setSeekToProgress] = useState<number>();
  const [markers, setMarkers] = useState<number[]>([]);
  const [handleMarkerClick, setHandleMarkerClick] = useState<Function>(
    () => {}
  );

  return (
    <AudioPlayerContext.Provider
      value={{
        isPlaying,
        setIsPlaying,
        isLoaded,
        setIsLoaded,
        elapsedTimeInSeconds,
        setElapsedTimeInSeconds,
        totalDurationInSeconds,
        setTotalDurationInSeconds,
        activeAudioTime,
        setActiveAudioTime,
        seekToProgress,
        setSeekToProgress,
        markers,
        setMarkers,
        handleMarkerClick,
        setHandleMarkerClick,
      }}
    >
      {children}
    </AudioPlayerContext.Provider>
  );
}

export const useAudioPlayerContext = () => useContext(AudioPlayerContext);
