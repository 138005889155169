import React, { useEffect, useMemo } from 'react';

import { datadogRum } from '@datadog/browser-rum';
import Head from 'next/head';

import useProject from 'hooks/useProject';
import { APP_NAME } from 'pages/_app';

interface HelmetProps {
  title?: string;
}

const useSiteTitle = (title?: string) => {
  const { project } = useProject();

  return useMemo(
    () =>
      [APP_NAME, project?.name ?? '', title]
        .filter((x) => x)
        .slice(-3)
        .join(' - '),
    [project?.name, title],
  );
};

const Helmet = ({ title }: HelmetProps) => {
  const siteTitle = useSiteTitle(title);

  useEffect(() => {
    if (title) {
      datadogRum.startView(title);
    }
  }, [title]);

  return (
    <Head>
      <title>{siteTitle}</title>
    </Head>
  );
};

export default Helmet;
