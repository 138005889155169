import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import api from 'api';
import { useToast } from 'hooks/useToast';

const useAsrAnnotationsTasks = () => {
  const toast = useToast();

  const queryKey = ['asrAnnotationsTasks'];

  const { data: asrAnnotationTasks, isLoading: isLoadingAsrAnnotationTasks } =
    useQuery(queryKey, () => api.getAllAsrAnnotationTasks(), {
      retry: false,
      onError: () => {
        toast.error({
          title: 'Failed to fetch ASR annotation tasks',
        });
      },
    });

  return useMemo(
    () => ({
      asrAnnotationTasks: asrAnnotationTasks ?? [],
      isLoadingAsrAnnotationTasks,
    }),
    [asrAnnotationTasks, isLoadingAsrAnnotationTasks],
  );
};

export default useAsrAnnotationsTasks;
