import { motion } from "framer-motion";
import styled, { css } from "styled-components";

import Clickable from "components/atoms/Clickable";
import { Body, BodyStrong } from "components/atoms/Text";

import themeColors from "styles/config/colors";
import themeSpacing from "styles/config/spacing";

const noOverflowMixin = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ActiveNavText = styled(BodyStrong)`
  ${noOverflowMixin}
  color: ${themeColors.textPrimaryInverse};
`;

export const NavText = styled(Body)`
  ${noOverflowMixin}
`;

export const NavClickable = styled(Clickable)`
  display: flex;
  align-items: center;
`;

export const NavSectionItemContainer = styled(motion.li)`
  display: flex;
  justify-content: space-between;
  padding-top: ${themeSpacing.sm1};
  list-style: none;

  & .mainNavContent {
    svg {
      flex-shrink: 0;
      margin-right: ${themeSpacing.sm1};
    }
  }
`;
