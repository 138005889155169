import styled, { css } from "styled-components";
import { ThemeSet } from "styled-theming";

import { Body } from "components/atoms/Text";

import themeColors from "styles/config/colors";
import themeSpacing from "styles/config/spacing";

export const TagContainer = styled.div<{ $maxWidth?: number }>`
  display: flex;
  flex: 1 1 0;

  ${({ $maxWidth }) =>
    css`
      max-width: ${$maxWidth ? `${$maxWidth}px` : "100%"};
    `}
`;

interface TagProps {
  tag?: string;
  customColour?: boolean;
  defaultColour?: TagColour;
}

const defaultStyle = {
  background: themeColors.backgroundPage,
  color: themeColors.textSecondary,
};

export const Tag = styled(Body)<TagProps>`
  margin-right: 5px;
  padding: ${themeSpacing.xs1} ${themeSpacing.xs2};

  font-size: ${themeSpacing.xs3};
  font-weight: 600;
  color: ${({ tag, customColour, defaultColour = defaultStyle }) =>
    customColour && tag ? tagStyles[tag].color : defaultColour.color};
  text-wrap: nowrap;

  background: ${({ tag, customColour, defaultColour = defaultStyle }) =>
    customColour && tag ? tagStyles[tag].background : defaultColour.background};
  border: 1px solid ${themeColors.borderStrong};
  border-radius: ${themeSpacing.radiusSmall};
`;

export interface TagColour {
  background: ThemeSet;
  color: ThemeSet;
}

// Custom tag styles mapping to specific tags

const tagStyles: { [key: string]: TagColour } = {
  "no audio": {
    background: themeColors.backgroundDanger,
    color: themeColors.textPrimaryInverse,
  },
  unmapped: {
    background: themeColors.backgroundAccent,
    color: themeColors.textSecondary,
  },
  slot: {
    background: themeColors.backgroundInformation,
    color: themeColors.textPrimaryInverse,
  },
};
