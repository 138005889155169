export const DOCS_HOME =
  'https://docs.poly.ai/introduction/what-is-polyai-jupiter';
export const DOCS_GET_STARTED =
  'https://docs.poly.ai/get-started/account-creation/introduction';
export const DOCS_TWILIO_INTEGRATION =
  'https://docs.poly.ai/telephony/twilio-how-to-integrate-voice';
export const DOCS_FUNCTION_LIBRARY =
  'https://docs.poly.ai/function/import-library';
export const DOCS_TOS = 'https://docs.poly.ai/legal/terms-of-service';
export const DOCS_PRIVACY = 'https://docs.poly.ai/legal/privacy-policy';
export const DOCS_CONTENT_FILTERS = '';
