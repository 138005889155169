import ReactPopup from 'reactjs-popup';
import styled from 'styled-components';

import Input from '@polyai/ui/components/atoms/Input';
import { Body, Headline } from '@polyai/ui/components/atoms/Text';
import themeColors from '@polyai/ui/styles/config/colors';
import themeSpacing from '@polyai/ui/styles/config/spacing';
import text from '@polyai/ui/styles/config/text';

export const FullscreenPopup = styled(ReactPopup)`
  &-content {
    overflow-y: scroll;
    overscroll-behavior: contain;
    display: flex;
    flex-direction: column;
    align-items: center;

    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: ${themeSpacing.lg1};

    opacity: 1;
    background: ${themeColors.backgroundPage};
  }
`;

export const QuestionnaireWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeSpacing.md1};

  width: 100%;
  max-width: 500px;
`;

export const WelcomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeSpacing.xs1};
`;

export const Title = styled(Headline)`
  font-size: ${text.size.body.body1};
`;

export const Text = styled(Body)`
  font-size: ${text.size.body.body3};
  color: ${themeColors.textSecondary};
`;

export const Questions = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeSpacing.sm3};
`;

export const TextInput = styled(Input)`
  background-color: ${themeColors.background};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
`;
