import ENDPOINTS from 'api/endpoints';
import { doDelete, doGet, doPost } from 'api/request';
import { LOGGER_TYPE } from 'api/types';
import { generateNextPath } from 'lib/routeHelper';
import { store } from 'reduxStore';
import {
  FunctionBuiltins,
  FunctionCall,
  FunctionExecutionRequest,
  FunctionExecutionResponse,
  FunctionsDeployResponse,
  GetFunctionsResponse,
  PostFunction,
} from './types';

export const getFunctions = () => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  return doGet<GetFunctionsResponse>(
    generateNextPath(ENDPOINTS.FUNCTIONS, {
      accountId,
      projectId,
    }),
    LOGGER_TYPE.JUPITER,
    { version: 'v2' },
  );
};

export const getFunction = (functionId: string) => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  return doGet<FunctionCall>(
    generateNextPath(ENDPOINTS.FUNCTION, { accountId, projectId, functionId }),
    LOGGER_TYPE.JUPITER,
    { version: 'v2' },
  );
};

export const postFunction = (func: PostFunction) => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  return doPost<FunctionCall>(
    generateNextPath(ENDPOINTS.FUNCTIONS, {
      accountId,
      projectId,
    }),
    LOGGER_TYPE.JUPITER,
    func,
    { version: 'v2' },
  );
};

export const duplicateFunction = (functionId: string) => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  return doPost<FunctionCall>(
    generateNextPath(ENDPOINTS.DUPLICATE_FUNCTION, {
      accountId,
      projectId,
      functionId,
    }),
    LOGGER_TYPE.JUPITER,
    {},
    { version: 'v2' },
  );
};

export const deleteFunction = (functionId: string) => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  return doDelete(
    generateNextPath(ENDPOINTS.FUNCTION, { accountId, projectId, functionId }),
    LOGGER_TYPE.JUPITER,
    {},
    { version: 'v2' },
  );
};

export const executeFunction = ({
  accountId,
  projectId,
  functionId,
  body,
}: {
  accountId: string;
  projectId: string;
  functionId: string;
  body: FunctionExecutionRequest;
}) => {
  return doPost<FunctionExecutionResponse>(
    generateNextPath(ENDPOINTS.EXECUTE_FUNCTION, {
      accountId,
      projectId,
      functionId,
    }),
    LOGGER_TYPE.JUPITER,
    body,
    { version: 'v2' },
  );
};

export const deployFunctions = ({
  accountId,
  projectId,
}: {
  accountId: string;
  projectId: string;
}) => {
  return doPost<FunctionsDeployResponse>(
    generateNextPath(ENDPOINTS.DEPLOY_FUNCTIONS, { accountId, projectId }),
    LOGGER_TYPE.JUPITER,
    {},
    { version: 'v2' },
  );
};

export const getPythonVersionZip = () => {
  return doGet<Blob>('', LOGGER_TYPE.JUPITER, {
    downloadingFile: true,
    overwriteUrl: '/code-editor/stdlib.zip',
    noAuth: true,
  });
};

export const getFunctionCustomLib = (params: {
  accountId: string;
  projectId: string;
  functionId?: string;
}) => {
  const { functionId, ...urlParams } = params;
  return doGet<FunctionBuiltins>(
    generateNextPath(ENDPOINTS.FUNCTION_CUSTOM_LIB, urlParams, {
      function_id: functionId,
    }),
    LOGGER_TYPE.JUPITER,
    { version: 'v2' },
  );
};

export const getGlobalFunctionLib = () => {
  return doGet<FunctionBuiltins>(
    generateNextPath(ENDPOINTS.FUNCTIONS_LIB),
    LOGGER_TYPE.JUPITER,
    { version: 'v2' },
  );
};
