import { ClientEnv, JupiterClientEnvironment } from 'constants/Chat.constants';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { createNewCall, projectInit } from 'actions';

export interface CallPanelState {
  isCallPanelOpen: boolean;
  clientEnv: ClientEnv;
}

const initialState: CallPanelState = {
  isCallPanelOpen: false,
  clientEnv: JupiterClientEnvironment.DRAFT,
};

const callPanelSlice = createSlice({
  name: 'callPanel',
  initialState,
  reducers: {
    openCallPanel: (state) => {
      state.isCallPanelOpen = true;
    },
    closeCallPanel: (state) => {
      state.isCallPanelOpen = false;
    },
    toggleCallPanel: (state) => {
      state.isCallPanelOpen = !state.isCallPanelOpen;
    },
    setClientEnv: (state, { payload: clientEnv }: PayloadAction<ClientEnv>) => {
      state.clientEnv = clientEnv;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(projectInit, () => {
        return { ...initialState };
      })
      .addCase(createNewCall, (state) => {
        state.isCallPanelOpen = true;
      });
  },
});

export const { openCallPanel, closeCallPanel, toggleCallPanel, setClientEnv } =
  callPanelSlice.actions;

export default callPanelSlice.reducer;
