import { SUPPORT_EMAIL } from 'constants/EmailUs.constants';

import Callout, { CalloutType } from '@polyai/ui/components/atoms/Callout';
import Select from '@polyai/ui/components/atoms/Select';
import Tag from '@polyai/ui/components/atoms/Tag';
import { Title } from '@polyai/ui/components/atoms/Text';
import { THEME_NAMES } from '@polyai/ui/styles/config/theme.constants';

import { setTheme } from 'ducks/layout/layoutSlice';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useUserAccess } from 'hooks/useProjectResources';
import { ACCOUNT_MODAL } from '../AccountModal.uiCopy';

import * as Styled from './AccountModalContent.styled';

const THEME_OPTIONS = [
  { label: 'Default', value: THEME_NAMES.NEW },
  { label: 'Studio', value: THEME_NAMES.DEFAULT },
  { label: 'Barbie', value: THEME_NAMES.BARBIE },
];

type AccountModalContentProps = {
  firstName?: string;
  lastName?: string;
  email?: string;
};

const AccountModalContent: React.FC<AccountModalContentProps> = ({
  firstName,
  lastName,
  email,
}) => {
  const dispatch = useAppDispatch();
  const themeName = useAppSelector((state) => state.layout.theme);

  const { isInternalUser } = useUserAccess();

  const currentThemeOption = THEME_OPTIONS.find(
    (option) => option.value === themeName,
  );

  const handleChangeTheme = (value: THEME_NAMES) => {
    dispatch(setTheme(value));
  };

  return (
    <Styled.Wrapper>
      <Styled.List>
        {[
          {
            title: ACCOUNT_MODAL.details.firstName,
            value: firstName,
          },
          {
            title: ACCOUNT_MODAL.details.lastName,
            value: lastName,
          },
          {
            title: ACCOUNT_MODAL.details.password,
            value: '●●●●●●●●',
          },
          {
            title: ACCOUNT_MODAL.details.email,
            value: email,
          },
        ].map(({ title, value }, index) => (
          <Styled.Item key={index}>
            <Styled.ItemTitle as="dt">{title}</Styled.ItemTitle>
            <Styled.ItemValue
              $letterSpacingExtraLoose={
                title === ACCOUNT_MODAL.details.password
              }
              as="dd"
            >
              {value}
            </Styled.ItemValue>
          </Styled.Item>
        ))}
      </Styled.List>

      {/* 🪦 faiz: 2022-2024 */}
      {isInternalUser && (
        <Select<{ label: string; value: THEME_NAMES }, false>
          formatOptionLabel={(option) => (
            <Styled.ThemeOption>
              <Title>{option.label}</Title>
              {option.value === THEME_NAMES.BARBIE && (
                <Tag>
                  {' '}
                  {String.fromCodePoint(
                    parseInt('1F9EA', 16),
                  )} Experimental{' '}
                </Tag>
              )}
            </Styled.ThemeOption>
          )}
          label="Theme"
          options={THEME_OPTIONS}
          value={currentThemeOption}
          onChange={(option) => handleChangeTheme(option?.value as THEME_NAMES)}
        />
      )}

      <Callout isVisibleBorder={false} type={CalloutType.INFO}>
        {ACCOUNT_MODAL.helpText.updateInfo(
          <Styled.SupportEmail href={`mailto:${SUPPORT_EMAIL}`}>
            {SUPPORT_EMAIL}
          </Styled.SupportEmail>,
        )}
      </Callout>
    </Styled.Wrapper>
  );
};

export default AccountModalContent;
