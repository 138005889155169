import React from "react";

import { ColorName } from "styles/config/colors";
import { InfoIcon } from "../Icons";
import Tooltip from "../Tooltip";

import * as Styled from "./Tag.styled";

export enum TagVariant {
  DEFAULT = "DEFAULT",
  ACTIVE = "ACTIVE",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
  KEYWORD = "KEYWORD",
  INFO = "INFO",
}

export interface TagProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  append?: React.ReactNode;
  prepend?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  textColor?: ColorName;
  variant?: TagVariant;
  iconOnly?: boolean;
  infoContent?: React.ReactNode;
  hideBorder?: boolean;
}

const Tag: React.FC<TagProps> = ({
  append,
  prepend,
  onClick,
  children,
  textColor,
  variant = TagVariant.DEFAULT,
  iconOnly,
  infoContent,
  hideBorder,
  ...props
}) => {
  return (
    <Styled.TagWrapper
      $hideBorder={hideBorder}
      $iconOnly={iconOnly}
      append={!!append}
      clickable={!!onClick}
      variant={variant}
      onClick={onClick}
      {...props}
    >
      {prepend}
      <Styled.TagText $color={textColor} data-test-id="tag-text">
        {children}
      </Styled.TagText>
      {append}
      {variant === TagVariant.INFO && infoContent && (
        <Tooltip content={infoContent} placement="top" complex>
          <InfoIcon color="iconInfo" />
        </Tooltip>
      )}
    </Styled.TagWrapper>
  );
};

export default Tag;
