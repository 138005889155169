import styled, { css } from "styled-components";

import { Caption, Title as BaseTitle } from "components/atoms/Text";

import themeColors from "styles/config/colors";
import themeSpacing from "styles/config/spacing";
import text from "styles/config/text";

const CARD_WIDTH = 320;

export const CardWrapper = styled.div<{
  $selected?: boolean;
  $fullWidth?: boolean;
  onClick?: () => void;
}>`
  width: ${({ $fullWidth }) => ($fullWidth ? "100%" : `${CARD_WIDTH}px`)};
  padding: ${themeSpacing.sm1};

  background: ${themeColors.background};
  border: 1px solid transparent;
  border-radius: ${themeSpacing.radiusMedium};

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;

      &:hover {
        background: ${themeColors.backgroundHover};
      }

      &:focus,
      &:active {
        border-color: ${themeColors.borderPrimary};
        outline: none;
      }
    `}

  ${({ $selected, onClick }) =>
    $selected &&
    css`
      background: ${themeColors.backgroundBrand};
      ${onClick &&
      css`
        &:hover,
        &:focus,
        &:active {
          background: ${themeColors.backgroundBrandHover};
        }
      `}
    `}
`;

export const CardTitleSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CardDescription = styled(Caption)`
  margin-top: ${themeSpacing.xs2};
  color: ${themeColors.textSecondary};
`;

export const TopLevelSection = styled.div`
  display: flex;
  gap: ${themeSpacing.xs3};
  align-items: center;
  min-height: 20px;
`;

export type TitleSize = "sm" | "md" | "lg";

export const Title = styled(BaseTitle)<{ $size?: TitleSize }>`
  font-size: ${({ $size = "sm" }) => {
    switch ($size) {
      case "sm":
        return text.size.heading.h3;
      case "md":
        return text.size.body.body1;
      case "lg":
        return text.size.heading.h1;
    }
  }};
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
