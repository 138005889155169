import ENDPOINTS from 'api/endpoints';
import { doGet, doPut } from 'api/request';
import { LOGGER_TYPE } from 'api/types';
import { generateNextPath } from 'lib/routeHelper';
import { store } from 'reduxStore';
import { Pronunciation, PronunciationsResponse } from './types';

export const getPronunciations = () => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  return doGet<PronunciationsResponse>(
    generateNextPath(ENDPOINTS.PRONUNCIATIONS, {
      accountId,
      projectId,
    }),
    LOGGER_TYPE.JUPITER,
    { version: 'v2' },
  );
};

export const putPronunciations = (pronunciations: Pronunciation[]) => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  return doPut<PronunciationsResponse>(
    generateNextPath(ENDPOINTS.PRONUNCIATIONS, {
      accountId,
      projectId,
    }),
    LOGGER_TYPE.JUPITER,
    { pronunciations },
    { version: 'v2' },
  );
};
