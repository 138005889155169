import ENDPOINTS from 'api/endpoints';
import {
  AnnotationTask,
  AsrAnnotationTaskResponse,
  BaseAsrAnnotationTasksResponse,
  PatchAnnotationTaskRequest,
} from 'api/internalResources/asrAnnotations/types';
import { doGet, doPatch } from 'api/request';
import { LOGGER_TYPE } from 'api/types';
import { generateNextPath } from 'lib/routeHelper';

export const getAsrAnnotationTasksById = (taskId: string) =>
  doGet<AsrAnnotationTaskResponse>(
    generateNextPath(ENDPOINTS.ASR_ANNOTATIONS_TASK_ID, { taskId }),
    LOGGER_TYPE.JUPITER,
  );

export const patchAsrAnnotationTask = (
  taskId: string,
  request: PatchAnnotationTaskRequest,
) =>
  doPatch<AnnotationTask>(
    generateNextPath(ENDPOINTS.ASR_ANNOTATIONS_ID, {
      taskId,
      annotationId: request.annotation_id,
    }),
    LOGGER_TYPE.JUPITER,
    {
      transcript: request.transcript,
      entities: request.entities,
      completed: request.completed,
    },
  );

export const getAllAsrAnnotationTasks = () =>
  doGet<BaseAsrAnnotationTasksResponse[]>(
    ENDPOINTS.ASR_ANNOTATIONS_TASKS,
    LOGGER_TYPE.JUPITER,
  );
