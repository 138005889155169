// merge two arrays of objects + dedupe using defined prop

import { matchPath } from 'react-router-dom';

import { PAGE_TITLES, PATHS } from 'routes/routes.constants';

export const getPageTab = (pathname: string) => {
  const paths = Object.entries(PATHS) as [keyof typeof PATHS, string][];
  const path = paths.find((p: [string, string]) =>
    matchPath({ path: p[1] }, pathname),
  );
  if (path?.length) {
    return PAGE_TITLES[path[0]];
  }
  return 'Unknown tab';
};

type RequiredNestedKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
    ? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
    : `${Key}`;
}[keyof ObjectType & (string | number)];

type NotArray<T> = T extends any[] ? never : T;

export type NestedKeyOf<ObjectType extends Partial<object>> =
  RequiredNestedKeyOf<Required<NotArray<ObjectType>>>;

export type NestedPropertyType<T, K extends string> = K extends keyof T
  ? T[K]
  : K extends `${infer FirstPart}.${infer Rest}`
  ? FirstPart extends keyof T
    ? NestedPropertyType<Exclude<T[FirstPart], undefined>, Rest>
    : never
  : never;

export type PropValueUpdater<T extends Partial<object>> = (
  p: NestedKeyOf<T>,
  v: NestedPropertyType<T, NestedKeyOf<T>>,
) => void;

export const updateNestedProperty = <
  T extends { [key: string]: any },
  P extends NestedKeyOf<T>,
>(
  object: T,
  propertyPath: P,
  value: NestedPropertyType<T, P>,
): T => {
  const properties = propertyPath.split('.');
  const property = properties.shift();

  if (!property) {
    return object;
  }

  let updatedObject = object;
  if (properties.length === 0) {
    updatedObject = {
      ...object,
      [property]: value,
    };
  } else {
    updatedObject = {
      ...object,
      [property]: updateNestedProperty(
        object[property] || {},
        properties.join('.'),
        value,
      ),
    };
  }

  return updatedObject as T;
};

export const pick = <T extends { [key: string]: any }, K extends keyof T>(
  obj: T,
  ...props: K[]
): Pick<T, K> => {
  return props.reduce(
    (picked, key) => ({
      ...picked,
      [key]: obj[key],
    }),
    {} as Pick<T, K>,
  );
};
