import { ClientEnv, JupiterClientEnvironment } from 'constants/Chat.constants';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { createNewChat, projectInit } from 'actions';

export interface ChatPanelState {
  isChatPanelOpen: boolean;
  clientEnv: ClientEnv;
}

const initialState: ChatPanelState = {
  isChatPanelOpen: false,
  clientEnv: JupiterClientEnvironment.DRAFT,
};

const chatPanelSlice = createSlice({
  name: 'chatPanel',
  initialState,
  reducers: {
    openChatPanel: (state) => {
      state.isChatPanelOpen = true;
    },
    closeChatPanel: (state) => {
      state.isChatPanelOpen = false;
    },
    toggleChatPanel: (state) => {
      state.isChatPanelOpen = !state.isChatPanelOpen;
    },
    setClientEnv: (state, { payload: clientEnv }: PayloadAction<ClientEnv>) => {
      state.clientEnv = clientEnv;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(projectInit, () => {
        return { ...initialState };
      })
      .addCase(createNewChat, (state) => {
        state.isChatPanelOpen = true;
      });
  },
});

export const { openChatPanel, closeChatPanel, toggleChatPanel, setClientEnv } =
  chatPanelSlice.actions;

export default chatPanelSlice.reducer;
