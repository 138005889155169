import { AccountModalUICopy } from './types';

export const ACCOUNT_MODAL: AccountModalUICopy = {
  title: 'Account',
  button: {
    confirm: 'Close',
  },
  details: {
    firstName: 'First Name',
    lastName: 'Last Name',
    password: 'Password',
    email: 'Email',
  },
  helpText: {
    updateInfo: (supportEmail) => (
      <>To update any information, please email us at {supportEmail}.</>
    ),
  },
};
