import styled from 'styled-components';

import { Body } from 'components/atoms/Text';

import themeColors from 'styles/config/colors';
import sizes from 'styles/config/sizes';
import themeSpacing from 'styles/config/spacing';
import text from 'styles/config/text';
import { AlertType } from './types';

export const AlertContainer = styled.div<{ type?: AlertType }>`
  display: flex;
  gap: ${themeSpacing.xs3};
  align-items: flex-start;

  width: ${sizes.alertWidth}px;
  height: auto;
  padding: ${themeSpacing.sm1} ${themeSpacing.sm2};

  color: ${themeColors.backgroundHover};
  white-space: pre-line;

  background-color: ${themeColors.backgroundInverse};
  border-radius: ${themeSpacing.radiusMedium};
`;

export const AlertIcon = styled.div`
  display: flex;
  align-items: center;
`;

export const AlertBody = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: flex-start;

  p {
    width: 100%;
    word-break: break-word;
  }
`;

export const MessageContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${themeSpacing.xs1};
`;

export const MessageBody = styled(Body)`
  margin-top: ${themeSpacing.xs1};
  font-size: ${text.size.body.body3};
`;

export const MessageList = styled.ul`
  list-style: outside;
`;

export const ActionButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: ${themeSpacing.xs2};
`;
