import { doPost, doPut } from 'api/request';
import { LOGGER_TYPE, TuningSetting } from 'api/types';
import { store } from 'reduxStore';
import { AudioGenerationConfig, AudioGenerationResponse } from './types';

export const editVoiceTuningSettings = ({
  voiceId,
  settings,
}: {
  voiceId: string;
  settings: TuningSetting;
}) => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;
  const path = `accounts/${accountId}/projects/${projectId}/voices/${voiceId}`;
  return doPut<TuningSetting>(
    path,
    LOGGER_TYPE.JUPITER,
    {
      similarity_boost: settings.similarity_boost,
      stability: settings.stability,
    },
    { version: 'v2' },
  );
};

export const generateAudio = ({
  text,
  language,
  config,
}: {
  text: string;
  language: string;
  config: AudioGenerationConfig;
}) => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;
  const path = `accounts/${accountId}/projects/${projectId}/generate-audio`;
  return doPost<AudioGenerationResponse>(
    path,
    LOGGER_TYPE.JUPITER,
    {
      text,
      config,
      language,
      store_on_s3: true,
    },
    { version: 'v2' },
  );
};
