import { create } from 'zustand';

type Readonly = {
  readonly: boolean;
};
type Preview = {
  preview: boolean;
};

type ReadonlyInternal = Readonly &
  Preview & {
    setReadOnly: (value: boolean) => void;
    setPreview: (value: boolean) => void;
  };

type SyncStore = {
  readonlyValue: boolean | undefined;
  previewValue: boolean | undefined;
};
export const syncStore = ({ readonlyValue, previewValue }: SyncStore) => {
  const { readonly, setReadOnly, preview, setPreview } =
    permissionStore.getState();
  if (readonly !== readonlyValue) {
    setReadOnly(!!readonlyValue);
  }
  if (preview !== previewValue) {
    setPreview(!!previewValue);
  }
};

const permissionStore = create<ReadonlyInternal>((set) => ({
  readonly: false,
  setReadOnly: (value: boolean) => set(() => ({ readonly: value })),
  preview: false,
  setPreview: (value: boolean) => set(() => ({ preview: value })),
}));

export const useViewMode = () => {
  const readonly = permissionStore((state) => state.readonly);
  const preview = permissionStore((state) => state.preview);
  return {
    readonly,
    preview,
    isEditable: !readonly && !preview,
  };
};
