import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import { isBillingEnabled } from 'components/molecules/UserBilling/billing.constants';

import api from 'api';
import { useAppSelector } from 'hooks/reduxHooks';
import useAccountRole from './useAccountRole';

export const getBalanceQueryKey = (accountId: string) => [accountId, 'balance'];

const useBalance = () => {
  const accountId = useAppSelector((state) => state.account.id);
  const projectId = useAppSelector((state) => state.project.id);

  const { isAccountAdmin } = useAccountRole();
  const enabled =
    isBillingEnabled &&
    (isAccountAdmin ? !!accountId : !!accountId && !!projectId);

  const { data: balance, isLoading: isLoadingBalance } = useQuery({
    queryFn: () => api.getBalance(),
    enabled,
    queryKey: getBalanceQueryKey(accountId),
  });

  const { data: balanceLogData, isLoading: isLoadingBalanceLog } = useQuery({
    queryFn: () => api.getBalanceLog(),
    enabled,
    queryKey: [accountId, 'balance-log'],
    refetchOnWindowFocus: 'always',
  });

  return useMemo(
    () => ({
      balance,
      isLoadingBalance,
      balanceLog: balanceLogData?.balance_log ?? [],
      isLoadingBalanceLog,
      isBillingEnabled,
    }),
    [balance, isLoadingBalance, balanceLogData, isLoadingBalanceLog],
  );
};

export default useBalance;
