import ENDPOINTS from 'api/endpoints';
import { doDelete, doGet, doPut } from 'api/request';
import { LOGGER_TYPE } from 'api/types';
import { generateNextPath } from 'lib/routeHelper';
import {
  AttributeRequest,
  AttributeResponse,
  VariantManagementResponse,
  VariantRequest,
  VariantResponse,
} from './types';

export const getAttributes = (params: {
  accountId: string;
  projectId: string;
}) => {
  return doGet<AttributeResponse[]>(
    generateNextPath(ENDPOINTS.ATTRIBUTES, params),
    LOGGER_TYPE.JUPITER,
    {
      version: 'v2',
    },
  );
};

export const getAttribute = (params: {
  accountId: string;
  projectId: string;
  attributeId: string;
}) => {
  return doGet<AttributeResponse>(
    generateNextPath(ENDPOINTS.ATTRIBUTE, params),
    LOGGER_TYPE.JUPITER,
    {
      version: 'v2',
    },
  );
};

export const putAttribute = ({
  body,
  ...params
}: {
  accountId: string;
  projectId: string;
  body: AttributeRequest;
}) => {
  return doPut<AttributeResponse>(
    generateNextPath(ENDPOINTS.ATTRIBUTES, params),
    LOGGER_TYPE.JUPITER,
    body,
    {
      version: 'v2',
    },
  );
};

export const deleteAttribute = (params: {
  accountId: string;
  projectId: string;
  attributeId: string;
}) => {
  return doDelete(
    generateNextPath(ENDPOINTS.ATTRIBUTE, params),
    LOGGER_TYPE.JUPITER,
    {},
    {
      version: 'v2',
    },
  );
};

export const getVariants = (params: {
  accountId: string;
  projectId: string;
}) => {
  return doGet<VariantResponse[]>(
    generateNextPath(ENDPOINTS.VARIANTS, params),
    LOGGER_TYPE.JUPITER,
    {
      version: 'v2',
    },
  );
};

export const getVariant = (params: {
  accountId: string;
  projectId: string;
  variantId: string;
}) => {
  return doGet<AttributeResponse>(
    generateNextPath(ENDPOINTS.VARIANT, params),
    LOGGER_TYPE.JUPITER,
    {
      version: 'v2',
    },
  );
};

export const putVariant = ({
  body,
  ...params
}: {
  accountId: string;
  projectId: string;
  body: VariantRequest;
}) => {
  return doPut<VariantResponse>(
    generateNextPath(ENDPOINTS.VARIANTS, params),
    LOGGER_TYPE.JUPITER,
    body,
    {
      version: 'v2',
    },
  );
};

export const deleteVariant = (params: {
  accountId: string;
  projectId: string;
  variantId: string;
}) => {
  return doDelete(
    generateNextPath(ENDPOINTS.VARIANT, params),
    LOGGER_TYPE.JUPITER,
    {},
    {
      version: 'v2',
    },
  );
};

export const getVariantManagement = (params: {
  accountId: string;
  projectId: string;
}) => {
  return doGet<VariantManagementResponse>(
    generateNextPath(ENDPOINTS.VARIANT_MANAGEMENT_VALUES, params),
    LOGGER_TYPE.JUPITER,
    {
      version: 'v2',
    },
  );
};
