import styled, { css } from "styled-components";

import { IconSize } from "components/atoms/Icons/BaseIcon";
import { PopoverContent as DefaultPopoverContent } from "components/atoms/Popover/PopoverContent";

import themeColors from "styles/config/colors";
import themeSpacing from "styles/config/spacing";
import rem from "styles/functions/rem";

const BASE_PADDING_LEFT = 16;

export const PopoverContent = styled(DefaultPopoverContent)`
  z-index: 1001;
  overflow-y: auto;
  color: ${themeColors.textPrimaryInverse};
  border-color: ${themeColors.textSecondary};
`;

export const TreeContainer = styled.div`
  position: relative;
`;

export const NodeMenuItem = styled.span<{ $depth: number }>`
  display: flex;
  gap: ${themeSpacing.xs2};
  align-items: center;

  padding: ${themeSpacing.xs2} ${themeSpacing.sm1};
  padding-left: ${({ $depth }) =>
    rem(BASE_PADDING_LEFT + IconSize.md * $depth)};

  ${({ $depth }) =>
    $depth === 0 &&
    css`
      border-top: 1px solid ${themeColors.borderStrongInverse};
    `}
`;

export const ParentNodeMenuItem = styled(NodeMenuItem)<{ $depth: number }>`
  ${({ $depth }) =>
    $depth === 0 &&
    css`
      /* overrides implicit first-child border-top being set to none  */
      border-top: 1px solid ${themeColors.borderStrongInverse} !important;
    `}

  /* the divider line indicating tree level */
  ::after {
    content: "";

    position: absolute;
    z-index: 1;
    bottom: ${themeSpacing.xs2};

    /* push to horizontally align with the parent checkbox above */
    left: ${({ $depth }) =>
      rem(BASE_PADDING_LEFT + (IconSize.md * ($depth + 1) - IconSize.md / 2))};

    /* trim line to fit under parent checkbox and take padding into account */
    height: calc(
      100% - ${IconSize.md}px - ${themeSpacing.sm1} - ${themeSpacing.xs2}
    );

    border-left: 1px solid ${themeColors.borderStrongInverse};
  }
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: inherit;

  /* prettier-ignore */
  > ${TreeContainer}:first-child > button ${ParentNodeMenuItem},
  > button:first-child ${ParentNodeMenuItem} {
    border-top: none;
  }
`;
