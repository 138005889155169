import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface TestAssistantState {
  hasUnsavedChanges: boolean;
}

const initialState: TestAssistantState = {
  hasUnsavedChanges: false,
};

const testAssistantSlice = createSlice({
  name: 'testAssistant',
  initialState,
  reducers: {
    setHasUnsavedChanges: (
      state,
      { payload: hasUnsavedChanges }: PayloadAction<boolean>,
    ) => {
      state.hasUnsavedChanges = hasUnsavedChanges;
    },
  },
});

export const { setHasUnsavedChanges } = testAssistantSlice.actions;

export default testAssistantSlice.reducer;
