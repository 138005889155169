import styled, { css } from 'styled-components';

import { Body } from '@polyai/ui/components/atoms/Text';
import themeSpacing from '@polyai/ui/styles/config/spacing';
import text from '@polyai/ui/styles/config/text';
import rem from '@polyai/ui/styles/functions/rem';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeSpacing.sm3};
  margin: ${themeSpacing.sm2} 0 ${themeSpacing.xs2};
`;

export const Item = styled.div`
  display: flex;
  grid-column: 1 / span 2;
  flex-direction: column;
  gap: ${themeSpacing.xs1};
`;

export const List = styled.dl`
  display: grid;
  grid-column: 1 / 3;
  grid-gap: ${themeSpacing.sm3} ${themeSpacing.sm2};

  /* first name */
  ${Item}:first-child {
    grid-column: 1 / 2;
  }

  /* last name */
  ${Item}:nth-child(2) {
    grid-column: 2 / 3;
  }
`;

export const ItemTitle = styled(Body)`
  font-size: ${text.size.body.body4};
  line-height: ${text.lineHeight.normal};
`;

export const ItemValue = styled(ItemTitle)<{
  $letterSpacingExtraLoose?: boolean;
}>`
  font-size: ${text.size.body.body3};
  font-weight: ${({ theme }) => theme.text.weight.semibold};
  ${({ $letterSpacingExtraLoose }) =>
    $letterSpacingExtraLoose &&
    css`
      letter-spacing: ${rem(1.25)};
    `}
`;

export const SupportEmail = styled.a`
  text-decoration: underline;
`;

export const ThemeOption = styled.div`
  display: flex;
  gap: ${themeSpacing.sm1};
  align-items: center;
`;
