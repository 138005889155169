import { featureToCopyMap } from 'constants/Features.constants';
import { Mutation } from '@tanstack/react-query';

import { Toast } from 'hooks/useToast';
import { HTTPError } from './error';

export const handleGlobalErrors = (
  error: HTTPError,
  mutation: Mutation<unknown, unknown, unknown, unknown>,
  toast: Toast,
) => {
  switch (error.code) {
    case 'UserAccessForbidden':
      const { action, resource } = error.data(error.code);
      const readableResource = featureToCopyMap[resource] || resource;

      toast.warning({
        title: 'Insufficient permissions',
        description: `You are unable to perform this action as you do not have ${action.toLowerCase()} access for ${readableResource}`,
      });

      // can't stop error propagation to lower-level onError
      // so setting it to undefined to stop it from doing anything
      mutation.setOptions({ onError: undefined });

      break;
    default:
      break;
  }
};
