import styled, { css } from "styled-components";

import IconButton from "components/atoms/IconButton";
import { KeyboardSafeTextArea } from "components/atoms/KeyboardSafeInputs";
import { BodyStrong, CaptionStrong, Headline } from "components/atoms/Text";

import breakpoints, { under } from "styles/config/breakpoints";
import themeColors, { baseColors } from "styles/config/colors";
import themeSpacing from "styles/config/spacing";
import text from "styles/config/text";

export const ScreenHeaderWrapper = styled.header<{
  $backButton: boolean;
  $minimized: boolean;
}>`
  display: flex;
  flex-direction: row;
  gap: ${themeSpacing.sm3};
  margin-bottom: ${themeSpacing.md1};

  @media ${under(breakpoints.sm)} {
    margin-bottom: ${themeSpacing.sm1};
  }

  ${({ $backButton }) =>
    $backButton &&
    css`
      a {
        display: flex;
        align-items: center;

        button {
          width: ${themeSpacing.md1};
          height: ${themeSpacing.md1};
          background-color: ${themeColors.backgroundPage};
        }
      }
    `}

  ${({ $minimized }) =>
    $minimized &&
    css`
      display: inline-flex;
      gap: 0;
      align-items: center;

      width: auto;
      padding: ${themeSpacing.xs2} ${themeSpacing.xs3};

      background-color: ${themeColors.background};
      border: 1px solid ${themeColors.borderStrong};
      border-radius: ${themeSpacing.radiusFull};
      box-shadow: 0 1px 1px ${themeColors.borderStrong};

      h1 {
        font-size: ${text.size.body.body2};
        font-weight: ${text.weight.bold};
      }
    `}
`;

export const Content = styled.div`
  overflow: hidden;
  width: 100%;
`;

export const HeadlineWrapper = styled.div<{
  $actions: boolean;
  $minimized: boolean;
}>`
  flex: 0;
  line-height: 100%;

  ${({ $actions }) =>
    $actions &&
    css`
      display: flex;
      align-items: center;

      button {
        display: flex;
        align-items: center;
      }
    `}

  ${({ $minimized }) =>
    $minimized &&
    css`
      button {
        cursor: pointer;
        display: flex;
        align-items: center;

        svg {
          margin-left: ${themeSpacing.xs2};
        }
      }
    `}
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const PopupButton = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderHeadline = styled(Headline)`
  margin-right: ${themeSpacing.xs2};
  white-space: wrap;
`;

export const DescriptionInputError = styled(BodyStrong)`
  padding-top: ${themeSpacing.xs1};
  color: ${themeColors.errorText01};
`;

export const Captions = styled.div`
  display: flex;
  gap: ${themeSpacing.xs1};
  align-items: center;
  margin-top: ${themeSpacing.xs1};
`;

export const Versioning = styled(CaptionStrong)`
  font-weight: ${text.weight.medium};
  color: ${themeColors.textSecondary};
`;

export const BackButton = styled(IconButton)`
  display: flex;
  align-items: start;
`;

const descriptionStyles = css<{ $error?: boolean }>`
  display: flex;

  width: 479px;
  max-width: 100%;
  height: unset;
  min-height: ${themeSpacing.sm3};
  margin-top: ${themeSpacing.sm1};
  padding-bottom: ${themeSpacing.xs3};

  font-size: ${text.size.body.body3};
  color: ${themeColors.textSecondary};

  &:focus {
    border-bottom: 1px solid ${themeColors.borderBrand};
  }

  @media ${under(breakpoints.sm)} {
    width: 100%;
  }

  ${({ $error }) =>
    $error &&
    css`
      border-bottom: 1px solid ${baseColors.red["20"]};
    `}
`;

export const Description = styled.p<{ $error?: boolean }>`
  ${descriptionStyles}
`;

export const DescriptionTextArea = styled(KeyboardSafeTextArea)<{
  $error: boolean;
}>`
  ${descriptionStyles}
`;
