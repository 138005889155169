import styled from 'styled-components';

import { Title as BaseTitle } from '@polyai/ui/components/atoms/Text';
import themeColors from '@polyai/ui/styles/config/colors';
import themeSpacing from '@polyai/ui/styles/config/spacing';
import text from '@polyai/ui/styles/config/text';

export const Container = styled.div`
  position: fixed;
  z-index: 100;
  inset: 0;
`;

export const DotsContainer = styled.div`
  position: fixed;
  z-index: -1;
  inset: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${themeColors.backgroundPage};
`;

export const DotPath = styled.path`
  fill: ${themeColors.borderSubtle};
`;

export const SvgTop = styled.svg`
  position: fixed;
  top: -150px;
  right: 0;
  left: -280px;
`;

export const SvgBottom = styled.svg`
  position: fixed;
  top: 50%;
  right: 0;
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex: 1 1;
  flex-direction: column;
  gap: ${themeSpacing.md2};
  align-items: center;
  justify-content: center;

  height: 100%;

  @media (max-width: ${({ theme }) => `${theme.breakpoints.md}px`}) {
    padding: ${themeSpacing.md2} ${themeSpacing.sm4};
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeSpacing.xs3};

  max-width: 420px;

  text-align: center;
`;

export const Title = styled(BaseTitle)`
  font-size: ${text.size.heading.h1};
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: ${themeSpacing.sm4};
  justify-content: center;
`;
