import { z } from 'zod';

export const DisclaimerServerSchema = z.object({
  is_enabled: z.boolean(),
  message: z.string().nullable(),
  ringtone: z.string().nullable(),
  voice_id: z.string().nullable(),
  ringtone_public_url: z.string().nullish(),
  ringtone_filename: z.string().nullish(),
});

type DisclaimerServerType = z.infer<typeof DisclaimerServerSchema>;

export type DisclaimerResponse = DisclaimerServerType;

export type DisclaimerRequest = Pick<DisclaimerServerType, 'is_enabled'> &
  Partial<Omit<DisclaimerServerType, 'is_enabled' | 'ringtone_public_url'>>;

export const AudioUploadResponseServerSchema = z.object({
  public_url: z.string(),
  s3_bucket: z.string(),
  s3_key: z.string(),
  recording_duration: z.number(),
});

export type AudioUploadResponse = z.infer<
  typeof AudioUploadResponseServerSchema
>;
