import styled from 'styled-components';

import CreateSelect from '@polyai/ui/components/atoms/CreateSelect';
import themeSpacing from '@polyai/ui/styles/config/spacing';

export const EntityCollectionWrapper = styled.div`
  margin-top: ${themeSpacing.sm3};
`;

export const EntityValueMultiInput = styled(CreateSelect)`
  width: 100%;
  margin-bottom: ${themeSpacing.xs2};
`;
