import styled, { css } from "styled-components";

export const Wrapper = styled.div<{ $isSidebarOpen?: boolean }>`
  width: 100%;
  max-width: ${({ theme }) => theme.sizes.gridContentWidth};
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacing.sm4};

  ${({ $isSidebarOpen }) =>
    $isSidebarOpen &&
    css`
      flex-basis: calc(100% - ${({ theme }) => theme.sizes.gridSidebarWidth}px);
      width: unset;

      @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
        flex-basis: 100%;
      }
    `};

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    padding: ${({ theme }) => theme.spacing.sm3};
  }
`;
