import React, { PropsWithChildren, Suspense } from 'react';

import ConditionalWrapper from '@polyai/common/utils/conditionalWrapper';
import GridContent from '@polyai/ui/components/atoms/GridContent';
import { Loader } from '@polyai/ui/components/atoms/Loader';

import Helmet from '../Helmet';

const LazyScreen = ({
  title,
  noWrap,
  children,
}: PropsWithChildren<{
  title?: string;
  noWrap?: boolean;
}>) => (
  <Suspense
    fallback={
      <GridContent>
        <Loader fullScreen spinner />
      </GridContent>
    }
  >
    <Helmet title={title} />
    <ConditionalWrapper
      condition={!noWrap}
      wrapper={(children) => <GridContent>{children}</GridContent>}
    >
      {children}
    </ConditionalWrapper>
  </Suspense>
);

export default LazyScreen;
