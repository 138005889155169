import { useEffect, useRef, useState } from "react";

import { safeResizeObserver } from "@polyai/common/utils/safeResizeObserver";

type SizeAttributes =
  | "offsetWidth"
  | "offsetHeight"
  | "clientWidth"
  | "clientHeight"
  | "scrollWidth"
  | "scrollHeight";

function useIsOverflowing<T extends HTMLElement = HTMLDivElement>(
  targetAttribute: SizeAttributes = "scrollWidth",
  maxWidth?: number
) {
  const targetRef = useRef<T>(null);
  const [isOverflow, setIsOverflow] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      if (targetRef.current) {
        setIsOverflow(
          targetRef.current[targetAttribute] >
            (maxWidth || targetRef.current.clientWidth)
        );
      } else {
        setIsOverflow(false);
      }
    };

    const targetObserver = safeResizeObserver(checkOverflow);

    if (targetRef.current) {
      targetObserver.observe(targetRef.current);
    }

    checkOverflow();

    return () => {
      targetObserver.disconnect();
    };
  }, [maxWidth, targetAttribute, targetRef]);

  return { isOverflow, targetRef };
}

export default useIsOverflowing;
