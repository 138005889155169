import { useMemo } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import useRequiredParams from '@polyai/common/hooks/useRequiredParams';

import api from 'api';
import {
  AnnotationTask,
  AsrAnnotationTaskResponse,
  PatchAnnotationTaskRequest,
} from 'api/internalResources/asrAnnotations/types';
import { useToast } from 'hooks/useToast';

const useAsrAnnotationsTasksById = () => {
  const { taskId } = useRequiredParams<{ taskId: string }>();

  const toast = useToast();

  const queryClient = useQueryClient();

  const queryKey = ['asrAnnotationsTasks', taskId];

  const { data: asrAnnotations, isLoading: isLoadingAsrAnnotations } = useQuery(
    queryKey,
    () => api.getAsrAnnotationTasksById(taskId),
    {
      enabled: !!taskId,
      retry: false,
      onError: () => {
        toast.error({
          title: 'Failed to fetch ASR annotation tasks by ID',
        });
      },
    },
  );

  const { mutateAsync: submitReview, isLoading: isSubmittingReview } =
    useMutation(
      ({ data }: { data: PatchAnnotationTaskRequest }) =>
        api.patchAsrAnnotationTask(taskId, data),
      {
        mutationKey: queryKey,
        onError: (_, args) => {
          toast.error({
            title: `Failed to submit review for annotation id ${args.data.annotation_id}`,
          });
        },
        onSuccess: (data) => {
          queryClient.setQueryData(
            queryKey,
            (oldData?: AsrAnnotationTaskResponse) => {
              if (!oldData) {
                return oldData;
              }

              return {
                ...oldData,
                annotations: oldData.annotations.map(
                  (annotation: AnnotationTask) =>
                    annotation.annotation_id === data.annotation_id
                      ? data
                      : annotation,
                ),
              };
            },
          );
        },
      },
    );

  return useMemo(
    () => ({
      asrAnnotations,
      isLoadingAsrAnnotations,
      submitReview,
      isSubmittingReview,
    }),
    [asrAnnotations, isLoadingAsrAnnotations, submitReview, isSubmittingReview],
  );
};

export default useAsrAnnotationsTasksById;
