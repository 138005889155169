import styled, { css } from "styled-components";

import themeColors, { ColorName } from "styles/config/colors";
import themeSpacing from "styles/config/spacing";

export const IconFrame = styled.div<{
  color?: ColorName;
  backgroundColor?: ColorName;
  width: number;
  height: number;
}>`
  display: flex;
  flex-shrink: 0;
  gap: ${themeSpacing.xs2};
  align-items: center;
  justify-content: center;

  ${({ width, height }) => css`
    width: ${width}px;
    height: ${height}px;
  `}
  padding: ${themeSpacing.xs2};

  color: ${({ color }) => (color ? themeColors[color] : "inherit")};

  background: ${({ backgroundColor }) =>
    backgroundColor ? themeColors[backgroundColor] : "transparent"};
  border-radius: ${themeSpacing.radiusFull};
`;
