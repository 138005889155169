import { TransitionStatus } from "react-transition-group";
import styled from "styled-components";

export const AccordionGroupWrapper = styled.div<{
  $maxHeight: number;
  $state: TransitionStatus;
}>`
  overflow: hidden;
  max-height: ${({ $state, $maxHeight }) => {
    if (!$maxHeight) {
      return "0px";
    }

    switch ($state) {
      case "entering":
      case "entered":
        return `${$maxHeight}px`;
      case "exiting":
      case "exited":
        return "0px";
    }
  }};
  transition: max-height 0.2s ease-in-out;
`;
