import { ComponentWithChildren } from "@polyai/common/types/helpers";

import { Props } from "./types";

import * as Styled from "./Stack.styled";

export const Stack: ComponentWithChildren<Props> = ({
  direction = "row",
  spacing = "xs3",
  children,
  ...rest
}: Partial<Props>) => {
  return (
    <Styled.Stack direction={direction} spacing={spacing} {...rest}>
      {children}
    </Styled.Stack>
  );
};
