import { ChangeEvent, useCallback, useMemo, useState } from "react";

import { formatCurrency } from "@polyai/common/lib/paymentHelper";

import { CreditIcon } from "../Icons";
import Input, { InputProps } from ".";

const CurrencyInput = ({
  value,
  maxValue,
  onChange,
  precision = 0,
  ...props
}: Omit<InputProps, "value" | "onChange"> & {
  value: number | undefined;
  maxValue?: number;
  precision?: number;
  onChange: (value: number | undefined) => void;
}) => {
  const [inputValue, setInputValue] = useState(
    value ? formatCurrency(value, { precision: 0 }) : ""
  );

  const currencyRegex = useMemo(
    () =>
      new RegExp(`^[0-9,]*\.{0,${precision ? 1 : 0}}[0-9]{0,${precision}}$`),
    [precision]
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;
      if (!value) {
        onChange(undefined);
        setInputValue("");
        return;
      }

      if (!currencyRegex.test(value)) {
        return;
      }

      const stripped = value.replaceAll(",", "");
      const numberValue = parseFloat(stripped);

      if (!maxValue || numberValue <= maxValue) {
        onChange(numberValue);
        setInputValue(value);
      }
    },
    [maxValue, onChange, currencyRegex]
  );

  const onBlur = useCallback(() => {
    if (value) {
      setInputValue(formatCurrency(value, { precision }));
    }
  }, [precision, value]);

  return (
    <Input
      {...props}
      Icon={CreditIcon}
      value={inputValue}
      onBlur={onBlur}
      onChange={handleChange}
    />
  );
};

export default CurrencyInput;
