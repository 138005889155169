import { ComponentWithChildren } from "@polyai/common/types/helpers";

import * as Styled from "./GridContent.styled";

type GridContentProps = {
  className?: string;
  isSidebarOpen?: boolean;
};

const GridContent: ComponentWithChildren<GridContentProps> = ({
  className,
  isSidebarOpen,
  children,
}) => {
  return (
    <Styled.Wrapper $isSidebarOpen={isSidebarOpen} className={className}>
      {children}
    </Styled.Wrapper>
  );
};

export default GridContent;
