import { ProjectVersionState } from 'api/resources/deployments/types';
import { NUM_OF_VERSION_CHARS_TO_SHOW } from 'screens/Environments/constants';

export const getUserFriendlyVersion = (version: string | undefined): string => {
  if (version) {
    return version.slice(0, NUM_OF_VERSION_CHARS_TO_SHOW);
  } else {
    return '';
  }
};

export const transformDisclaimerData = ({
  knowledge_base: data,
}: Pick<ProjectVersionState, 'knowledge_base'>) => {
  return {
    is_enabled: data.disclaimer_enabled,
    message: data.disclaimer_message,
    ringtone: data.disclaimer_ringtone,
    voice_id: data.disclaimer_voice_id,
    ringtone_public_url: data.disclaimer_ringtone_public_url,
    ringtone_filename: data.disclaimer_ringtone_filename,
  };
};
