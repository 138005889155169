import { createGlobalStyle, css } from 'styled-components';

import themeColors from '@polyai/ui/styles/config/colors';

const CYPRESS = process.env.NEXT_PUBLIC_CYPRESS;

import 'rsuite/dist/rsuite-no-reset.min.css';

const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: unset;

    overflow: hidden auto;

    font-family: ${({ theme }) => theme.text.font.body};
    font-display: block;
    color:  ${themeColors.textPrimary};

    background: ${themeColors.backgroundPage};

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent; /* for removing the highlight on tap/touch */
  }

  html,
  body {
    height: 100%;
    min-height: 100vh;
  }

  body.noscroll {
    position: fixed;
    inset: 0;
    overflow: hidden;
  }

  #root {
    height: 100%;
    min-height: 100vh;
  }

  .skeletonInFlexContainer {
    display: flex;
    flex: 1;
  }

  /* don't display error overlay if it's a cypress test run */
  ${
    CYPRESS &&
    css`
      iframe {
        display: none;
      }
    `
  }
  `;

export default GlobalStyle;
