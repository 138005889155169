import { doGet } from 'api/request';
import { LOGGER_TYPE } from 'api/types';
import { AccountResponse, AccountsResponse, AuthoriseResponse } from './types';

export const getAccount = (accountId: string) => {
  return doGet<AccountResponse>(`accounts/${accountId}`, LOGGER_TYPE.JUPITER, {
    version: 'v2',
  });
};

export const getAccounts = () => {
  return doGet<AccountsResponse>(`accounts`, LOGGER_TYPE.ACCOUNT, {
    version: 'v2',
  });
};

export const authoriseUser = () => {
  return doGet<AuthoriseResponse>(`authorise`, LOGGER_TYPE.ACCOUNT);
};
