import styled from "styled-components";

import themeSpacing from "styles/config/spacing";
import { Props } from "./types";

export const Stack = styled.div<Props>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  gap: ${({ spacing }) => themeSpacing[spacing]};
  justify-content: flex-start;
`;
