import React, { useCallback, useMemo, useRef, useState } from "react";

import {
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
} from "@floating-ui/react";

import { PopoverProps } from "./types";

const emptyListRef = {
  current: [],
};

export function usePopover({
  initialOpen = false,
  placement = "bottom",
  modal,
  open: controlledOpen,
  onOpenChange: setControlledOpen,
  autoUpdate: autoPosition = true,
  middleware = [],
  list,
  useDismissProps,
  onSelectListItem,
}: PopoverProps = {}) {
  const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen);
  const [labelId, setLabelId] = useState<string | undefined>();
  const [descriptionId, setDescriptionId] = useState<string | undefined>();

  const listElementsRef = useRef<Array<HTMLElement | null>>([]);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(0);

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoPosition ? autoUpdate : undefined,
    middleware: [
      offset(5),
      flip({
        crossAxis: placement.includes("-"),
        fallbackAxisSideDirection: "end",
        padding: 5,
        fallbackPlacements: ["left", "right"],
      }),
      shift({ padding: 24 }),
      ...middleware,
    ],
  });

  const context = data.context;

  const click = useClick(context, {
    enabled: controlledOpen == null,
  });
  const dismiss = useDismiss(context, {
    ancestorScroll: true,
    outsidePress: true,
    ...useDismissProps,
  });
  const role = useRole(context);

  const listNav = useListNavigation(context, {
    listRef: list ? listElementsRef : emptyListRef,
    activeIndex,
    selectedIndex,
    onNavigate: setActiveIndex,
  });

  const interactions = useInteractions([click, dismiss, role, listNav]);

  const handleSelectListItem = useCallback(
    (index: number) => {
      setSelectedIndex(index);
      onSelectListItem?.(index);
    },
    [onSelectListItem]
  );

  return useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
      listContext: {
        elementsRef: listElementsRef,
        activeIndex,
        setActiveIndex,
        selectedIndex,
        setSelectedIndex,
        handleSelect: handleSelectListItem,
      },
      modal,
      labelId,
      descriptionId,
      setLabelId,
      setDescriptionId,
    }),
    [
      open,
      setOpen,
      interactions,
      data,
      modal,
      labelId,
      descriptionId,
      activeIndex,
      setActiveIndex,
      selectedIndex,
      setSelectedIndex,
      handleSelectListItem,
    ]
  );
}
