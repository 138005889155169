import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ParsedFilterItem } from 'components/molecules/Filter/Filter.constants';

import { projectInit } from 'actions';
import { SortQuery } from 'api/resources/conversation/types';
import { TABLE_STORAGE_KEY } from './table.constants';

export enum TableType {
  CONVERSATIONS = 'conversations',
  TOPICS = 'topics',
  CACHED_AUDIOS = 'cached_audios',
}

interface TablePayload<T> {
  table: TableType;
  data: T;
}

export interface TableState {
  currentPage: number;
  filters: ParsedFilterItem[];
  sorts: SortQuery[];
  offset: number;
  search: string;
  firstLoadedAt?: string;
}

export type MappedTableState = {
  [key in TableType]: TableState;
};

export const defaultState: TableState = {
  currentPage: 0,
  filters: [],
  sorts: [],
  offset: 0,
  search: '',
  firstLoadedAt: undefined,
};

export const initialState: MappedTableState = {
  [TableType.CONVERSATIONS]: { ...defaultState },
  [TableType.TOPICS]: { ...defaultState },
  [TableType.CACHED_AUDIOS]: { ...defaultState },
};

const loadState = (): MappedTableState | undefined => {
  try {
    const serializedState = localStorage.getItem(TABLE_STORAGE_KEY);

    if (serializedState === null) {
      return undefined;
    }

    return {
      ...initialState,
      ...JSON.parse(serializedState),
    } as MappedTableState;
  } catch (e) {
    return undefined;
  }
};

const tableSlice = createSlice({
  name: 'table',
  initialState: loadState() || initialState,
  reducers: {
    setCurrentPage: (
      state,
      { payload: { table, data: page } }: PayloadAction<TablePayload<number>>,
    ) => {
      state[table].currentPage = page;
    },
    setFilters: (
      state,
      {
        payload: { table, data: filters },
      }: PayloadAction<TablePayload<ParsedFilterItem[]>>,
    ) => {
      state[table].filters = filters;
    },
    setSorts: (
      state,
      {
        payload: { table, data: sorts },
      }: PayloadAction<TablePayload<SortQuery[]>>,
    ) => {
      state[table].sorts = sorts;
    },
    setOffset: (
      state,
      { payload: { table, data: offset } }: PayloadAction<TablePayload<number>>,
    ) => {
      state[table].offset = offset;
    },
    setSearch: (
      state,
      { payload: { table, data: search } }: PayloadAction<TablePayload<string>>,
    ) => {
      state[table].search = search;
    },
    setTable: (
      state,
      {
        payload: { table, data: newState },
      }: PayloadAction<TablePayload<Partial<TableState>>>,
    ) => {
      state[table] = { ...state[table], ...newState };
    },
    setFirstLoadedAt: (
      state,
      {
        payload: { table, data: firstLoadedAt },
      }: PayloadAction<TablePayload<string>>,
    ) => {
      state[table].firstLoadedAt = firstLoadedAt;
    },
    resetTable: (state, { payload: table }: PayloadAction<TableType>) => {
      state[table] = defaultState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(projectInit, () => {
      return { ...initialState };
    });
  },
});

export const {
  resetTable,
  setCurrentPage,
  setFilters,
  setFirstLoadedAt,
  setOffset,
  setSearch,
  setSorts,
  setTable,
} = tableSlice.actions;

export default tableSlice.reducer;
