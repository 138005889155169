export const SMS_TEMPLATES_QUERY_KEY = ['resource-metrics', 'sms-templates'];
export const CONFIG_QUERY_KEY = 'config';
export const FLOWS_QUERY_KEY = 'flows';
export const FUNCTIONS_QUERY_KEY = 'functions';
export const HANDOFFS_QUERY_KEY = 'handoffs';
export const DISCLAIMER_QUERY_KEY = 'disclaimer';
export const KNOWLEDGE_BASE_QUERY_KEY = 'knowledge-base';
export const PRONUNCIATION_QUERY_KEY = 'pronunciations';
export const SMS_CREDENTIALS_QUERY_KEY = 'sms-credentials';
export const STOP_KEYWORDS_QUERY_KEY = 'stop-keywords';
export const TOPICS_QUERY_KEY = 'topics';
export const VARIANT_MANAGEMENT_QUERY_KEY = 'variant-management';
export const VOICE_TUNING_QUERY_KEY = 'voice-tuning';
export const TELEPHONE_MUTATION_KEY = 'jupiter-telephony';
export const ALL_CONNECTION_QUERY_KEY = 'all-environments';
export const VOICE_TUNING_MUTATION_KEY = 'voice-tuning-settings';
