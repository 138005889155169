import React from 'react';

import { LOGGER_TYPE } from 'api/types';
import { logToDataDog } from 'lib/ddlogHelper';
import ErrorPage, { ErrorStatus } from 'screens/ErrorPage';

class ErrorBoundary extends React.Component<
  any,
  { hasError: boolean; errorMessage?: string; errorInfo?: string }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: undefined,
      errorInfo: undefined,
    };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    this.setState({
      hasError: true,
      errorMessage: error.message,
      errorInfo: errorInfo.componentStack,
    });

    logToDataDog(LOGGER_TYPE.REACT_ERROR, 'error', error.message, {
      errorInfo: errorInfo.componentStack,
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorPage type={ErrorStatus.UNHANDLED_ERROR} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
