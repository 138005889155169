import ENDPOINTS from 'api/endpoints';
import { doGet } from 'api/request';
import { LOGGER_TYPE } from 'api/types';
import { generateNextPath } from 'lib/routeHelper';
import { store } from 'reduxStore';
import { CachedAudiosResponse } from './types';

export const NUM_CACHED_AUDIOS_PER_PAGE = 50;

export const getCachedAudios = ({
  query,
  limit = NUM_CACHED_AUDIOS_PER_PAGE,
}: {
  query: string;
  limit?: number;
}) => {
  const projectId = store.getState().project.id;
  const accountId = store.getState().account.id;

  return doGet<CachedAudiosResponse>(
    generateNextPath(ENDPOINTS.AUDIO_CACHE_LIST, { accountId, projectId }),
    LOGGER_TYPE.JUPITER,
    {
      params: {
        limit,
      },
      query,
      version: 'v2',
    },
  );
};
