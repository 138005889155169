import {
  PatchUsersRequest,
  PatchUsersResponse,
  UserInfo,
} from '@polyai/common/api/resources/userManagement/types';

import ENDPOINTS from 'api/endpoints';
import { doDelete, doGet, doPatch, doPost } from 'api/request';
import { LOGGER_TYPE } from 'api/types';
import { generateNextPath } from 'lib/routeHelper';
import { store } from 'reduxStore';

export const getUsers = (accountId: string) => {
  return doGet<UserInfo[]>(
    generateNextPath(ENDPOINTS.USER_MANAGEMENT, {
      accountId,
    }),
    LOGGER_TYPE.USER_MANAGEMENT,
    { params: { account_id: accountId } },
  );
};

export const getUser = (user_email: string) => {
  const accountId = store.getState().account.id;

  return doPost(
    generateNextPath(ENDPOINTS.USER_MANAGEMENT, {
      accountId,
    }),
    LOGGER_TYPE.USER_MANAGEMENT,
    {
      user_email,
      account_id: accountId,
    },
  );
};

export const patchUsers = (body: Omit<PatchUsersRequest, 'account_id'>) => {
  const accountId = store.getState().account.id;

  return doPatch<PatchUsersResponse>(
    generateNextPath(ENDPOINTS.USER_MANAGEMENT, {
      accountId,
    }),
    LOGGER_TYPE.USER_MANAGEMENT,
    {
      ...body,
      account_id: accountId,
    },
  );
};

export const deleteUsers = (users_email: string[]) => {
  const accountId = store.getState().account.id;

  return doDelete(
    generateNextPath(ENDPOINTS.USER_MANAGEMENT, {
      accountId,
    }),
    LOGGER_TYPE.USER_MANAGEMENT,
    {
      users_email,
      account_id: accountId,
    },
  );
};
