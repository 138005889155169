import ENDPOINTS from 'api/endpoints';
import { doDelete, doGet, doPost, doPut } from 'api/request';
import { LOGGER_TYPE } from 'api/types';
import { pick } from 'lib/miscHelper';
import { generateNextPath } from 'lib/routeHelper';
import { store } from 'reduxStore';
import {
  ImportTopicsCSVResponse,
  PutTopicsParams,
  Topic,
  TopicsResponse,
  UpdateTopicsRequest,
} from './types';

export const getTopics = (projectId: string) => {
  const accountId = store.getState().account.id;

  return doGet<TopicsResponse>(
    generateNextPath(ENDPOINTS.TOPICS, {
      accountId,
      projectId,
    }),
    LOGGER_TYPE.JUPITER,
    { version: 'v2' },
  );
};

export const updateTopics = ({
  projectId,
  update,
}: {
  projectId: string;
  update: UpdateTopicsRequest;
}) => {
  const accountId = store.getState().account.id;

  return doPost<TopicsResponse>(
    generateNextPath(ENDPOINTS.TOPICS, {
      accountId,
      projectId,
    }),
    LOGGER_TYPE.JUPITER,
    {
      ...update,
      updated: update.updated?.map((t) =>
        pick(t, 'id', 'name', 'content', 'actions'),
      ),
    },
    { version: 'v2' },
  );
};

export const putTopic = ({ topicId, topic }: PutTopicsParams) => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  return doPut<Topic>(
    generateNextPath(ENDPOINTS.TOPIC, {
      accountId,
      projectId,
      topicId,
    }),
    LOGGER_TYPE.JUPITER,
    pick(topic, 'name', 'content', 'actions'),
    { version: 'v2' },
  );
};

export const deleteTopic = (topicId: string) => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  return doDelete<Topic>(
    generateNextPath(ENDPOINTS.TOPIC, {
      accountId,
      projectId,
      topicId,
    }),
    LOGGER_TYPE.JUPITER,
    undefined,
    { version: 'v2' },
  );
};

export const importTopics = (file: File) => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;
  const url = generateNextPath(
    ENDPOINTS.TOPICS_IMPORT,
    { accountId, projectId },
    {
      type: 'csv',
    },
  );
  const bodyFormData = new FormData();
  const blob = new Blob([file], { type: 'csv' });
  bodyFormData.append('csv', blob);

  return doPost<ImportTopicsCSVResponse>(
    url,
    LOGGER_TYPE.JUPITER,
    bodyFormData,
    {
      postingFile: true,
      version: 'v2',
      parseResponseToJSON: true,
    },
  );
};

export const exportTopics = () => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  return doGet<{ data: Blob; headers: Headers }>(
    generateNextPath(ENDPOINTS.TOPICS_EXPORT, {
      accountId,
      projectId,
    }),
    LOGGER_TYPE.JUPITER,
    { version: 'v2', downloadingFile: true, returnStatusCode: true },
  );
};
