import styled, { css } from "styled-components";

import { BodyStrong } from "components/atoms/Text";

import themeColors, {
  baseColors,
  ColorName,
  StyledColorScheme,
} from "styles/config/colors";
import themeSpacing from "styles/config/spacing";
import { TagVariant } from ".";

interface TagWrapperProps {
  append?: boolean;
  clickable?: boolean;
  $iconOnly?: boolean;
  variant: TagVariant;
  $hideBorder?: boolean;
}

const getBackgroundColourForTag = (
  colors: StyledColorScheme,
  variant: TagVariant
) => {
  switch (variant) {
    case TagVariant.ACTIVE:
      return colors.scriptingNode;
    case TagVariant.ERROR:
      return colors.backgroundDanger;
    case TagVariant.SUCCESS:
      return colors.backgroundSuccess;
    case TagVariant.KEYWORD:
      return colors.backgroundInverse;
    case TagVariant.INFO:
      return baseColors.blue["05"];
  }
};

export const TagWrapper = styled.aside<TagWrapperProps>`
  display: inline-flex;
  gap: ${themeSpacing.xxs2};
  align-items: center;
  justify-content: flex-end;

  height: ${themeSpacing.sm3};
  padding: 0 ${themeSpacing.xs2};

  background-color: ${themeColors.backgroundPage};
  border: 1px solid ${themeColors.borderStrong};
  border-radius: ${themeSpacing.radiusSmall};

  p {
    display: inline-block;
    width: max-content;
    font-size: ${themeSpacing.xs3};
    color: ${themeColors.textSecondary};
  }

  ${({ clickable }) =>
    clickable &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}

  ${({ append }) =>
    append &&
    css`
      padding-right: 0;

      p {
        overflow: hidden;

        max-width: ${themeSpacing.xxl1};

        color: ${themeColors.textPrimary};
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    `}
  ${({ variant }) =>
    variant === TagVariant.KEYWORD &&
    css`
      margin-right: 0.5rem;
    `}
  ${({ variant }) =>
    css`
      background-color: ${getBackgroundColourForTag(themeColors, variant)};
    `}
  ${({ variant }) =>
    [
      TagVariant.ERROR,
      TagVariant.SUCCESS,
      TagVariant.ACTIVE,
      TagVariant.KEYWORD,
    ].includes(variant) &&
    css`
      p {
        color: ${themeColors.textPrimaryInverse};
      }
    `}
    ${({ variant }) =>
    variant === TagVariant.INFO &&
    css`
      gap: ${themeSpacing.xs1};
      padding: 0 ${themeSpacing.xs2};
      border-color: ${baseColors.blue["20"]};

      p {
        color: ${themeColors.informationText01};
      }
    `}
    
    ${({ $iconOnly }) =>
    $iconOnly &&
    css`
      padding: ${themeSpacing.xs1};
    `}

    ${({ $hideBorder }) =>
    $hideBorder &&
    css`
      border: none;
    `}
`;

export const TagText = styled(BodyStrong)<{ $color: ColorName }>`
  display: flex !important;
  align-items: center;

  && {
    color: ${({ $color, theme }) => theme.activeColorScheme.colors[$color]};
  }

  p {
    color: ${({ $color, theme }) => theme.activeColorScheme.colors[$color]};
  }
`;
