import { useMutation } from '@tanstack/react-query';

import api from 'api';
import { setShowUserQuestionnaire } from 'ducks/auth/authSlice';
import { useAppDispatch } from './reduxHooks';
import { useToast } from './useToast';

const useQuestionnaire = () => {
  const toast = useToast();
  const dispatch = useAppDispatch();

  const { mutate: updateQuestionnaire, isLoading: isUpdatingQuestionnaire } =
    useMutation(api.setQuestionnaire, {
      onSettled: () => {
        toast.success({
          title:
            'Thanks for your answers, this information will help us improve',
        });

        dispatch(setShowUserQuestionnaire(false));
      },
    });

  return {
    updateQuestionnaire,
    isUpdatingQuestionnaire,
  };
};

export default useQuestionnaire;
