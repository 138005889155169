import styled, { css } from "styled-components";

import themeColors, { baseColors } from "styles/config/colors";
import themeSpacing from "styles/config/spacing";
import text from "styles/config/text";
import { CloseIcon } from "../Icons";
import { Subtitle } from "../Text";
import { CalloutType } from ".";

export const CalloutWrapper = styled.div<{
  $type?: CalloutType;
  $isBorderVisible?: boolean;
  $isRoundedBorder?: boolean;
  $iconOnTop?: boolean;
}>`
  display: flex;
  gap: ${themeSpacing.xs3};
  ${({ $iconOnTop = false }) =>
    !$iconOnTop &&
    css`
      align-items: center;
    `}
  padding: ${themeSpacing.xs3} ${themeSpacing.sm1};

  ${({ $isRoundedBorder }) => {
    return css`
      border-radius: ${$isRoundedBorder
        ? themeSpacing.radiusSmall
        : themeSpacing.radiusMedium};
    `;
  }}

  ${({ $type, $isBorderVisible }) => {
    switch ($type) {
      case CalloutType.INFO:
        return css`
          color: ${baseColors.blue[80]};
          background: ${baseColors.blue["05"]};
          ${$isBorderVisible &&
          css`
            border: 1px solid ${baseColors.blue["20"]};
          `}
        `;
      case CalloutType.ERROR:
        return css`
          color: ${themeColors.errorText01};
          background: ${baseColors.red["05"]};
          ${$isBorderVisible &&
          css`
            border: 1px solid ${baseColors.red["20"]};
          `}
        `;
      case CalloutType.WARNING:
        return css`
          color: ${themeColors.textWarning};
          background: ${baseColors.yellow["05"]};
          ${$isBorderVisible &&
          css`
            border: 1px solid ${baseColors.yellow["20"]};
          `}
        `;
      case CalloutType.SUCCESS:
        return css`
          color: ${themeColors.textSuccess};
          background: ${baseColors.green["05"]};
          ${$isBorderVisible &&
          css`
            border: 1px solid ${baseColors.green["20"]};
          `}
        `;
      default:
        return css`
          color: ${themeColors.textSecondary};
          background: ${themeColors.backgroundPage};
        `;
    }
  }}
`;

export const CalloutIconContainer = styled.div`
  display: flex;
  align-items: start;
  height: 100%;
`;

export const CalloutContentWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
`;

export const CalloutContent = styled.div`
  display: flex;
  gap: ${themeSpacing.xs2};
  align-items: center;
`;

export const CalloutHeadline = styled(Subtitle)`
  font-size: ${text.size.body.body2};
  font-weight: ${text.weight.semibold};
`;

export const CloseButton = styled(CloseIcon)`
  cursor: pointer;
`;
