import { useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import dynamic from 'next/dynamic';
import { useForm } from 'react-hook-form';

import { AudioPlayerProvider } from '@polyai/common/contexts/AudioPlayerContext';
import { useRouter } from '@polyai/common/hooks/useRouter';
import getFullAudioUrl from '@polyai/common/utils/fullAudioUrl';
import Button from '@polyai/ui/components/atoms/Button';
import Input from '@polyai/ui/components/atoms/Input';
import { Loader } from '@polyai/ui/components/atoms/Loader';
import Tag, { TagVariant } from '@polyai/ui/components/atoms/Tag';
import { Title } from '@polyai/ui/components/atoms/Text';
import ScreenHeader from '@polyai/ui/components/molecules/ScreenHeader';

import UnsavedChangesModal from 'components/molecules/UnsavedChangesModal';

import { PatchAnnotationTaskRequest } from 'api/internalResources/asrAnnotations/types';
import useAsrAnnotationsTasksById from 'hooks/useAsrAnnotations/useAsrAnnotationsTasksById';
import useAuthToken from 'hooks/useAuthToken';
import { useIsFeatureOn } from 'hooks/useFeatureSwitch';
import { useUserAccess } from 'hooks/useProjectResources';
import EntityCollection, {
  AsrTasksFormData,
  Entity,
  EntityType,
} from 'screens/AsrAnnotationReview/components/EntityCollection';
import { UNSAVED_ENTITY_CHANGES_UI_COPY } from 'screens/AsrAnnotationReview/UnsavedEntityChanges.uiCopy';
import ErrorPage, { ErrorStatus } from 'screens/ErrorPage';

import * as Styled from './AsrAnnotationReview.styled';

type NavDirection = 'next' | 'previous' | 'finish';

const MIN_ANNOTATION_NUM = 1;

const AudioPlayer = dynamic(
  () => import('@polyai/ui/components/molecules/AudioPlayer'),
  {
    ssr: false,
  },
);

const AsrAnnotationReviewScreen = () => {
  const {
    asrAnnotations,
    isLoadingAsrAnnotations,
    submitReview,
    isSubmittingReview,
  } = useAsrAnnotationsTasksById();
  const { token } = useAuthToken();

  const { pushUrl } = useRouter();

  const queryClient = useQueryClient();

  const { isInternalUser } = useUserAccess();

  const { isFeatureOn } = useIsFeatureOn();

  const isAsrAnnotationReviewEnabled = isFeatureOn(
    'internal_asr_annotations_tool',
  );

  const [hasSetInitialAnnotationNum, setHasSetInitialAnnotationNum] =
    useState(false);

  const [currentAnnotationNum, setCurrentAnnotationNum] =
    useState<number>(MIN_ANNOTATION_NUM);

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [navDirection, setNavDirection] = useState<NavDirection>('next');

  // Set the initial annotation number to the first unreviewed annotation
  // If all annotations are reviewed, set the initial annotation number to the first annotation
  useEffect(() => {
    if (asrAnnotations && !hasSetInitialAnnotationNum) {
      const firstUnreviewedAnnotationIndex =
        asrAnnotations?.annotations?.findIndex(
          (annotation) => !annotation.completed,
        ) ?? -1;

      setCurrentAnnotationNum(
        Math.max(firstUnreviewedAnnotationIndex + 1, MIN_ANNOTATION_NUM),
      );
      setHasSetInitialAnnotationNum(true);
    }
  }, [asrAnnotations, hasSetInitialAnnotationNum]);

  const totalAnnotations =
    asrAnnotations?.summary?.pending! + asrAnnotations?.summary?.completed!;

  const selectedAnnotation =
    asrAnnotations?.annotations[currentAnnotationNum - 1];

  const audioTurnUrl = `conversations/${selectedAnnotation?.conversation_id}/turns/${selectedAnnotation?.audio_turn_index}/audio`;

  const mapEntitiesToForm = (entities: { [key: string]: string[] }) => {
    const mappedEntities = Object.entries(entities).map(([entity, values]) => ({
      name: entity as EntityType,
      values,
    }));

    if (mappedEntities.length === 0) {
      return [{ name: '', values: [] }] as Entity[];
    }

    return mappedEntities;
  };

  const formMethods = useForm<AsrTasksFormData>({
    defaultValues: {
      transcript: selectedAnnotation?.transcript ?? '',
      entities: mapEntitiesToForm(selectedAnnotation?.entities ?? {}),
    },
    mode: 'all',
  });

  const { register, watch, setValue } = formMethods;

  const asrTaskData = watch();

  const invalidateAsrAnnotationTasksCache = async () => {
    await queryClient.invalidateQueries(['asrAnnotationsTasks']);
  };

  const changeAnnotation = (direction: NavDirection) => {
    let annotationNum = currentAnnotationNum;

    if (direction === 'next') {
      annotationNum = Math.min(annotationNum + 1, totalAnnotations);
    } else if (direction === 'previous') {
      annotationNum = Math.max(annotationNum - 1, MIN_ANNOTATION_NUM);
    }

    setCurrentAnnotationNum(annotationNum);
  };

  const handleSubmitReview = async () => {
    // Map entities to the correct format
    const entities = asrTaskData.entities
      .filter((entity) => entity.name !== '' && entity.values.length > 0)
      .reduce((acc, entity) => {
        acc[entity.name] = entity.values;
        return acc;
      }, {} as { [key: string]: string[] });

    const data: PatchAnnotationTaskRequest = {
      transcript: asrTaskData.transcript,
      entities,
      completed: true,
      annotation_id: selectedAnnotation?.annotation_id!,
    };

    await submitReview({ data });
  };

  const handleNextTurn = async () => {
    await handleSubmitReview();

    changeAnnotation('next');
  };

  const handlePreviousTurn = () => {
    changeAnnotation('previous');
  };

  const handleBackToTasks = async () => {
    await invalidateAsrAnnotationTasksCache();
    pushUrl('/asr-annotations');
  };

  const handleFinishReview = async () => {
    await handleSubmitReview();
    await handleBackToTasks();
  };

  const handleAnnotationChange = async (direction: NavDirection) => {
    if (direction === 'next') {
      await handleNextTurn();
    } else if (direction === 'previous') {
      handlePreviousTurn();
    } else {
      await handleFinishReview();
    }

    setHasUnsavedChanges(false);
  };

  const checkUnsavedChanges = async (direction: NavDirection) => {
    // Remove the placeholder entity if it exists
    const entitiesToCheck = asrTaskData.entities.filter(
      (entity) => entity.name !== '' || entity.values.length > 0,
    );

    // Check if there are any unsaved entities
    const hasUnsavedEntities = entitiesToCheck.some(
      (entity) => entity.name === '' || entity.values.length === 0,
    );

    if (hasUnsavedEntities) {
      setHasUnsavedChanges(true);
      // Set the direction here so that the correct action can be taken after
      // the user confirms in the unsaved changes modal
      setNavDirection(direction);
      return;
    }

    // Otherwise proceed with making the change
    await handleAnnotationChange(direction);
  };

  useEffect(() => {
    if (asrAnnotations) {
      const selectedAnnotation =
        asrAnnotations.annotations[currentAnnotationNum - 1];

      setValue('transcript', selectedAnnotation?.transcript ?? '');
      setValue(
        'entities',
        mapEntitiesToForm(selectedAnnotation?.entities ?? {}),
      );
    }
  }, [asrAnnotations, currentAnnotationNum, setValue]);

  if (!isInternalUser || !isAsrAnnotationReviewEnabled) {
    return <ErrorPage type={ErrorStatus.FORBIDDEN} />;
  }

  if (isLoadingAsrAnnotations) {
    return <Loader fullScreen spinner />;
  }

  if (!asrAnnotations) {
    return <ErrorPage type={ErrorStatus.UNAVAILABLE} />;
  }

  return (
    <>
      <ScreenHeader
        description={
          <Styled.DescriptionWrapper>
            {'ASR annotation reviews to be carried out as part of task ID:'}
            <Styled.IdTag variant={TagVariant.KEYWORD}>
              {asrAnnotations.task_id}
            </Styled.IdTag>
            {`Clicking on next turn will mark the current turn as reviewed.`}
          </Styled.DescriptionWrapper>
        }
        screenTitle="ASR Annotation Review"
      />

      <Styled.AsrAnnotationToolWrapper>
        <Styled.TitleWrapper>
          <Title>{`Conversation turns review (${currentAnnotationNum} of ${totalAnnotations})`}</Title>
          {selectedAnnotation?.completed && (
            <Tag variant={TagVariant.SUCCESS}>Reviewed</Tag>
          )}
        </Styled.TitleWrapper>

        <Styled.IdText>
          {`Annotation ID: ${asrAnnotations?.annotations[currentAnnotationNum]?.annotation_id}`}
        </Styled.IdText>
        <Styled.IdText>
          {`Conversation ID: ${asrAnnotations.annotations[currentAnnotationNum]?.conversation_id}`}
        </Styled.IdText>

        <AudioPlayerProvider>
          <Styled.AudioPlayerWrapper>
            <AudioPlayer
              authToken={token}
              url={getFullAudioUrl(audioTurnUrl, 'jupiter/v1')}
            />
          </Styled.AudioPlayerWrapper>
        </AudioPlayerProvider>
        <Input
          {...register('transcript')}
          label="Correct transcript"
          value={asrTaskData.transcript}
          fullWidth
        />
        <EntityCollection asrTaskData={asrTaskData} formMethods={formMethods} />
        <Styled.ButtonsGroup>
          <Button
            label="Back to tasks"
            variant="GHOST"
            onClick={handleBackToTasks}
          />

          <Styled.TurnNavButtons>
            <Button
              disabled={currentAnnotationNum <= 1}
              label="Previous turn"
              variant="SECONDARY"
              onClick={async () => await checkUnsavedChanges('previous')}
            />
            {currentAnnotationNum < totalAnnotations && (
              <Button
                disabled={currentAnnotationNum === totalAnnotations}
                label="Next turn"
                loading={isSubmittingReview}
                onClick={async () => await checkUnsavedChanges('next')}
              />
            )}
            {currentAnnotationNum === totalAnnotations && (
              <Button
                label="Finish"
                onClick={async () => await checkUnsavedChanges('finish')}
              />
            )}
          </Styled.TurnNavButtons>
        </Styled.ButtonsGroup>
        <UnsavedChangesModal
          buttonText="Discard changes"
          open={hasUnsavedChanges}
          uiCopy={UNSAVED_ENTITY_CHANGES_UI_COPY}
          onClose={() => setHasUnsavedChanges(false)}
          onConfirmButtonClick={async () =>
            await handleAnnotationChange(navDirection)
          }
        />
      </Styled.AsrAnnotationToolWrapper>
    </>
  );
};

export default AsrAnnotationReviewScreen;
