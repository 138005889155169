import { ClientEnvironment } from '@polyai/common/constants/global.constants';

import ENDPOINTS from 'api/endpoints';
import { doDelete, doGet, doPost } from 'api/request';
import { LOGGER_TYPE } from 'api/types';
import { generateNextPath } from 'lib/routeHelper';
import { store } from 'reduxStore';
import { CountryCode, PhoneNumberInAllEnvironmentsResponse } from './types';
import { PhoneNumberResponse } from './types';

export const getPhoneNumber = (
  projectId: string,
  clientEnv?: ClientEnvironment,
) => {
  const accountId = store.getState().account.id;
  return doGet<PhoneNumberResponse>(
    generateNextPath(ENDPOINTS.PHONE_NUMBERS, { accountId, projectId }),
    LOGGER_TYPE.JUPITER,
    {
      params: { client_env: clientEnv },
    },
  );
};

export const getPhoneNumbersInAllEnvironments = (projectId: string) => {
  const accountId = store.getState().account.id;
  return doGet<PhoneNumberInAllEnvironmentsResponse>(
    generateNextPath(`${ENDPOINTS.PHONE_NUMBERS}/list`, {
      accountId,
      projectId,
    }),
    LOGGER_TYPE.JUPITER,
  );
};

export const buyPhoneNumber = (
  projectId: string,
  countryCode: CountryCode,
  clientEnv: ClientEnvironment,
) => {
  const accountId = store.getState().account.id;

  return doPost<PhoneNumberResponse>(
    generateNextPath(ENDPOINTS.PHONE_NUMBERS, { accountId, projectId }),
    LOGGER_TYPE.JUPITER,
    {
      country_code: countryCode,
      client_env: clientEnv,
    },
  );
};

export const deletePhoneNumber = (
  phoneNumber: string,
  clientEnv: ClientEnvironment,
) => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  return doDelete(
    generateNextPath(ENDPOINTS.PHONE_NUMBERS, { accountId, projectId }),
    LOGGER_TYPE.JUPITER,
    {
      phone_number: phoneNumber,
      client_env: clientEnv,
    },
  );
};
