import ENDPOINTS from 'api/endpoints';
import { doGet } from 'api/request';
import { LOGGER_TYPE } from 'api/types';
import { generateNextPath } from 'lib/routeHelper';
import { ResourceMetric } from './types';

export const getSmsTemplatesUsage = (accountId: string, projectId: string) => {
  return doGet<ResourceMetric>(
    generateNextPath(ENDPOINTS.SMS_TEMPLATES_USAGE_METRICS, {
      accountId,
      projectId,
    }),
    LOGGER_TYPE.JUPITER,
    { version: 'v2' },
  );
};
