import React from "react";

import ReactLoadingSkeleton, {
  SkeletonProps as ReactLoadingSkeletonProps,
} from "react-loading-skeleton";
import { useTheme } from "styled-components";

import "react-loading-skeleton/dist/skeleton.css";

export interface SkeletonProps extends ReactLoadingSkeletonProps {
  inverse?: boolean;
  inFlexContainer?: boolean;
}

const Skeleton: React.FC<SkeletonProps> = ({
  inverse = false,
  inFlexContainer = false,
  ...props
}) => {
  const {
    activeColorScheme: { colors },
  } = useTheme();

  return (
    <ReactLoadingSkeleton
      {...props}
      baseColor={inverse ? colors.backgroundBrandInverse : undefined}
      containerClassName={
        inFlexContainer ? "skeletonInFlexContainer" : undefined
      }
      duration={2}
      highlightColor={inverse ? colors.backgroundPage : undefined}
    />
  );
};

export default Skeleton;
