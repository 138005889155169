import { UnsavedChangesModalUICopy } from 'components/molecules/UnsavedChangesModal/types';

export const UNSAVED_ENTITY_CHANGES_UI_COPY: UnsavedChangesModalUICopy = {
  title: 'You have unsaved changes!',
  description:
    'Are you sure you want to proceed to a different annotation? One or more entity / values pairs are incomplete. Press enter in the values input after each value to save them (you will see a tag).',
  button: {
    cancel: 'Stay here',
    confirm: 'Discard changes',
  },
};
