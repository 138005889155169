import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface FunctionState {
  isFunctionsDirty: boolean;
}

const initialState: FunctionState = {
  isFunctionsDirty: false,
};

const functionsSlice = createSlice({
  name: 'functionInfo',
  initialState,
  reducers: {
    setIsFunctionsDirty: (state, id: PayloadAction<boolean>) => {
      state.isFunctionsDirty = id.payload;
    },
  },
});

export const { setIsFunctionsDirty } = functionsSlice.actions;

export default functionsSlice.reducer;
