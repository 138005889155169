import ENDPOINTS from 'api/endpoints';
import { doGet, doPost } from 'api/request';
import {
  CopySampleProjectResponse,
  SampleProjectsResponse,
} from 'api/resources/sampleProjects/types';
import { LOGGER_TYPE } from 'api/types';
import { generateNextPath } from 'lib/routeHelper';
import { ProjectVersionState } from '../deployments/types';

export const getSampleProjects = () =>
  doGet<SampleProjectsResponse>(
    ENDPOINTS.SAMPLE_PROJECTS,
    LOGGER_TYPE.JUPITER,
    {
      version: 'v2',
    },
  );

export const getSampleProject = (projectId: string) => {
  return doGet<ProjectVersionState>(
    generateNextPath(ENDPOINTS.SAMPLE_PROJECT, { projectId }),
    LOGGER_TYPE.JUPITER,
    { version: 'v2' },
  );
};

export const copySampleProject = (projectId: string, userAccountId: string) => {
  return doPost<CopySampleProjectResponse>(
    generateNextPath(ENDPOINTS.DUPLICATE_SAMPLE_PROJECT, { projectId }),
    LOGGER_TYPE.JUPITER,
    { account_id: userAccountId },
    { version: 'v2' },
  );
};
