import { TreeList } from "../helpers";

// USER ROLES

// definition of all user roles available across apps
export enum Role {
  ADMIN = "Admin",
  MEMBER = "Member",
  CREATOR = "Creator",
  EDITOR_VIEWER = "Editor/Viewer",
  EDITOR = "Editor",
  VIEWER = "Viewer",
  NO_ACCESS = "No access",
}

type RoleToCopyMapping = {
  [key in Role]?: { name: string; subtext: string; tooltipText?: string };
};

type EntityMapping = {
  PROJECT: string;
};

export type RoleConfig = {
  accountLevelRoles: readonly Role[];
  projectLevelRoles: readonly Role[];
  roleToCopyMap: RoleToCopyMapping;
  entityMap: EntityMapping;
};

// FEATURES
export type FeatureConfig<
  FeatureCategory extends string,
  Feature extends string
> = {
  featureCategories: FeatureCategory[];
  featureTrees: TreeList<FeatureCategory | Feature>;
  featureList: Feature[];
  featureToCopyMap: Record<Feature, string>;
};

// MISC
export type UserManagementProject = {
  id: string;
  name: string;
};
