import { FC, ReactNode, useMemo } from 'react';

import CollapsableTagContainer from '@polyai/ui/components/atoms/CollapsableTagContainer';

import BaseColumn, { ColumnTooltipProps } from './BaseColumn';

import * as Styled from './JupiterCard.styled';

interface Props {
  icon: ReactNode;
  title: string;
  titleTooltip?: ReactNode;
  titleTooltipProps?: ColumnTooltipProps;
  value?: string | number | string[] | null;
  valueTooltip?: ReactNode;
  valueTooltipProps?: ColumnTooltipProps;
  isLoading?: boolean;
  className?: string;
  missingValueText?: string;
}

const ValueColumn: FC<Props> = ({
  icon,
  title,
  titleTooltip,
  value,
  valueTooltip,
  isLoading = false,
  className,
  missingValueText = 'No data',
}) => {
  const isValueArray = Array.isArray(value);
  const isMissing = isValueArray
    ? !value.length ||
      value.every((item) => ([null, undefined] as any[]).includes(item))
    : ([null, undefined] as any[]).includes(value);

  const content = useMemo(() => {
    if (isMissing) {
      return (
        <Styled.CardValueMissing>{missingValueText}</Styled.CardValueMissing>
      );
    }
    if (isValueArray) {
      return <CollapsableTagContainer tags={value as string[]} />;
    }
    return <Styled.CardValue>{value}</Styled.CardValue>;
  }, [isMissing, missingValueText, isValueArray, value]);

  return (
    <BaseColumn
      className={className}
      icon={icon}
      isLoading={isLoading}
      title={<Styled.ValueCaption>{title}</Styled.ValueCaption>}
      titleTooltip={titleTooltip}
      value={content}
      valueTooltip={valueTooltip}
    />
  );
};

export default ValueColumn;
