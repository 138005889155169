import { doPost } from 'api/request';
import { LOGGER_TYPE } from 'api/types';
import { CheckoutSession } from './types';

export const createCheckoutSession = ({
  accountId,
  amount,
}: {
  accountId: string;
  amount: number;
}) => {
  return doPost<CheckoutSession>(
    `accounts/${accountId}/open_checkout`,
    LOGGER_TYPE.JUPITER,
    {
      amount,
    },
  );
};
