import { doGet } from 'api/request';
import { LOGGER_TYPE } from 'api/types';
import { SupportedLanguageResponseType } from './types';

export const getLanguages = () => {
  return doGet<SupportedLanguageResponseType>(
    'languages',
    LOGGER_TYPE.JUPITER,
    { version: 'v2' },
  );
};
