import styled from 'styled-components';

import Tag from '@polyai/ui/components/atoms/Tag';
import { Caption } from '@polyai/ui/components/atoms/Text';
import themeColors from '@polyai/ui/styles/config/colors';
import themeSpacing from '@polyai/ui/styles/config/spacing';

const MIN_AUDIO_PLAYER_HEIGHT = 80;

export const AsrAnnotationToolWrapper = styled.div`
  padding: ${themeSpacing.sm3} ${themeSpacing.sm4} ${themeSpacing.sm4};
  background-color: ${themeColors.background};
  border-radius: ${themeSpacing.radiusMedium};
`;

export const AudioPlayerWrapper = styled.div`
  min-height: ${MIN_AUDIO_PLAYER_HEIGHT}px;
  margin: ${themeSpacing.sm4} 0;
`;

export const ButtonsGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${themeSpacing.md2};
`;

export const TurnNavButtons = styled.div`
  display: flex;
  gap: ${themeSpacing.xs2};
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const IdText = styled(Caption)`
  margin-top: ${themeSpacing.xs2};
  color: ${themeColors.textHelper};
`;

export const DescriptionWrapper = styled.div`
  display: inline;
`;

export const IdTag = styled(Tag)`
  margin-left: ${themeSpacing.xs1};
`;
