import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Placement } from "@floating-ui/react";

import { upCaseFirstLetter } from "@polyai/common/lib/stringHelper";
import { safeResizeObserver } from "@polyai/common/utils/safeResizeObserver";

import { Body } from "components/atoms/Text";

import Tooltip from "../Tooltip";

import { Tag, TagColour, TagContainer } from "./CollapsableTagContainer.styled";

export interface CollapsableTagContainerProps {
  tags: string[];
  maxWidth?: number;
  customColour?: boolean;
  defaultColour?: TagColour;
  upCaseFirstChar?: boolean;
  tooltipPlacement?: Placement;
}

const HIDDEN_TAG_WIDTH = 40;

const CollapsableTagContainer: React.FC<CollapsableTagContainerProps> = ({
  tags = [],
  maxWidth,
  customColour = false,
  defaultColour,
  upCaseFirstChar = false,
  tooltipPlacement,
  ...props
}) => {
  const [cutOffIndex, setCutOffIndex] = useState<number>(tags.length);
  const ref = useRef<HTMLDivElement>(null);
  const [containerScrollWidth, setContainerScrollWidth] = useState(0);

  const containerObserver = useMemo(
    () =>
      safeResizeObserver(([container]) => {
        const { clientWidth, children, childElementCount } = container.target;

        let currentWidth = 0;

        for (let i = 0; i < childElementCount; i++) {
          if (children[i].scrollWidth + currentWidth <= clientWidth) {
            currentWidth += children[i].scrollWidth;
          } else {
            if (currentWidth + HIDDEN_TAG_WIDTH > clientWidth) {
              setCutOffIndex(i - 1);
            } else {
              setCutOffIndex(i);
            }

            break;
          }
        }

        setContainerScrollWidth(clientWidth);
      }),
    []
  );

  useEffect(() => {
    if (tags) {
      setCutOffIndex(tags.length);
    }
  }, [tags]);

  useLayoutEffect(() => {
    if (ref.current) {
      const element = ref.current;
      containerObserver.observe(element);

      return () => containerObserver.unobserve(element);
    }
  }, [containerObserver, containerScrollWidth]);

  const tagsToDisplay = useMemo(
    () => tags.slice(0, cutOffIndex),
    [tags, cutOffIndex]
  );

  const hiddenTags = useMemo(
    () => (cutOffIndex !== undefined ? tags.slice(cutOffIndex) : tags),
    [tags, cutOffIndex]
  );

  return (
    <TagContainer ref={ref} $maxWidth={maxWidth} {...props}>
      {tagsToDisplay.map((tag) => (
        <Tag
          key={`main-tag-${tag}`}
          customColour={customColour}
          defaultColour={defaultColour}
          tag={tag}
        >
          {upCaseFirstChar ? upCaseFirstLetter(tag) : tag}
        </Tag>
      ))}
      {!!hiddenTags.length && (
        <Tooltip
          content={hiddenTags.map((tag) => (
            <Body key={`tooltip-tag-${tag}`}>
              {upCaseFirstChar ? upCaseFirstLetter(tag) : tag}
            </Body>
          ))}
          placement={tooltipPlacement}
        >
          <Tag>{`+${hiddenTags.length}`}</Tag>
        </Tooltip>
      )}
    </TagContainer>
  );
};

export default CollapsableTagContainer;
