import { useMemo } from 'react';

import useRequiredParams from '@polyai/common/hooks/useRequiredParams';
import { Role } from '@polyai/common/types/resources/userManagement';

import useAccounts from './useAccounts';

const useAccountRole = () => {
  const { accountId } = useRequiredParams<{ accountId: string }>();
  const { accounts } = useAccounts();

  const currentAccountRole = useMemo(
    () =>
      accounts.find((account) => account.id === accountId)?.role || Role.MEMBER,
    [accountId, accounts],
  );

  const isAccountAdmin = useMemo(
    () => currentAccountRole === Role.ADMIN,
    [currentAccountRole],
  );

  return { currentAccountRole, isAccountAdmin };
};

export default useAccountRole;
