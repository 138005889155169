import React, { createContext, useContext, useEffect, useState } from 'react';

import { Location, useLocation } from 'react-router-dom';

import { setAccountId } from '../ducks/account/accountSlice';
import { useAppDispatch, useAppSelector } from '../hooks/reduxHooks';

export const PreviousLocationContext = createContext<Location | undefined>(
  undefined,
);

/*
  The PreviousLocationContext keeps track of the previous location.
*/
export function PreviousLocationProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [prevLocation, setPrevLocation] = useState<Location>();
  const [currentLocation, setCurrentLocation] = useState<Location>();

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === currentLocation?.pathname) {
      return;
    }

    setPrevLocation(currentLocation);
    setCurrentLocation(location);
  }, [currentLocation, location]);

  const accountId = useAppSelector((state) => state.account.id);
  const dispatch = useAppDispatch();

  useEffect(() => {
    // set Account ID from URL in case of opening link
    const paths = location.pathname.split('/');
    const account = paths.length && paths[1];
    if (account && account !== accountId && account !== 'accounts') {
      dispatch(setAccountId(account));
    }
  }, [accountId, dispatch, location.pathname]);

  return (
    <PreviousLocationContext.Provider value={prevLocation}>
      {children}
    </PreviousLocationContext.Provider>
  );
}

export const usePreviousLocation = () => useContext(PreviousLocationContext);
