import { AppState, Auth0Provider } from '@auth0/auth0-react';

import { useRouter } from '@polyai/common/hooks/useRouter';
import { ComponentWithChildren } from '@polyai/common/types/helpers';

export const Auth0Wrapper: ComponentWithChildren = ({ children }) => {
  const { replaceUrl } = useRouter();

  const onRedirectCallback = (appState?: AppState) => {
    replaceUrl(appState?.returnTo?.replace(window.location.origin, '') || '/');
  };

  return (
    <Auth0Provider
      authorizationParams={{
        audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE!,
        redirect_uri: `${window.location.origin}/callback`,
      }}
      cacheLocation="localstorage"
      clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID!}
      domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN!}
      useRefreshTokens
      useRefreshTokensFallback
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
