export const ADD_CREDIT_TITLE = 'Add credit';
export const ADD_CREDIT_EMAIL = 'purchase@poly-ai.com';
export const ADD_CREDIT_TEXT =
  "We're glad you're interested in continuing to use our voice assistants. Contact our team to add further credit to your account.";
export const ADD_CREDIT_SUBJECT = 'PolyAI Platform credit request';

export const SUPPORT_TITLE = 'Contact us';
export const SUPPORT_EMAIL = 'platform-support@poly-ai.com';
export const SUPPORT_TEXT =
  'Contact us via email to let us know about the errors you are facing. We aim to respond to queries within 48 business hours.';
export const SUPPORT_SUBJECT = 'PolyAI Platform Error Report';
