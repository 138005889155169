import { FC } from 'react';

import dayjs from 'dayjs';

import { useRouter } from '@polyai/common/hooks/useRouter';
import {
  DocumentationIcon,
  SuccessIcon,
  TimeIcon,
} from '@polyai/ui/components/atoms/Icons';

import JupiterCard from 'components/molecules/JupiterCard';
import CardName from 'components/molecules/JupiterCard/CardName';
import ValueColumn from 'components/molecules/JupiterCard/ValueColumn';

import { BaseAsrAnnotationTasksResponse } from 'api/internalResources/asrAnnotations/types';

interface AsrAnnotationTaskCardProps {
  asrAnnotationTask: BaseAsrAnnotationTasksResponse;
}

const AsrAnnotationTaskCard: FC<AsrAnnotationTaskCardProps> = ({
  asrAnnotationTask,
}) => {
  const { pushUrl } = useRouter();

  const handleCardClick = () => {
    pushUrl(`/asr-annotations/${asrAnnotationTask.task_id}`);
  };

  return (
    <JupiterCard
      cols={['small', 'large', 'small', 'small']}
      onClick={handleCardClick}
    >
      <CardName
        lastUpdated={dayjs(asrAnnotationTask.updated_at).fromNow()}
        name={asrAnnotationTask.name}
      />
      <ValueColumn
        icon={<DocumentationIcon />}
        title="Description"
        value={asrAnnotationTask.description}
      />
      <ValueColumn
        icon={<TimeIcon />}
        title="Due by"
        value={dayjs(asrAnnotationTask.due_by).format('DD/MM/YYYY')}
      />
      <ValueColumn
        icon={<SuccessIcon />}
        title="Completed"
        value={`${asrAnnotationTask.summary.completed} / ${
          asrAnnotationTask.summary.pending +
          asrAnnotationTask.summary.completed
        }`}
      />
    </JupiterCard>
  );
};

export default AsrAnnotationTaskCard;
