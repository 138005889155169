import { FC, ReactElement } from 'react';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import ConditionalWrapper from '@polyai/common/utils/conditionalWrapper';
import { WarningIcon } from '@polyai/ui/components/atoms/Icons';
import Tooltip from '@polyai/ui/components/atoms/Tooltip';
import useIsOverflowing from '@polyai/ui/hooks/useIsOverflowing';

import * as Styled from './JupiterCard.styled';

dayjs.extend(relativeTime);

interface Props {
  name: string;
  lastUpdated?: string;
  loading?: boolean;
  warning?: string;
  icon?: JSX.Element;
  nameFontSize?: Styled.FontSize;
  warningIcon?: JSX.Element;
}

const CardName: FC<Props> = ({
  name,
  lastUpdated,
  loading,
  warning,
  icon,
  nameFontSize = 'lg',
  warningIcon = undefined,
}) => {
  const { isOverflow, targetRef } = useIsOverflowing();

  return (
    <Styled.NameContainer>
      <Styled.CardTextContainer>
        {loading ? (
          <Styled.NameSkeletonLoader />
        ) : (
          <Styled.Name>
            {warning && (
              <Tooltip content={warning}>
                {warningIcon ?? <WarningIcon customFill />}
              </Tooltip>
            )}
            {icon && icon}

            <ConditionalWrapper
              condition={isOverflow}
              wrapper={(children) => (
                <Tooltip
                  content={name}
                  placement="top-start"
                  targetOverflow={isOverflow}
                >
                  {children as ReactElement}
                </Tooltip>
              )}
            >
              <Styled.NameText ref={targetRef} $fontSize={nameFontSize}>
                {name}
              </Styled.NameText>
            </ConditionalWrapper>
          </Styled.Name>
        )}
        {loading ? (
          <Styled.EditedSkeletonLoader />
        ) : (
          lastUpdated && (
            <Styled.ValueCaption>{lastUpdated}</Styled.ValueCaption>
          )
        )}
      </Styled.CardTextContainer>
    </Styled.NameContainer>
  );
};

export default CardName;
