import { forwardRef, memo, PropsWithChildren } from "react";

import { PopupActions, PopupProps } from "reactjs-popup/dist/types";

import useLockScroll from "@polyai/common/hooks/useLockScroll";

import Button from "components/atoms/Button";

import { useBreakpoint } from "../../../hooks/useBreakpoint";

import "reactjs-popup/dist/index.css";
import * as Styled from "./Modal.styled";

export type ModalProps = Omit<PopupProps, "modal"> & {
  size?: keyof typeof Styled.ModalSize;
};

export { ModalBody, ModalButtonsContainer } from "./Modal.styled";

const LockScroll = () => {
  useLockScroll(true);

  return null;
};

export const ModalButtons = ({
  children,
  goBack,
  backButtonText = "Back",
  ...rest
}: PropsWithChildren<{
  goBack?: () => void;
  backButtonText?: string;
}>) => {
  const isSMOrLarger = useBreakpoint("xs");
  const size = !isSMOrLarger ? "sm" : undefined;
  return (
    <Styled.ModalButtonsContainer size={size} {...rest}>
      {goBack && (
        <Button label={backButtonText} variant="GHOST" onClick={goBack} />
      )}
      <Styled.ActionButtonsContainer size={size}>
        {children}
      </Styled.ActionButtonsContainer>
    </Styled.ModalButtonsContainer>
  );
};
const Modal = memo(
  forwardRef<PopupActions, ModalProps>(
    ({ children, size = "md", ...props }, ref) => {
      return (
        <Styled.Popup modal {...props} ref={ref} $size={Styled.ModalSize[size]}>
          <LockScroll />
          {children}
        </Styled.Popup>
      );
    }
  )
);

export default Modal;
