import { ClientEnvironment } from '@polyai/common/constants/global.constants';

export const DRAFT_ENVIRONMENT = 'draft';

export enum JupiterClientEnvironment {
  DRAFT = DRAFT_ENVIRONMENT,
}

export type ClientEnv = ClientEnvironment | JupiterClientEnvironment;

export const environmentOptions: Record<ClientEnv, string> = {
  draft: 'Draft',
  sandbox: 'Sandbox',
  'pre-release': 'Pre-release',
  live: 'Live',
};

export const CLIENT_ENVIRONMENTS = [
  ClientEnvironment.SANDBOX,
  ClientEnvironment.PRE_RELEASE,
  ClientEnvironment.LIVE,
];
