import ENDPOINTS from 'api/endpoints';
import { doDelete, doGet, doPost, doPut } from 'api/request';
import { LOGGER_TYPE } from 'api/types';
import { generateNextPath } from 'lib/routeHelper';
import { Flow, MinimalFlow, UpdateFlow } from './types';

export const getFlows = (accountId: string, projectId: string) => {
  return doGet<MinimalFlow[]>(
    generateNextPath(ENDPOINTS.FLOWS, {
      accountId,
      projectId,
    }),
    LOGGER_TYPE.JUPITER,
    { version: 'v2' },
  );
};

export const getFlow = (
  accountId: string,
  projectId: string,
  flowId: string,
) => {
  return doGet<Flow>(
    generateNextPath(ENDPOINTS.FLOW, {
      accountId,
      projectId,
      flowId,
    }),
    LOGGER_TYPE.JUPITER,
    { version: 'v2' },
  );
};

export const createFlow = ({
  accountId,
  projectId,
  name,
  description,
}: {
  accountId: string;
  projectId: string;
  name: string;
  description: string;
}) => {
  return doPost<Flow>(
    generateNextPath(ENDPOINTS.FLOWS, {
      accountId,
      projectId,
    }),
    LOGGER_TYPE.JUPITER,
    {
      name,
      description,
    },
    { version: 'v2' },
  );
};

export const updateFlow = ({
  accountId,
  projectId,
  flowId,
  flow,
}: {
  accountId: string;
  projectId: string;
  flowId: string;
  flow: UpdateFlow;
}) => {
  return doPut<Flow>(
    generateNextPath(ENDPOINTS.FLOW, {
      accountId,
      projectId,
      flowId,
    }),
    LOGGER_TYPE.JUPITER,
    flow,
    { version: 'v2' },
  );
};

export const duplicateFlow = ({
  accountId,
  projectId,
  flowId,
}: {
  accountId: string;
  projectId: string;
  flowId: string;
}) => {
  return doPost<Flow>(
    generateNextPath(ENDPOINTS.DUPLICATE_FLOW, {
      accountId,
      projectId,
      flowId,
    }),
    LOGGER_TYPE.JUPITER,
    {},
    { version: 'v2' },
  );
};

export const deleteFlow = ({
  accountId,
  projectId,
  flowId,
}: {
  accountId: string;
  projectId: string;
  flowId: string;
}) => {
  return doDelete(
    generateNextPath(ENDPOINTS.FLOW, {
      accountId,
      projectId,
      flowId,
    }),
    LOGGER_TYPE.JUPITER,
    {},
    { version: 'v2' },
  );
};
