import { useEffect, useMemo } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import useMatchPath from '@polyai/common/hooks/useMatchPath';
import useRequiredParams from '@polyai/common/hooks/useRequiredParams';

import api from 'api';
import { setVersionState } from 'utils/versionState';

const useSampleProject = (enabled: boolean = true) => {
  const { accountId, projectId } = useRequiredParams<{
    accountId: string;
    projectId: string;
  }>();

  const queryClient = useQueryClient();

  const matchPath = useMatchPath();
  const inSampleProject = useMemo(
    () => !!matchPath('/templates', false),
    [matchPath],
  );

  const {
    data: sampleProject,
    isLoading: isLoadingSampleProject,
    isError: didSampleProjectError,
  } = useQuery(
    [projectId, 'sample-project'],
    () => api.getSampleProject(projectId),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: enabled && inSampleProject && !!projectId,
    },
  );

  useEffect(() => {
    if (sampleProject) {
      setVersionState({
        accountId,
        projectId,
        queryClient,
        versionStateData: sampleProject,
        mockData: true,
      });
    }
  }, [sampleProject, accountId, projectId, queryClient]);

  return useMemo(
    () => ({
      sampleProject: sampleProject,
      isLoadingSampleProject,
      didSampleProjectError,
      inSampleProject,
    }),
    [
      sampleProject,
      isLoadingSampleProject,
      didSampleProjectError,
      inSampleProject,
    ],
  );
};

export default useSampleProject;
