import styled from "styled-components";

import { Stack } from "components/atoms/Stack";
import { Body, BodyStrong } from "components/atoms/Text";

import themeColors from "styles/config/colors";
import themeSpacing from "styles/config/spacing";

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeSpacing.sm3};

  > h2 {
    margin-bottom: ${themeSpacing.sm1};
  }
`;

export const Subtitle = styled(Body)`
  color: ${themeColors.textSecondary};
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ModalTitle = styled(BodyStrong)`
  font-size: ${({ theme }) => theme.text.size.body.body2};
  font-weight: ${({ theme }) => theme.text.weight.semibold};
`;

export const ModalContent = styled(Stack)`
  white-space: pre-line;
`;

export const Actions = styled(Stack)<{ hasLeftButton?: boolean }>`
  justify-content: ${({ hasLeftButton }) =>
    hasLeftButton ? "space-between" : "flex-end"};
  text-align: right;
`;
