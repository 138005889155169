import styled, { css } from "styled-components";

import themeColors, { baseColors } from "styles/config/colors";
import themeSpacing from "styles/config/spacing";
import { CheckboxState } from ".";

export const CheckboxIconButton = styled.button<{
  $state: CheckboxState;
  $isDarkBackground: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  background-color: ${({ $state }) =>
    [CheckboxState.INDETERMINATE, CheckboxState.CHECKED].includes($state) &&
    themeColors.backgroundPrimary};
  border-radius: ${themeSpacing.radiusSmall};

  transition: all 0.2s ease-in-out;

  ${({ $state }) =>
    $state === CheckboxState.UNCHECKED &&
    css`
      border: 2px solid ${themeColors.borderStrong};
    `}

  &:hover {
    background-color: ${({ $state }) =>
      [CheckboxState.INDETERMINATE, CheckboxState.CHECKED].includes($state) &&
      themeColors.backgroundInverse};
    border-color: ${themeColors.borderUnderline};
  }

  &:focus {
    outline: 4px solid ${themeColors.borderSubtle};
  }

  ${({ $state, $isDarkBackground }) =>
    $isDarkBackground &&
    css`
      background-color: ${[
        CheckboxState.INDETERMINATE,
        CheckboxState.CHECKED,
      ].includes($state) && themeColors.backgroundPrimaryInverse};
      border: ${$state === CheckboxState.UNCHECKED &&
      `2px solid ${baseColors.purpleGrey["50"]}`};

      &:hover {
        background-color: ${[
          CheckboxState.INDETERMINATE,
          CheckboxState.CHECKED,
        ].includes($state) && themeColors.backgroundPrimaryInverseHover};
        border-color: ${baseColors.purpleGrey["30"]};
      }

      &:focus {
        outline: 4px solid ${themeColors.borderPrimary};
      }
    `}
`;
