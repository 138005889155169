import React from "react";

import { ColorName } from "styles/config/colors";
import * as Icons from "../Icons";
import { IconSize } from "../Icons/BaseIcon";

import * as Styled from "./IconFrame.styled";

export interface IconFrameProps {
  type: keyof typeof Icons;
  color?: ColorName;
  backgroundColor?: ColorName;
  size?: keyof typeof IconSize;
}

const IconFrame: React.FC<IconFrameProps> = ({
  type,
  backgroundColor,
  color,
  size = "xl",
}) => {
  return (
    <Styled.IconFrame
      backgroundColor={backgroundColor}
      color={color}
      height={IconSize[size]}
      width={IconSize[size]}
    >
      {Icons[type]({ size })}
    </Styled.IconFrame>
  );
};

export default IconFrame;
