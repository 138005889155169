import styled from 'styled-components';

import Clickable from '@polyai/ui/components/atoms/Clickable';
import themeColors from '@polyai/ui/styles/config/colors';
import themeSpacing from '@polyai/ui/styles/config/spacing';

export const DotsContainer = styled.div`
  position: fixed;
  z-index: -1;
  inset: 0;
  background-color: ${themeColors.backgroundPage};
`;

export const DotPath = styled.path`
  fill: ${themeColors.borderSubtle};
`;

export const SvgTop = styled.svg`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
`;

export const SvgBottom = styled.svg`
  position: fixed;
  top: 60%;
  right: 0;
  left: 0;
`;

export const VerifyEmailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeSpacing.lg1};
  justify-content: center;

  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding: ${themeSpacing.lg1} 0;

  @media (max-width: ${({ theme }) => `${theme.breakpoints.md}px`}) {
    gap: ${themeSpacing.md2};
    padding: ${themeSpacing.md2} ${themeSpacing.sm4};
  }
`;

export const UnderlinedButton = styled(Clickable)`
  text-decoration: underline solid currentcolor;
  text-underline-offset: ${themeSpacing.xxs2};
`;

export const VerifyEmailContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeSpacing.sm3};
  max-width: 450px;
`;
