import ENDPOINTS from 'api/endpoints';
import { doGet, doPut } from 'api/request';
import {
  SmsCredentialsRequest,
  SmsCredentialsResponse,
} from 'api/resources/smsCredentials/types';
import { LOGGER_TYPE } from 'api/types';
import { generateNextPath } from 'lib/routeHelper';
import { store } from 'reduxStore';

const VERSION = 'v2';

export const getSmsCredentials = (integration: string) => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  return doGet<SmsCredentialsResponse>(
    generateNextPath(ENDPOINTS.SMS_CREDENTIALS, {
      accountId,
      projectId,
      integration,
    }),
    LOGGER_TYPE.JUPITER,
    { version: VERSION },
  );
};

export const upsertSmsCredentials = (
  request: SmsCredentialsRequest,
  integration: string,
) => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  return doPut<SmsCredentialsResponse>(
    generateNextPath(ENDPOINTS.SMS_CREDENTIALS, {
      accountId,
      projectId,
      integration,
    }),
    LOGGER_TYPE.JUPITER,
    request,
    { version: VERSION },
  );
};
