import styled, { css } from "styled-components";

import Clickable from "components/atoms/Clickable";
import { ChevronUpIcon } from "components/atoms/Icons";
import { Body } from "components/atoms/Text";

import themeColors from "styles/config/colors";
import themeSpacing from "styles/config/spacing";

const MAX_NAV_GROUP_HEIGHT = "500px";
const NAV_HEADER_HEIGHT = "97px";
const OPEN_NAV_HEADER_HEIGHT = "69px";

interface OpenProp {
  $open?: boolean;
}

interface NavSectionHeaderProps extends OpenProp {
  $isClickable: boolean;
}

export const NavSectionContent = styled.ul<OpenProp>`
  max-height: 0;
  padding: 0 ${themeSpacing.sm3};
  transition: max-height 300ms, padding 300ms;

  ${({ $open }) =>
    $open &&
    css`
      max-height: ${MAX_NAV_GROUP_HEIGHT};
      padding: 0 ${themeSpacing.sm3} ${themeSpacing.sm1};
    `}
`;

export const NavSectionHeader = styled(Clickable)<NavSectionHeaderProps>`
  width: 100%;
  height: ${NAV_HEADER_HEIGHT};
  padding: ${themeSpacing.sm3} ${themeSpacing.sm3};

  border-top: 1px solid ${themeColors.borderSubtleInverse};

  transition: height 250ms;

  ${({ $isClickable }) =>
    $isClickable &&
    css`
      &:hover {
        background-color: ${themeColors.borderSubtleInverse};
      }
    `}

  ${({ $open }) =>
    $open &&
    css`
      height: ${OPEN_NAV_HEADER_HEIGHT};
    `}
`;

export const NavSectionWrapper = styled.div<OpenProp>`
  overflow: hidden;

  &:last-child {
    ${NavSectionHeader} {
      border-bottom: 1px solid ${themeColors.borderSubtleInverse};
    }
  }
`;

export const NavSectionHeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ClickableContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NavSectionDescription = styled(Body)<OpenProp>`
  transform: translateY(0);

  overflow: hidden;

  width: 100%;
  margin-top: ${themeSpacing.xs3};

  font-size: ${themeSpacing.xs3};
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;

  opacity: 1;

  transition: transform 250ms 250ms, opacity 250ms 250ms;

  ${({ $open }) =>
    $open &&
    css`
      transform: translateY(-20px);
      max-height: 0;
      margin: 0;
      opacity: 0;
    `}
`;

export const Chevron = styled(ChevronUpIcon)<OpenProp>`
  svg {
    transform: scaleY(-1);
    opacity: 0;
    transition: transform 250ms, opacity 250ms;

    ${({ $open }) =>
      $open &&
      css`
        transform: scaleY(1);
        opacity: 1;
      `}
  }
`;
