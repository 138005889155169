import React from "react";

import {
  FloatingFocusManager,
  FloatingPortal,
  useMergeRefs,
} from "@floating-ui/react";

import { usePopoverContext } from "./Provider";

import * as Styled from "./Popover.styled";

export const PopoverContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement>
>(function PopoverContent({ style, className, ...props }, propRef) {
  const { context: floatingContext, ...context } = usePopoverContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);

  if (!floatingContext.open) return null;

  return (
    <FloatingPortal>
      <FloatingFocusManager
        context={floatingContext}
        modal={context.modal}
        closeOnFocusOut
      >
        <Styled.PopoverContet
          ref={ref}
          aria-describedby={context.descriptionId}
          aria-labelledby={context.labelId}
          className={className}
          style={{ ...context.floatingStyles, ...style }}
          {...context.getFloatingProps(props)}
        >
          {props.children}
        </Styled.PopoverContet>
      </FloatingFocusManager>
    </FloatingPortal>
  );
});
