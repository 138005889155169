import { TreeList } from '@polyai/common/types/helpers';
import { FeatureConfig } from '@polyai/common/types/resources/userManagement';
import { getSubtreeValues } from '@polyai/ui/components/molecules/HierarchicalMenuPopover';

import {
  PROJECT_RESOURCE,
  PROJECT_RESOURCE_CATEGORY,
} from 'hooks/useProjectResources';

export const featureToCopyMap: Record<
  PROJECT_RESOURCE | PROJECT_RESOURCE_CATEGORY,
  string
> = {
  // CATEGORIES
  [PROJECT_RESOURCE_CATEGORY.BUILD]: 'Build',
  [PROJECT_RESOURCE_CATEGORY.ANALYZE]: 'Analyze',
  [PROJECT_RESOURCE_CATEGORY.CONFIGURE]: 'Configure',

  // ADMIN
  [PROJECT_RESOURCE.BILLING]: 'Billing',
  [PROJECT_RESOURCE.SECRETS]: 'Secrets',
  [PROJECT_RESOURCE.USER_MANAGEMENT]: 'User Management',

  // BUILD
  [PROJECT_RESOURCE.KNOWLEDGE_BASE]: 'Knowledge Base',
  [PROJECT_RESOURCE.VOICE]: 'Voice',
  [PROJECT_RESOURCE.ABOUT]: 'About',
  [PROJECT_RESOURCE.RULES]: 'Rules',
  [PROJECT_RESOURCE.SPEECH_RECOGNITION]: 'Speech Recognition',
  [PROJECT_RESOURCE.RESPONSE_CONTROL]: 'Response Control',
  [PROJECT_RESOURCE.FUNCTIONS]: 'Functions',
  [PROJECT_RESOURCE.HANDOFFS]: 'Call Handoffs',
  [PROJECT_RESOURCE.SMS]: 'SMS',
  [PROJECT_RESOURCE.FLOWS]: 'Flows',

  // ANALYZE
  [PROJECT_RESOURCE.ANALYTICS]: 'Overview',
  [PROJECT_RESOURCE.SAFETY_ANALYTICS]: 'Assistant Safety',
  [PROJECT_RESOURCE.CONVERSATIONS]: 'Conversations',
  [PROJECT_RESOURCE.PII]: 'PII',
  [PROJECT_RESOURCE.CALL_DOWNLOAD]: 'Call download',

  // CONFIGURE
  [PROJECT_RESOURCE.AUDIO_MANAGEMENT]: 'Audio management',
  [PROJECT_RESOURCE.SETTINGS]: 'Settings',
  [PROJECT_RESOURCE.ENVIRONMENTS]: 'Environments',
  [PROJECT_RESOURCE.VARIANT_MANAGEMENT]: 'Variant management',
};

const featureCategories = Object.values(PROJECT_RESOURCE_CATEGORY);

export const getFeatureConfig = (enabledFeatures: {
  [f in PROJECT_RESOURCE]: boolean;
}): FeatureConfig<PROJECT_RESOURCE_CATEGORY, PROJECT_RESOURCE> => {
  const isResourceEnabled = (resource: PROJECT_RESOURCE) =>
    enabledFeatures[resource] !== false;
  const featureTrees: TreeList<PROJECT_RESOURCE | PROJECT_RESOURCE_CATEGORY> = [
    {
      [PROJECT_RESOURCE_CATEGORY.BUILD]: [
        PROJECT_RESOURCE.KNOWLEDGE_BASE,
        PROJECT_RESOURCE.VOICE,
        PROJECT_RESOURCE.ABOUT,
        PROJECT_RESOURCE.RULES,
        PROJECT_RESOURCE.SPEECH_RECOGNITION,
        PROJECT_RESOURCE.RESPONSE_CONTROL,
        PROJECT_RESOURCE.FUNCTIONS,
        PROJECT_RESOURCE.HANDOFFS,
        PROJECT_RESOURCE.SMS,
        PROJECT_RESOURCE.FLOWS,
      ].filter(isResourceEnabled),
    },
    {
      [PROJECT_RESOURCE_CATEGORY.ANALYZE]: [
        PROJECT_RESOURCE.ANALYTICS,
        PROJECT_RESOURCE.SAFETY_ANALYTICS,
        {
          // Put call_download under PII if PII is enabled. Otherwise put it under conversations.
          [PROJECT_RESOURCE.CONVERSATIONS as string]: enabledFeatures[
            PROJECT_RESOURCE.PII
          ]
            ? [
                {
                  [PROJECT_RESOURCE.PII]: [
                    PROJECT_RESOURCE.CALL_DOWNLOAD,
                  ].filter(isResourceEnabled),
                },
              ]
            : [PROJECT_RESOURCE.CALL_DOWNLOAD].filter(isResourceEnabled),
        },
      ].filter((r) => {
        if (typeof r === 'object') {
          return enabledFeatures[PROJECT_RESOURCE.CONVERSATIONS] !== false;
        }
        return enabledFeatures[r] !== false;
      }),
    },
    {
      [PROJECT_RESOURCE_CATEGORY.CONFIGURE]: [
        PROJECT_RESOURCE.AUDIO_MANAGEMENT,
        PROJECT_RESOURCE.ENVIRONMENTS,
        PROJECT_RESOURCE.VARIANT_MANAGEMENT,
        PROJECT_RESOURCE.SETTINGS,
      ].filter(isResourceEnabled),
    },
  ];

  const featureList = featureTrees
    .flatMap((featureTree) => getSubtreeValues(featureTree))
    .filter((feature) =>
      Object.values(PROJECT_RESOURCE).includes(feature as PROJECT_RESOURCE),
    )
    .sort() as PROJECT_RESOURCE[];

  return {
    featureCategories,
    featureTrees,
    featureList,
    featureToCopyMap,
  };
};
