import React, { useMemo, useRef } from 'react';

import {
  DOCS_GET_STARTED,
  DOCS_HOME,
  DOCS_PRIVACY,
  DOCS_TOS,
} from 'constants/Docs.constants';
import { useAuth0 } from '@auth0/auth0-react';
import { PopupActions } from 'reactjs-popup/dist/types';

import useRequiredParams from '@polyai/common/hooks/useRequiredParams';
import { useRouter } from '@polyai/common/hooks/useRouter';
import IconButton from '@polyai/ui/components/atoms/IconButton';
import {
  CaretDownIcon,
  CatalogIcon,
  HelpIcon,
  LogoutIcon,
  UserIcon,
} from '@polyai/ui/components/atoms/Icons';
import { CurrencyIcon } from '@polyai/ui/components/atoms/Icons/CurrencyIcon';
import Logo from '@polyai/ui/components/atoms/Logo';
import {
  PopupMenu,
  PopupMenuItem,
} from '@polyai/ui/components/molecules/PopupMenu';
import { useBreakpoint } from '@polyai/ui/hooks/useBreakpoint';

import AccountModal from 'components/molecules/AccountModal';
import UserBilling, {
  UserBillingModal,
} from 'components/molecules/UserBilling';

import { useAppSelector } from 'hooks/reduxHooks';
import useAccountRole from 'hooks/useAccountRole';
import {
  GLOBAL_PERMISSION,
  useGlobalPermissions,
} from 'hooks/useProjectResources/useGlobalPermissions';
import { getActionName } from 'lib/ddlogHelper';

import * as MainNavStyled from '../../organisms/MainNav/MainNav.styled';
import * as Styled from './LandingNav.styled';

const LandingNav = ({ isErrorPage = false }: { isErrorPage?: boolean }) => {
  const { accountId } = useRequiredParams<{
    accountId: string;
  }>();

  const logout = useAppSelector((state) => state.auth.logout);
  const isNotMobile = useBreakpoint('xs');

  const { user } = useAuth0();
  const { isAccountAdmin } = useAccountRole();
  const { pushUrl } = useRouter();
  const { userHasGlobalPermission } = useGlobalPermissions();

  const accountMenuRef = useRef<PopupActions>();
  const helpMenuRef = useRef<PopupActions>();

  const closePopupWithCallback = (callback: () => void) => {
    accountMenuRef.current?.close();

    callback();
  };

  const userName = useMemo(() => {
    if (user?.given_name && user.family_name) {
      return `${user.given_name} ${user.family_name}`;
    }

    if (user?.given_name) {
      return user.given_name;
    }

    return user?.name ?? '';
  }, [user]);

  return (
    <Styled.LandingNavWrapper>
      <MainNavStyled.LogoWrapper data-test-id="back-to-home-page-button">
        <MainNavStyled.LogoLink to={`/${accountId ?? ''}`}>
          <Logo />
        </MainNavStyled.LogoLink>
      </MainNavStyled.LogoWrapper>
      {!isErrorPage && isNotMobile && isAccountAdmin && (
        <UserBilling justify="end" />
      )}
      <Styled.DropdownWrapper>
        <Styled.AccountActionsMenu>
          <PopupMenu
            contentStyle={{
              width: 'auto',
              marginTop: '8px',
            }}
            offsetY={8}
            popupRef={accountMenuRef}
            position="bottom right"
            size="sm"
            trigger={
              <Styled.AccountActionsMenuButton
                data-test-id="menu-popup-button"
                hoverTargetColor="backgroundPrimaryHover"
                hoverTarget
              >
                <Styled.UserName>{userName}</Styled.UserName>
                <CaretDownIcon size="lg" />
              </Styled.AccountActionsMenuButton>
            }
            nested
          >
            {!isErrorPage && !isNotMobile && isAccountAdmin && (
              <UserBillingModal
                trigger={
                  <Styled.PopupMenuButton
                    data-dd-action={getActionName(
                      'Billing',
                      'open',
                      'add credit',
                    )}
                  >
                    <CurrencyIcon color="iconSecondaryInverse" />
                    <span>Add credit</span>
                  </Styled.PopupMenuButton>
                }
                nested
              />
            )}
            <AccountModal
              trigger={
                <Styled.PopupMenuButton
                  onClick={() => closePopupWithCallback(logout as () => void)}
                >
                  <UserIcon color="iconSecondaryInverse" />
                  <span>Account</span>
                </Styled.PopupMenuButton>
              }
            />
            {userHasGlobalPermission(GLOBAL_PERMISSION.PLATFORM_ADMIN) && (
              <Styled.PopupMenuButton
                onClick={() =>
                  closePopupWithCallback(() => {
                    pushUrl('/settings/features');
                  })
                }
              >
                <CatalogIcon color="iconSecondaryInverse" />
                <span>Feature flags</span>
              </Styled.PopupMenuButton>
            )}
            <Styled.PopupMenuButton
              onClick={() => closePopupWithCallback(logout as () => void)}
            >
              <LogoutIcon color="iconSecondaryInverse" />
              <span>Log out</span>
            </Styled.PopupMenuButton>
          </PopupMenu>
        </Styled.AccountActionsMenu>

        <PopupMenu
          contentStyle={{
            width: 'auto',
            marginTop: '8px',
          }}
          offsetY={8}
          popupRef={helpMenuRef}
          position="bottom right"
          size="sm"
          trigger={
            <IconButton hoverTargetColor="backgroundPrimaryHover" hoverTarget>
              <HelpIcon color="iconPrimaryInverse" />
            </IconButton>
          }
          nested
        >
          <PopupMenuItem>
            <Styled.HelpLink
              data-dd-action={getActionName('Help', 'open', 'Getting Started')}
              href={DOCS_GET_STARTED}
              rel="noreferrer"
              target="_blank"
            >
              Getting Started
            </Styled.HelpLink>
          </PopupMenuItem>
          <PopupMenuItem>
            <Styled.HelpLink
              data-dd-action={getActionName('Help', 'open', 'Help Centre')}
              href={DOCS_HOME}
              rel="noreferrer"
              target="_blank"
            >
              Help Centre
            </Styled.HelpLink>
          </PopupMenuItem>
          <PopupMenuItem>
            <Styled.HelpLink
              data-dd-action={getActionName(
                'Help',
                'open',
                'Terms and Service',
              )}
              href={DOCS_TOS}
              rel="noreferrer"
              target="_blank"
            >
              Terms and Service
            </Styled.HelpLink>
          </PopupMenuItem>
          <PopupMenuItem>
            <Styled.HelpLink
              data-dd-action={getActionName('Help', 'open', 'Privacy Policy')}
              href={DOCS_PRIVACY}
              rel="noreferrer"
              target="_blank"
            >
              Privacy Policy
            </Styled.HelpLink>
          </PopupMenuItem>
        </PopupMenu>
      </Styled.DropdownWrapper>
    </Styled.LandingNavWrapper>
  );
};

export default LandingNav;
