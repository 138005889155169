const innerBankersRound = (num: number): number => {
  const rounded = Math.round(num);
  const diff = Math.abs(num - rounded);

  if (diff === 0.5) {
    if (rounded % 2 !== 0) {
      // If the rounded number is odd, return the nearest even number
      return Math.floor(num) % 2 === 0 ? Math.floor(num) : Math.ceil(num);
    }
  }

  return rounded;
};

export const bankersRound = (
  num: number,
  decimalPlaces: number = 0
): number => {
  const factor = 10 ** decimalPlaces;
  return innerBankersRound(num * factor) / factor;
};

export const determineBonus = (amount: number): number => {
  if (amount < 50) {
    return 0;
  }
  if (amount < 100) {
    return bankersRound(amount * 0.05);
  }
  if (amount < 250) {
    return bankersRound(amount * 0.1);
  }
  return bankersRound(amount * 0.15);
};

export const formatCurrency = (
  amount: number,
  {
    currencySymbol = "",
    precision = 2,
  }: { currencySymbol?: string; precision?: number } = {}
): string => {
  if (isNaN(amount)) {
    return "NaN";
  }
  if (Math.abs(amount) === Infinity) {
    return amount.toString();
  }

  const isNegative = amount < 0;
  const absoluteAmount = Math.abs(amount);

  const formattedAmount = absoluteAmount.toLocaleString(undefined, {
    maximumFractionDigits: precision,
  });

  return isNegative
    ? `-${currencySymbol}${formattedAmount}`
    : `${currencySymbol}${formattedAmount}`;
};
