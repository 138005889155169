import { MouseEvent, useMemo } from "react";

import { ComponentWithChildren } from "@polyai/common/types/helpers";

import { CheckboxCheckIcon } from "../Icons/CheckboxCheckIcon";
import { CheckboxMinusIcon } from "../Icons/CheckboxMinusIcon";

import * as Styled from "./Checkbox.styled";

export enum CheckboxState {
  INDETERMINATE,
  UNCHECKED,
  CHECKED,
}

type CheckboxProps = {
  state: CheckboxState;
  onChange?: (e: any) => void;
  isDarkBackground?: boolean;
  checkboxWrapperStyles?: string;
};

const Checkbox: ComponentWithChildren<CheckboxProps> = ({
  state,
  onChange,
  isDarkBackground = false,
  children,
  ...props
}) => {
  const onClickHandler = (event: MouseEvent<HTMLElement>) => {
    const newChecked = [
      CheckboxState.INDETERMINATE,
      CheckboxState.CHECKED,
    ].includes(state)
      ? false
      : true;

    const checkEventTarget = { ...event.target, checked: newChecked };
    event.target = checkEventTarget;
    onChange && onChange(event);
  };

  const iconColor = useMemo(
    () => (isDarkBackground ? "iconPrimary" : "iconPrimaryInverse"),
    [isDarkBackground]
  );

  return (
    <Styled.CheckboxIconButton
      $isDarkBackground={isDarkBackground}
      $state={state}
      as={!onChange ? "div" : "button"}
      onClick={onClickHandler}
      {...props}
    >
      {state === CheckboxState.INDETERMINATE && (
        <CheckboxMinusIcon color={iconColor} size="sm" />
      )}
      {state === CheckboxState.CHECKED && (
        <CheckboxCheckIcon color={iconColor} size="sm" />
      )}
      {children}
    </Styled.CheckboxIconButton>
  );
};

export default Checkbox;
