import { MouseEventHandler } from "react";

import { InputText } from "components/atoms/Text";

import * as Styled from "./NavSection.styled";

type NavSectionType = {
  title?: string;
  description?: string;
  isOpen?: boolean;
  handleClick?: MouseEventHandler<HTMLButtonElement>;
  children: React.ReactChild | React.ReactChild[];
  className?: string;
};

const NavSection = ({
  title,
  description,
  isOpen = true,
  handleClick,
  children,
  className,
  ...props
}: NavSectionType) => {
  const isClickable = !!handleClick;
  return (
    <Styled.NavSectionWrapper $open={isOpen} className={className} {...props}>
      <Styled.NavSectionHeader
        $isClickable={isClickable}
        $open={isOpen}
        disabled={!isClickable}
        onClick={handleClick}
      >
        <Styled.NavSectionHeaderContent>
          <InputText>{title}</InputText>
          {isClickable && <Styled.Chevron $open={isOpen} />}
        </Styled.NavSectionHeaderContent>
        <Styled.NavSectionDescription $open={isOpen}>
          {description}
        </Styled.NavSectionDescription>
      </Styled.NavSectionHeader>
      <Styled.NavSectionContent $open={isOpen}>
        {children}
      </Styled.NavSectionContent>
    </Styled.NavSectionWrapper>
  );
};

export default NavSection;
