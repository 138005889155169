import { useCallback, useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Auth0Wrapper } from 'Auth0Wrapper';
import dynamic from 'next/dynamic';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import { Toaster } from 'sonner';
import { ThemeProvider } from 'styled-components';

import useRequiredParams from '@polyai/common/hooks/useRequiredParams';
import { extractDomainFromEmail } from '@polyai/common/lib/emailHelper';
import debounce from '@polyai/common/utils/debounce';
import Layout from '@polyai/ui/components/atoms/Layout';
import { Loader } from '@polyai/ui/components/atoms/Loader';
import { getAppTheme } from '@polyai/ui/styles/theme';

import ErrorBoundary from 'components/molecules/ErrorBoundary';
import LazyScreen from 'components/molecules/LazyScreen';
import PrivatePage from 'components/molecules/PrivatePage';
import UserQuestionnaire from 'components/molecules/UserQuestionnaire';
import LandingLayout from 'components/organisms/LandingLayout';

import QueryClientProvider from 'api/queryClient';
import { LOGGER_TYPE } from 'api/types';
import { Auth } from 'auth';
import { ApiProvider } from 'contexts/ApiContext';
import { PreviousLocationProvider } from 'contexts/PreviousLocationContext';
import { closeMainNav, openMainNav } from 'ducks/layout/layoutSlice';
import { saveTableState } from 'ducks/table/persist';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { store } from 'reduxStore';
import { PAGE_TITLES, PATHS } from 'routes/routes.constants';
import AsrAnnotationReviewScreen from 'screens/AsrAnnotationReview';
import AsrAnnotationsScreen from 'screens/AsrAnnotations';
import CallbackPage from 'screens/Callback';
import ErrorPage, { ErrorStatus } from 'screens/ErrorPage';
import { TOAST_DURATION } from '../components/atoms/Alert';
import GlobalStyle from '../styles/global';

import 'styles/fonts.css';
import 'wipe.css';

const AssistantNavigation = dynamic(
  () => import('components/organisms/AssistantNavigation'),
  { ssr: false },
);
const CreateAssistantPage = dynamic(() => import('screens/CreateAssistant'), {
  ssr: false,
});
const FeatureSwitchScreen = dynamic(() => import('screens/FeatureSwitch'), {
  ssr: false,
});
const ProjectPage = dynamic(() => import('./ProjectPage'), { ssr: false });

console.log('PolyAI environment:', process.env.NEXT_PUBLIC_POLYAI_ENVIRONMENT);

declare global {
  interface Window {
    Cypress: any;
    webkitAudioContext: typeof AudioContext;
  }
}

store.subscribe(
  debounce(() => {
    saveTableState(store.getState().table);
  }, 1000),
);

const KnowledgeBaseRedirect = () => {
  // Assuming you have access to the `match` object
  const { accountId, projectId } = useRequiredParams<{
    accountId: string;
    projectId: string;
  }>();

  // Construct the new path by appending '/knowledge-base' to the current URL
  const newPath = `/${accountId}/${projectId}/knowledge-base`;

  // Return the Navigate component with the new path
  return <Navigate to={newPath} />;
};

const App = () => {
  const isAuthorised = useAppSelector((state) => state.auth.isAuthorised);
  const showUnauthorised = useAppSelector(
    (state) => state.auth.showUnauthorised,
  );
  const accountId = useAppSelector((state) => state.account.id);
  const { user } = useAuth0();

  useEffect(() => {
    const region = process.env.NEXT_PUBLIC_POLYAI_REGION;
    if (!window.parent.Cypress) {
      datadogRum.init({
        applicationId: process.env.NEXT_PUBLIC_DD_APP_ID!,
        clientToken: process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN!,
        env: process.env.NEXT_PUBLIC_POLYAI_ENVIRONMENT!,
        version: process.env.NEXT_PUBLIC_APP_VERSION!,
        site: region === 'us-1' ? 'datadoghq.com' : 'datadoghq.eu',
        service: 'jupiter-ui',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        actionNameAttribute: 'data-dd-action',
        defaultPrivacyLevel: 'allow',
        trackViewsManually: true,
      });

      datadogLogs.init({
        clientToken: process.env.NEXT_PUBLIC_DD_LOGS_CLIENT_TOKEN!,
        version: process.env.NEXT_PUBLIC_APP_VERSION!,
        site: region === 'us-1' ? 'datadoghq.com' : 'datadoghq.eu',
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
        service: 'jupiter-ui',
      });

      for (const logger of Object.values(LOGGER_TYPE)) {
        datadogLogs.createLogger(logger, {
          level: 'info',
          handler: 'http',
          context: {
            env: process.env.NEXT_PUBLIC_POLYAI_ENVIRONMENT!,
          },
        });
      }

      datadogRum.startSessionReplayRecording();
    }
  }, []);

  useEffect(() => {
    datadogRum.setUser({
      id: user?.email,
      name: user?.name,
      email: user?.email,
      email_domain: extractDomainFromEmail(user?.email),
    });
  }, [user]);

  useEffect(() => {
    if (accountId === undefined) {
      datadogRum.removeGlobalContextProperty('accountId');
    }
    datadogRum.setGlobalContextProperty('accountId', accountId);
  }, [accountId]);

  if (showUnauthorised) {
    return <ErrorPage type={ErrorStatus.UNAUTHORIZED} />;
  }

  if (isAuthorised) {
    return (
      <PreviousLocationProvider>
        <Routes>
          <Route element={<CallbackPage />} path="/callback" />
          <Route
            element={
              <LazyScreen>
                <AsrAnnotationsScreen />
              </LazyScreen>
            }
            path="/asr-annotations"
          />
          <Route
            element={
              <LazyScreen>
                <AsrAnnotationReviewScreen />
              </LazyScreen>
            }
            path="/asr-annotations/:taskId"
          />
          <Route
            element={
              <PrivatePage ErrorPage={ErrorPage}>
                <LandingLayout />
              </PrivatePage>
            }
          >
            <Route
              element={
                <LazyScreen title={PAGE_TITLES.CREATE_ASSISTANT}>
                  <CreateAssistantPage />
                </LazyScreen>
              }
              path={PATHS.CREATE_ASSISTANT}
            />
            <Route
              element={
                <LazyScreen title={PAGE_TITLES.ASSISTANTS}>
                  <AssistantNavigation tab="assistants" />
                </LazyScreen>
              }
              path={PATHS.ASSISTANTS}
            />
            <Route
              element={
                <LazyScreen title={PAGE_TITLES.BILLING}>
                  <AssistantNavigation tab="billing" />
                </LazyScreen>
              }
              path={PATHS.BILLING}
            />
            <Route
              element={
                <LazyScreen title={PAGE_TITLES.SECRETS}>
                  <AssistantNavigation tab="secrets" />
                </LazyScreen>
              }
              path={PATHS.SECRETS}
            />
            <Route
              element={
                <LazyScreen title={PAGE_TITLES.USERS}>
                  <AssistantNavigation tab="users" />
                </LazyScreen>
              }
              path={PATHS.USERS}
            />
          </Route>
          <Route
            element={
              <PrivatePage ErrorPage={ErrorPage}>
                <FeatureSwitchScreen />
              </PrivatePage>
            }
            path="/settings/features"
          />
          <Route
            element={
              <PrivatePage ErrorPage={ErrorPage}>
                <ProjectPage />
              </PrivatePage>
            }
            path={'/:accountId/:projectId/*'}
          />
          <Route
            element={<KnowledgeBaseRedirect />}
            path="/:accountId/:projectId"
          />
          <Route element={<ErrorPage type={ErrorStatus.NOT_FOUND} />} />
        </Routes>
        <UserQuestionnaire />
      </PreviousLocationProvider>
    );
  }

  return <Loader inProjectPage={false} fullScreen spinner />;
};

const AppContent = () => {
  const dispatch = useAppDispatch();
  const handleOpenMainNav = useCallback(
    () => dispatch(openMainNav()),
    [dispatch],
  );
  const handleCloseMainNav = useCallback(
    () => dispatch(closeMainNav()),
    [dispatch],
  );

  return (
    <Layout
      handleCloseMainNav={handleCloseMainNav}
      handleOpenMainNav={handleOpenMainNav}
    >
      <App />
    </Layout>
  );
};

const AppWithinStore = () => {
  const themeName = useAppSelector((state) => state.layout.theme);

  return (
    <ThemeProvider theme={getAppTheme(themeName)}>
      <ApiProvider>
        <GlobalStyle />
        <Auth0Wrapper>
          <Auth>
            <Toaster
              toastOptions={{
                unstyled: true,
                duration: TOAST_DURATION,
              }}
            />
            <QueryClientProvider>
              <ErrorBoundary>
                <AppContent />
              </ErrorBoundary>
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </Auth>
        </Auth0Wrapper>
      </ApiProvider>
    </ThemeProvider>
  );
};

const AppWrapper = () => {
  return (
    <Router>
      <Provider store={store}>
        <AppWithinStore />
      </Provider>
    </Router>
  );
};

export default AppWrapper;
