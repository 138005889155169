import styled from "styled-components";

import { CopyIcon } from "components/atoms/Icons";
import { Body, BodyStrong } from "components/atoms/Text";

import themeColors from "styles/config/colors";
import themeSpacing from "styles/config/spacing";

export const FlexBox = styled.div`
  display: flex;
  align-items: center;
`;

export const CenteredDiv = styled(FlexBox)`
  flex: 1 0;
  justify-content: center;
`;

export const BoldCell = styled(BodyStrong)`
  font-weight: 600;
  color: ${themeColors.textPrimary};
`;

export const HiddenOverflowCell = styled(Body)`
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    z-index: 2;

    overflow: visible;

    padding-right: 8px;
    padding-left: 0;

    text-overflow: inherit;
    text-wrap: break-word;

    background-color: ${themeColors.background};
  }
`;

export const NoShrink = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
`;

export const TextOverflowWrapper = styled.div`
  position: relative;
`;

export const InvisibleText = styled.div`
  max-height: 2.6ex;
  visibility: hidden;
`;

export const OverflowTooltipWrapper = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
`;

export const TooltipText = styled.div`
  overflow-wrap: break-word;
`;

export const TextEllipsis = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  white-space: nowrap;
`;

export const CopyContainer = styled.div<{ $showOverflow: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  ${({ $showOverflow }) =>
    !$showOverflow &&
    `
      overflow: hidden;
      text-overflow: ellipsis;
    `}
  white-space: ${({ $showOverflow }) => ($showOverflow ? "initial" : "nowrap")};

  &:hover #copy-icon {
    display: flex;
  }
`;

export const CopyBody = styled(Body)`
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CopyIconContainer = styled.div<{ $backgroundColor: string }>`
  cursor: pointer;

  position: absolute;
  top: -10px;
  right: 0;

  display: none;

  margin: auto;
  padding: ${themeSpacing.xs2};
  padding-right: 0;

  ${({ $backgroundColor }) => `
    background: radial-gradient(
      circle at 100% 50%,
      ${$backgroundColor} 60%,
      transparent
    );
    background: -webkit-radial-gradient(
      circle at 100% 50%,
      ${$backgroundColor} 60%,
      transparent
    );
  `}
`;

export const BlockCopyIcon = styled(CopyIcon)`
  margin: auto 0;
`;

export const TooltipWrapper = styled.div`
  display: flex;
  max-width: 100%;
`;

export const QuoteCellWrapper = styled(FlexBox)`
  gap: ${themeSpacing.xs2};
  padding: ${themeSpacing.xs3} 0;
`;
