import { ClientEnvironment } from '@polyai/common/constants/global.constants';

import { doGet, doPost } from 'api/request';
import {
  AllTelephonyTokensResponse,
  TelephonyTokenResponse,
} from 'api/resources/telephony/types';
import { LOGGER_TYPE } from 'api/types';
import { store } from 'reduxStore';

export const getConnectionToken = () => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;
  return doGet<TelephonyTokenResponse>(
    `accounts/${accountId}/projects/${projectId}/telephony/token`,
    LOGGER_TYPE.JUPITER,
    {
      params: {
        client_env: ClientEnvironment.SANDBOX,
      },
    },
  );
};

export const getAllConnectionTokens = () => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;
  return doGet<AllTelephonyTokensResponse>(
    `accounts/${accountId}/projects/${projectId}/telephony/token/list`,
    LOGGER_TYPE.JUPITER,
  );
};

export const createConnector = (clientEnv: ClientEnvironment) => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;
  return doPost<TelephonyTokenResponse>(
    `accounts/${accountId}/projects/${projectId}/telephony/connector`,
    LOGGER_TYPE.JUPITER,
    {
      client_env: clientEnv,
    },
  );
};
