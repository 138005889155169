import { PropsWithChildren } from 'react';

import LoadingIcon from '@polyai/ui/components/atoms/LoadingIcon';
import { useBreakpoint } from '@polyai/ui/hooks/useBreakpoint';
import { ColorName } from '@polyai/ui/styles/config/colors';

import * as Styled from './JupiterCard.styled';

interface Props extends PropsWithChildren {
  cols: Styled.ColWidth[];
  to?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  onClick?: () => void;
  loading?: boolean;
  loadingMessage?: string;
  secondary?: boolean;
  borderColor?: ColorName | undefined;
}

export const JupiterCardList = Styled.JupiterCardList;
export const CardContent = Styled.CardContent;

const JupiterCard = ({
  cols,
  to,
  disabled,
  onClick,
  children,
  fullWidth,
  loading,
  secondary,
  borderColor,
  loadingMessage = '',
}: Props) => {
  const isLargeScreen = useBreakpoint('sm');
  return (
    <Styled.Container
      $borderColor={borderColor}
      $cols={cols}
      $disabled={disabled}
      $fullWidth={fullWidth}
      $loading={loading}
      $mobile={!isLargeScreen}
      $secondary={secondary}
      to={to}
      onClick={onClick}
    >
      {loading ? (
        <Styled.LoadingWrapper>
          <LoadingIcon />{' '}
          <Styled.LoadingMessage>{loadingMessage}</Styled.LoadingMessage>
        </Styled.LoadingWrapper>
      ) : (
        children
      )}
    </Styled.Container>
  );
};

export default JupiterCard;
