import { useCallback, useState } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import api from 'api';
import { useAppSelector } from 'hooks/reduxHooks';
import { useToast } from 'hooks/useToast';
import { getBalanceQueryKey } from './useBalance';

const PAYMENTS_MUTATION_KEY = 'payments';

const usePayment = () => {
  const accountId = useAppSelector((state) => state.account.id);
  const [clientSecret, setClientSecret] = useState<string>();
  const toast = useToast();

  const queryClient = useQueryClient();

  const refreshBalance = useCallback(() => {
    queryClient
      .invalidateQueries(getBalanceQueryKey(accountId))
      .catch((e) =>
        console.error('Error occurred. Could not invalidate queries', e),
      );
  }, [accountId, queryClient]);

  const { mutate: openCheckout, isLoading: isOpeningCheckout } = useMutation(
    ({ amount }: { amount: number }) =>
      api.createCheckoutSession({ accountId, amount }),
    {
      mutationKey: [PAYMENTS_MUTATION_KEY],
      onSuccess: (data) => {
        setClientSecret(data.client_secret);
      },
      onError: () => {
        toast.error({
          title: 'There was an error creating the checkout session',
        });
      },
    },
  );

  const closeCheckout = useCallback(() => {
    setClientSecret(undefined);
  }, []);

  return {
    openCheckout,
    closeCheckout,
    refreshBalance,
    isOpeningCheckout,
    clientSecret,
  };
};

export default usePayment;
