import { capitalize } from "lodash-es";

const pythonReservedWords = new Set([
  "False",
  "def",
  "if",
  "raise",
  "None",
  "del",
  "import",
  "return",
  "True",
  "elif",
  "in",
  "try",
  "and",
  "else",
  "is",
  "while",
  "as",
  "except",
  "lambda",
  "with",
  "assert",
  "finally",
  "nonlocal",
  "yield",
  "break",
  "for",
  "not",
  "class",
  "form",
  "or",
  "continue",
  "global",
  "pass",
]);

export const upCaseFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const truncateFileName = (
  fileName: string,
  maxFileNameLength: number,
  cleanupRegex: RegExp
): string => {
  const cleanedFileName = fileName.replace(cleanupRegex, "");
  const fileNameLength = cleanedFileName.length;
  if (fileNameLength < maxFileNameLength) return cleanedFileName;

  const frontChars = cleanedFileName.substring(0, maxFileNameLength / 2).trim();
  const lastChars = cleanedFileName
    .substring(fileNameLength - maxFileNameLength / 2, fileNameLength)
    .trim();

  return `${frontChars}...${lastChars}`;
};

export const snakeCaseToCapitaliseWords = (str: string) => {
  return str
    .split("_")
    .map((word) => capitalize(word))
    .join(" ");
};

// Checks if a string is a valid E.164 number
// See - https://www.twilio.com/docs/glossary/what-e164#:~:text=This%20number%20allows%20phone%20calls,a%20maximum%20of%20fifteen%20digits.

export const isStringValidE164Format = (string: string) => {
  const regex = new RegExp(/^\+[1-9]\d{1,14}$/);

  const result = regex.test(string);

  return result;
};

export const formatChatMessage = (message: string) => {
  const matchNumberSequenceAtEndOfSentence = new RegExp(/(\s+\d+\. )/gm);
  return message.replaceAll(matchNumberSequenceAtEndOfSentence, "\n$1");
};

const escapeRegExp = (text: string) =>
  text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");

export const getPercentageMatch = (search: string, text: string) => {
  const regex = new RegExp(escapeRegExp(search), "gi");
  const numMatches = (text.match(regex) || []).length;

  if (!numMatches) {
    return 0;
  }

  return (search.length * numMatches) / text.length;
};

export const validatePythonVariable = (value: string) => {
  if (!value) {
    return "Please provide a value";
  }
  if (!/^[a-z0-9_]+$/i.test(value)) {
    return "Value can only contain alphanumeric characters and underscores";
  }
  if (/^[0-9]*$/.test(value[0])) {
    return "Value can't start with a number";
  }
  if (pythonReservedWords.has(value)) {
    return `${value} is a reserved word`;
  }
  return true;
};

export const isValidPythonVariable = (value: string): boolean =>
  validatePythonVariable(value) === true;

export const pluralise = (str: string, count: number) =>
  `${str}${count === 1 ? "" : "s"}`;

export const getEmoji = (hex: string) =>
  String.fromCodePoint(parseInt(hex, 16));
