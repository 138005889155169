import { FC, MouseEvent, useEffect, useState } from "react";

import { Howl, HowlOptions } from "howler";

import { IconButton } from "components/atoms/IconButton";
import { PlayAltIcon, StopAltIcon } from "components/atoms/Icons";

import { ColorName } from "styles/config/colors";

import * as Styled from "./TableCell.styled";

const PLAY_BUTTON_CLASSNAME = "playAudioButton";

interface PlayAudioCellProps {
  className?: string;
  url: string;
  disabled?: boolean;
  onError?: (id: number, error: unknown) => void;
  options?: Omit<HowlOptions, "src">;
  iconColor?: ColorName;
  onPlayAudio?: (audio: Howl | undefined) => void;
  autoplay?: boolean;
}

const PlayAudioCell: FC<PlayAudioCellProps> = ({
  className,
  url,
  disabled,
  onError,
  options = {},
  iconColor = undefined,
  onPlayAudio,
  autoplay = true,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audio, setAudio] = useState<Howl>();
  const [currentUrl, setCurrentUrl] = useState("");

  const loadAudio = async () => {
    setIsLoading(true);
    const audioFile = new Howl({
      src: [url],
      ...options,
      autoplay,
      onloaderror: (id, error) => {
        setIsLoading(false);
        onError?.(id, error);
      },
      onload: () => setIsLoading(false),
      onplay: () => setIsPlaying(true),
      onstop: () => setIsPlaying(false),
      onend: () => setIsPlaying(false),
    });
    setAudio(audioFile);
    setCurrentUrl(url);
    return audioFile;
  };

  useEffect(() => {
    return () => {
      audio?.stop();
    };
  }, [audio]);

  const handlePlayAudio = async (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (!audio || url !== currentUrl) {
      const audio = await loadAudio();
      onPlayAudio?.(audio);
    } else {
      audio.seek(0);
      audio.play();
      onPlayAudio?.(audio);
    }
  };

  const handleStop = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    audio?.stop();
  };

  return (
    <Styled.CenteredDiv className={className}>
      <IconButton
        className={PLAY_BUTTON_CLASSNAME}
        disabled={disabled}
        hoverTarget={false}
        loading={isLoading}
        onMouseDown={isPlaying ? handleStop : handlePlayAudio}
      >
        {isPlaying ? (
          <StopAltIcon color={iconColor} />
        ) : (
          <PlayAltIcon color={iconColor} />
        )}
      </IconButton>
    </Styled.CenteredDiv>
  );
};

export default PlayAudioCell;
