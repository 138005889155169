import React, { ReactNode, useState } from "react";

import { Transition } from "react-transition-group";

import * as Styled from "./AccordionGroup.styled";

const AccordionGroup = ({
  open,
  children,
}: {
  open: boolean;
  children: ReactNode;
}) => {
  const [maxHeight, setMaxHeight] = useState(0);

  const handleHeightChange = (scrollHeight: number) => {
    if (scrollHeight > maxHeight) {
      setMaxHeight(scrollHeight);
    }
  };

  return (
    <Transition in={open} timeout={500} mountOnEnter unmountOnExit>
      {(state) => (
        <Styled.AccordionGroupWrapper
          ref={(element) => handleHeightChange(element?.scrollHeight ?? 0)}
          $maxHeight={maxHeight}
          $state={state}
        >
          {children}
        </Styled.AccordionGroupWrapper>
      )}
    </Transition>
  );
};

export default AccordionGroup;
