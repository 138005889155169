import { PropsWithChildren, useCallback, useEffect, useState } from 'react';

import { isJupiterLite } from 'constants/Global.constants';
import { useAuth0 } from '@auth0/auth0-react';

import { Loader } from '@polyai/ui/components/atoms/Loader';

import api from 'api';
import {
  setGetAuthToken,
  setIsAuthorised,
  setLogin,
  setLogout,
  setShowUnauthorised,
  setShowUserQuestionnaire,
  setUser,
} from 'ducks/auth/authSlice';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useToast } from 'hooks/useToast';
import ErrorPage, { ErrorStatus } from 'screens/ErrorPage';

export const Auth = ({ children }: PropsWithChildren) => {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const isAuthorised = useAppSelector((state) => state.auth.isAuthorised);
  const [shouldVerifyEmail, setShouldVerifyEmail] = useState(false);

  const {
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    user,
  } = useAuth0();

  const login = useCallback(
    async (path: string) => {
      if (!isAuthenticated) {
        await loginWithRedirect({
          appState: {
            returnTo: path,
          },
        });
      }
    },
    [isAuthenticated, loginWithRedirect],
  );

  const authoriseUser = useCallback(async () => {
    try {
      const { isAuthorised, user } = await api.authoriseUser();
      dispatch(setIsAuthorised(isAuthorised));
      dispatch(setShowUnauthorised(!isAuthorised));
      if (user && user.is_first_login) {
        dispatch(setShowUserQuestionnaire(true));
      }
    } catch (e) {
      dispatch(setIsAuthorised(false));
      dispatch(setShowUnauthorised(true));
    }
  }, [dispatch]);

  const handleLogout = useCallback(async () => {
    dispatch(setShowUnauthorised(false));
    try {
      await logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    } catch {
      toast.error({
        title: 'We could not log you out. Try again!',
      });
    }
  }, [dispatch, logout, toast]);

  useEffect(() => {
    // 1. store auth methods for use within application
    dispatch(setLogin(login));
    dispatch(
      setLogout(() => {
        void handleLogout();
      }),
    );
    dispatch(
      setGetAuthToken(async () => {
        try {
          return await getAccessTokenSilently();
        } catch (e) {
          void handleLogout();
        }
      }),
    );

    // 2. handle authentication
    if (isLoading) {
      return;
    }

    if (!isAuthenticated || !user) {
      login(window.location.pathname).catch((error) => {
        toast.error({
          title: 'We could not log you in. Try again',
        });
        console.error('Error occurred while logging in.', error);
      });
      return;
    }

    // 3. handle authorisation

    // If the user has not verified their email address we should get them to
    // verify their email before we authorise them (otherwise we could create users
    // and accounts in Aegis for random emails and get overloaded)
    if (user && !user?.email_verified && isJupiterLite) {
      setShouldVerifyEmail(true);
      return;
    }

    if (isAuthenticated && !isAuthorised) {
      void authoriseUser();
    }
  }, [isAuthenticated, isAuthorised, isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user) {
      dispatch(setUser(user));
    }
  }, [dispatch, user]);

  if (shouldVerifyEmail) {
    return <ErrorPage type={ErrorStatus.EMAIL_UNVERIFIED} />;
  }

  if (!isAuthenticated || !user) {
    return <Loader inProjectPage={false} fullScreen spinner />;
  }

  return children;
};
