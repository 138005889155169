import { useEffect, useMemo, useRef } from "react";

import { AnimatePresence, HTMLMotionProps, motion } from "framer-motion";

import { ComponentWithChildren } from "@polyai/common/types/helpers";
import { safeResizeObserver } from "@polyai/common/utils/safeResizeObserver";

type Props = {
  visible: boolean;
  direction: "vertical" | "horizontal";
  setHeight?: (height: number) => void;
  setWidth?: (width: number) => void;
};

const Animation: ComponentWithChildren<Props & HTMLMotionProps<"div">> = ({
  visible,
  children,
  direction,
  setHeight,
  setWidth,
  ...rest
}) => {
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (elementRef.current) {
      const set = direction === "vertical" ? setHeight : setWidth;
      const updateHeight = () => {
        if (elementRef.current) {
          set?.(elementRef.current.offsetHeight);
        }
      };

      updateHeight();

      const resizeObserver = safeResizeObserver(() => {
        updateHeight();
      });

      resizeObserver.observe(elementRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [direction, setHeight, setWidth, visible]);

  const props = useMemo(
    () =>
      direction === "vertical"
        ? {
            animate: { height: "auto", opacity: 1, minHeight: 0 },
            exit: { height: 0, opacity: 0 },
            initial: { height: 0, opacity: 0 },
            transition: { ease: "easeInOut", duration: 0.2 },
          }
        : {
            animate: { width: "auto", opacity: 1, minWidth: 0 },
            exit: { width: 0, opacity: 0 },
            initial: { width: 0, opacity: 0 },
            transition: { ease: "easeInOut", duration: 0.2 },
          },
    [direction]
  );

  return (
    // @ts-ignore
    <AnimatePresence initial={!visible} presenceAffectsLayout={false}>
      {visible && (
        <motion.div {...rest} {...props} ref={elementRef}>
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Animation;
