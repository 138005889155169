import styled from "styled-components";

import themeColors from "styles/config/colors";
import themeSpacing from "styles/config/spacing";

export const Badge = styled.div<{ $color: string; $background: string }>`
  display: flex;
  align-items: center;
  justify-content: center;

  max-width: fit-content;
  padding: ${themeSpacing.xs1} ${themeSpacing.xs2};

  font-size: ${({ theme }) => theme.text.size.body.body4};
  font-weight: ${({ theme }) => theme.text.weight.semibold};
  font-style: normal;
  line-height: 110%;
  color: ${({ $color }) => $color};

  background: ${({ $background }) => $background};
  border: 1px solid ${themeColors.borderSubtle};
  border-radius: ${themeSpacing.radiusSmall};
`;
