import styled from 'styled-components';

import Clickable from '@polyai/ui/components/atoms/Clickable';
import { BodyStrong } from '@polyai/ui/components/atoms/Text';
import { PopupMenuItem } from '@polyai/ui/components/molecules/PopupMenu';
import themeColors from '@polyai/ui/styles/config/colors';
import sizes from '@polyai/ui/styles/config/sizes';
import themeSpacing from '@polyai/ui/styles/config/spacing';

export const LandingNavWrapper = styled.div`
  position: sticky;
  z-index: 11;
  top: 0;

  display: flex;
  gap: ${themeSpacing.sm4};
  align-items: center;
  justify-content: space-between;

  height: ${sizes.headerHeight}px;
  padding: ${themeSpacing.sm1} ${themeSpacing.sm3};

  background-color: ${themeColors.backgroundInverse};
  box-shadow: 0 1px 1px 0 ${themeColors.shadow};
`;

export const AccountActionsMenu = styled.div`
  color: ${themeColors.background};
`;

export const AccountActionsMenuButton = styled(Clickable)`
  display: flex;
  gap: ${themeSpacing.xs2};
  align-items: center;
  color: ${themeColors.background};
`;

export const PopupMenuButton = styled(PopupMenuItem)`
  display: flex;
  gap: ${themeSpacing.xs2};
  align-items: center;
`;

export const HelpLink = styled.a`
  width: 100%;
`;

export const DropdownWrapper = styled.div`
  display: flex;
  flex: 0 0;
  gap: ${themeSpacing.sm1};
  align-items: center;
`;

export const UserName = styled(BodyStrong)`
  text-wrap: nowrap;
  white-space: nowrap;
`;
