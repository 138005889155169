import { FC, ReactElement, ReactNode, useRef } from 'react';

import Skeleton from '@polyai/ui/components/atoms/Skeleton';
import Tooltip from '@polyai/ui/components/atoms/Tooltip';

import * as Styled from './JupiterCard.styled';

export interface ColumnTooltipProps {
  disableUnderline?: boolean;
}

interface Props {
  icon: ReactNode;
  title: ReactElement;
  titleTooltip?: ReactNode;
  titleTooltipProps?: ColumnTooltipProps;
  value: ReactElement;
  valueTooltip?: ReactNode;
  valueTooltipProps?: ColumnTooltipProps;
  isLoading?: boolean;
  className?: string;
}

const BaseColumn: FC<Props> = ({
  icon,
  title,
  titleTooltip,
  titleTooltipProps,
  value,
  valueTooltip,
  valueTooltipProps,
  isLoading,
  className,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const Title = titleTooltip ? (
    <Tooltip content={titleTooltip} placement="bottom">
      <Styled.TooltipWrapper $underline={!titleTooltipProps?.disableUnderline}>
        {title}
      </Styled.TooltipWrapper>
    </Tooltip>
  ) : (
    title
  );

  const Value = valueTooltip ? (
    <Tooltip content={valueTooltip} placement="bottom" {...valueTooltipProps}>
      <Styled.TooltipWrapper $underline={!valueTooltipProps?.disableUnderline}>
        {value}
      </Styled.TooltipWrapper>
    </Tooltip>
  ) : (
    value
  );

  return (
    <Styled.CardContainer ref={containerRef} className={className}>
      <Styled.IconBadge>{icon}</Styled.IconBadge>
      <Styled.CardTextContainer>
        {Title}
        {isLoading ? (
          <Styled.SkeletonContainer>
            <Skeleton />
          </Styled.SkeletonContainer>
        ) : (
          Value
        )}
      </Styled.CardTextContainer>
    </Styled.CardContainer>
  );
};

export default BaseColumn;
