import React, { ChangeEvent, ReactElement, ReactNode, useState } from "react";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import BackButton from "components/atoms/BackButton";
import Clickable from "components/atoms/Clickable";
import { ChevronRightIcon } from "components/atoms/Icons";
import { CaptionStrong, Headline } from "components/atoms/Text";

import * as Styled from "./ScreenHeader.styled";

dayjs.extend(relativeTime);

type ScreenHeaderProps = {
  screenTitle: string | ReactElement;
  captionTime?: string;
  captionTitle?: string;
  description?: string | ReactElement;
  backTo?: string;
  actions?: { title: string; onClick: () => void }[];
  menuPopup?: ReactElement;
  minimized?: boolean;
  onClickHeader?: () => void;
  onSaveDescription?: (description: string) => void;
  afterExpandedDescriptionContent?: ReactNode;
  subHeader?: ReactNode;
  rightActions?: ReactNode;
  backCircle?: boolean;
  className?: string;
};

const ScreenHeader: React.FC<ScreenHeaderProps> = ({
  screenTitle,
  captionTime,
  captionTitle,
  description,
  backTo,
  actions,
  menuPopup,
  rightActions,
  minimized = false,
  subHeader,
  onClickHeader,
  onSaveDescription = undefined,
  afterExpandedDescriptionContent,
  backCircle = false,
  ...rest
}) => {
  const [textValue, setTextValue] = useState(description);

  const handleDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setTextValue(e.target.value);
  };

  const handleSaveDescription = () => {
    if (onSaveDescription) {
      onSaveDescription(textValue as string);
    }
  };

  const showTextLimitationError =
    typeof textValue === "string" && !!onSaveDescription
      ? textValue.length > 250
      : false;

  const textArea = document.querySelector("textarea");
  const rows = textArea ? textArea.value.split("\n").length : 0;

  return (
    <Styled.ScreenHeaderWrapper
      $backButton={!!backTo}
      $minimized={minimized}
      data-test-id="screen-header"
      id="screenHeader"
      {...rest}
    >
      {!!backTo && (
        <Styled.BackButton>
          <BackButton
            circle={backCircle}
            data-test-id="back-to-flow-table-button"
            to={backTo}
          />
        </Styled.BackButton>
      )}
      <Styled.Content>
        <Styled.HeadlineWrapper
          $actions={!!actions?.length}
          $minimized={minimized}
        >
          {minimized ? (
            <Clickable
              data-test-id="screen-header-minimized"
              onClick={onClickHeader}
            >
              <Headline>{screenTitle}</Headline>
              <ChevronRightIcon size={minimized && "xs"} />
            </Clickable>
          ) : (
            <Styled.HeaderWrapper>
              <Styled.TitleWrapper>
                <Styled.HeaderHeadline data-test-id="screen-header-title">
                  {screenTitle}
                </Styled.HeaderHeadline>
                <Styled.PopupButton>{menuPopup}</Styled.PopupButton>
              </Styled.TitleWrapper>
              {rightActions}
            </Styled.HeaderWrapper>
          )}
        </Styled.HeadlineWrapper>
        {!minimized && (
          <>
            {(captionTitle || captionTime) && (
              <Styled.Captions>
                {captionTitle && <CaptionStrong>{captionTitle}</CaptionStrong>}
                {captionTime && (
                  <Styled.Versioning>{captionTime}</Styled.Versioning>
                )}
              </Styled.Captions>
            )}
            {subHeader}
            {onSaveDescription ? (
              <>
                <Styled.DescriptionTextArea
                  $error={showTextLimitationError}
                  rows={rows}
                  spellCheck={false}
                  onBlur={handleSaveDescription}
                  onChange={handleDescriptionChange}
                >
                  {textValue}
                </Styled.DescriptionTextArea>
                {showTextLimitationError && (
                  <Styled.DescriptionInputError>
                    {(textValue as string).length} of 250 characters
                  </Styled.DescriptionInputError>
                )}
                {afterExpandedDescriptionContent}
              </>
            ) : (
              <>
                {description && (
                  <Styled.Description>{description}</Styled.Description>
                )}
              </>
            )}
            <div />
          </>
        )}
      </Styled.Content>
    </Styled.ScreenHeaderWrapper>
  );
};

export default ScreenHeader;
