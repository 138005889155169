import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FunctionCall } from 'api/types';

interface FunctionTestPanelState {
  targetFn?: FunctionCall;
  isOpen: boolean;
}

const initialState: FunctionTestPanelState = {
  isOpen: false,
};

const functionTestPanelSlice = createSlice({
  name: 'functionTestPanel',
  initialState,
  reducers: {
    toggleFunctionTestPanel: (state) => {
      state.isOpen = !state.isOpen;
    },
    setTestTargetFn: (
      state,
      { payload: target }: PayloadAction<FunctionCall | undefined>,
    ) => {
      state.targetFn = target;
    },
  },
});

export const { toggleFunctionTestPanel, setTestTargetFn } =
  functionTestPanelSlice.actions;

export default functionTestPanelSlice.reducer;
