import { doPut } from 'api/request';
import { LOGGER_TYPE } from 'api/types';
import { Questionnaire } from './types';

const URL = 'questionnaire';

export const setQuestionnaire = ({
  questionnaire,
}: {
  questionnaire: Questionnaire;
}) => {
  return doPut<Questionnaire>(URL, LOGGER_TYPE.JUPITER, questionnaire);
};
