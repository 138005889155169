import ENDPOINTS from 'api/endpoints';
import { doGet, doPost, doPut } from 'api/request';
import { LOGGER_TYPE } from 'api/types';
import { generateNextPath } from 'lib/routeHelper';
import { store } from 'reduxStore';
import {
  AudioUploadResponseServerSchema,
  DisclaimerRequest,
  DisclaimerServerSchema,
} from './types';

export const getDisclaimer = async () => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  const response = await doGet(
    generateNextPath(ENDPOINTS.DISCLAIMER, { accountId, projectId }),
    LOGGER_TYPE.JUPITER,
    { version: 'v2' },
  );

  return DisclaimerServerSchema.parse(response);
};

export const updateDisclaimer = async ({
  data,
}: {
  data: DisclaimerRequest;
}) => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  const response = await doPut(
    `accounts/${accountId}/projects/${projectId}/disclaimer`,
    LOGGER_TYPE.JUPITER,
    data,
    { version: 'v2' },
  );

  return DisclaimerServerSchema.parse(response);
};

export const uploadRingtone = async ({ file }: { file: File }) => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  const formData = new FormData();
  formData.append('in', file);

  const response = await doPost(
    `accounts/${accountId}/projects/${projectId}/audio-upload`,
    LOGGER_TYPE.JUPITER,
    formData,
    {
      postingFile: true,
      version: 'v2',
      parseResponseToJSON: true,
    },
  );

  return AudioUploadResponseServerSchema.parse(response);
};
