import { FeaturesResponse } from 'api/internalResources/featureFlag';
import { PROJECT_RESOURCE } from 'hooks/useProjectResources';

export const isFeatureEnabled = (
  features: FeaturesResponse,
  featureName: string,
) => !!features.find((f) => f.name === featureName && f.enabled);

export const hasFeatureAccess = (
  resource: PROJECT_RESOURCE,
  features: string[],
  restrictedFeatures: string[],
) => {
  if (features.includes('*')) {
    return !restrictedFeatures?.includes(resource);
  }
  return features.includes(resource);
};
