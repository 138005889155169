import { doDelete, doGet, doPost, doPut } from 'api/request';
import {
  CreateEditSecretRequest,
  DeleteSecretRequest,
  SecretsManagerResponse,
} from 'api/resources/secretsManager/types';
import { LOGGER_TYPE } from 'api/types';
import { store } from 'reduxStore';

export const getSecrets = (accountId: string, displayName?: string) =>
  doGet(`accounts/${accountId}/api_secrets`, LOGGER_TYPE.JUPITER, {
    params: {
      name: displayName,
    },
  });

export const createSecret = (body: CreateEditSecretRequest) => {
  const accountId = store.getState().account.id;

  return doPost<SecretsManagerResponse>(
    `accounts/${accountId}/api_secrets`,
    LOGGER_TYPE.JUPITER,
    body,
  );
};

export const editSecret = (body: CreateEditSecretRequest) => {
  const accountId = store.getState().account.id;

  return doPut<SecretsManagerResponse>(
    `accounts/${accountId}/api_secrets`,
    LOGGER_TYPE.JUPITER,
    body,
  );
};

export const deleteSecret = (body: DeleteSecretRequest) => {
  const accountId = store.getState().account.id;

  return doDelete<SecretsManagerResponse>(
    `accounts/${accountId}/api_secrets`,
    LOGGER_TYPE.JUPITER,
    body,
  );
};
