import { doGet } from 'api/request';
import {
  BalanceLogResponse,
  BalanceOnlyResponse,
} from 'api/resources/balance/types';
import { LOGGER_TYPE } from 'api/types';
import { store } from 'reduxStore';

export const getBalance = () => {
  const accountId = store.getState().account.id;

  return doGet<BalanceOnlyResponse>(
    `accounts/${accountId}/balance`,
    LOGGER_TYPE.JUPITER,
    { params: { balance_only: 'true' } },
  );
};

// TODO: NEED TO UPDATE ONCE BE IS READY WITH THE LIMIT / QUERY STRING etc
export const getBalanceLog = () => {
  const accountId = store.getState().account.id;

  return doGet<BalanceLogResponse>(
    `accounts/${accountId}/balance`,
    LOGGER_TYPE.JUPITER,
  );
};
