import styled, { css } from 'styled-components';

import { IconSize } from '@polyai/ui/components/atoms/Icons/BaseIcon';
import { Caption, Title } from '@polyai/ui/components/atoms/Text';
import Card from '@polyai/ui/components/molecules/Card';
import breakpoints, { under } from '@polyai/ui/styles/config/breakpoints';
import themeColors, { baseColors } from '@polyai/ui/styles/config/colors';
import themeSpacing from '@polyai/ui/styles/config/spacing';
import text from '@polyai/ui/styles/config/text';

export const UserBillingWrapper = styled.div<{
  $justify: 'end' | 'space-between';
}>`
  display: flex;
  flex: 1 1;
  gap: ${themeSpacing.sm1};
  justify-content: ${({ $justify }) => $justify};

  color: ${themeColors.textPrimaryInverse};
`;

export const Credit = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${IconSize.lg}px;
  height: ${IconSize.lg}px;

  background: ${baseColors.newGrey['80']};
  border-radius: ${themeSpacing.radiusFull};
`;

export const BalanceContainer = styled.div`
  display: flex;
  gap: ${themeSpacing.xs1};
  align-items: center;
`;

export const Balance = styled.div`
  font-size: ${text.size.body.body4};
  font-weight: ${text.weight.semibold};
  text-wrap: nowrap;
  white-space: nowrap;
`;

export const BundleCard = styled(Card)`
  border: ${themeColors.borderSubtle} solid 1px;
`;

export const CardContainer = styled.div`
  display: flex;
  gap: ${themeSpacing.xs2};
  margin: ${themeSpacing.sm1} 0;

  @media (${under(breakpoints.sm)}) {
    flex-direction: column;

    ${BundleCard} {
      width: 100%;
    }
  }
`;

export const BundleAmount = styled(Title)`
  margin: ${themeSpacing.sm3} 0 ${themeSpacing.xxs2};
  font-size: ${text.size.body.body1};
`;

export const BundleCaption = styled(Caption)<{ $isCustom?: boolean }>`
  color: ${themeColors.textHelper};

  ${({ $isCustom }) =>
    $isCustom &&
    css`
      margin-bottom: ${themeSpacing.sm3};
    `}
`;

export const LineItems = styled.div`
  max-width: 200px;
  margin: ${themeSpacing.sm1} 0 ${themeSpacing.sm1} auto;
  text-align: right;
`;

export const LineItem = styled.div`
  padding-bottom: ${themeSpacing.xs2};
`;

const CurrencyContainer = styled.div`
  display: flex;
  gap: ${themeSpacing.xs2};
  align-items: end;
  justify-content: end;
`;

export const ItemAmount = styled(CurrencyContainer)`
  font-size: ${text.size.body.body2};
  font-weight: ${text.weight.semibold};
  color: ${themeColors.textPrimary};
`;

export const Total = styled(CurrencyContainer)<{ $hasBonus?: boolean }>`
  ${({ $hasBonus }) =>
    $hasBonus &&
    css`
      border-top: ${themeColors.borderStrong} solid 1px;
    `}
  padding: ${themeSpacing.xs2} 0;
  font-weight: ${text.weight.semibold};
`;

export const Amount = styled(Title)<{ $isTotal?: boolean }>`
  line-height: 100%;
  ${({ $isTotal }) =>
    $isTotal &&
    css`
      font-size: ${text.size.body.body1};
    `}
`;

export const ModalCaption = styled.div`
  margin: ${themeSpacing.sm1} 0 ${themeSpacing.sm4};

  /* tooltip wrapper */
  > div {
    display: inline-flex;
  }
`;

export const TooltipContents = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeSpacing.xs1};
`;
