import styled from "styled-components";

import themeColors from "styles/config/colors";

export const CharLimitError = styled.div`
  font-size: ${({ theme }) => theme.text.size.body.body4};
  font-weight: ${({ theme }) => theme.text.weight.semibold};
  color: ${themeColors.backgroundDanger};
  text-align: right;
`;
