import { UnsavedChangesModalUICopy } from './types';

export const UNSAVED_CHANGES_MODAL: UnsavedChangesModalUICopy = {
  title: 'You have unsaved changes!',
  description:
    'Are you sure you want to leave? Stay to save changes otherwise, they will be lost.',
  button: {
    cancel: 'Stay here',
    confirm: 'Leave',
  },
};
