import styled from "styled-components";

import themeSpacing from "styles/config/spacing";
import Clickable from "../Clickable";

export const BackButtonWrapper = styled(Clickable)`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: ${themeSpacing.sm3};
  padding: ${themeSpacing.xs3};
`;
