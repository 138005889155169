import ENDPOINTS from 'api/endpoints';
import { doDelete, doGet, doPost, doPut } from 'api/request';
import {
  SmsTemplate,
  SmsTemplateRequest,
} from 'api/resources/smsTemplates/types';
import { LOGGER_TYPE } from 'api/types';
import { generateNextPath } from 'lib/routeHelper';
import { store } from 'reduxStore';

const VERSION = 'v2';

export const getSmsTemplates = () => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  return doGet<SmsTemplate[]>(
    generateNextPath(ENDPOINTS.SMS_TEMPLATES, { accountId, projectId }),
    LOGGER_TYPE.JUPITER,
    { version: VERSION },
  );
};

export const createSmsTemplate = (request: SmsTemplateRequest) => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  return doPost<SmsTemplate>(
    generateNextPath(ENDPOINTS.SMS_TEMPLATES, { accountId, projectId }),
    LOGGER_TYPE.JUPITER,
    request,
    { version: VERSION },
  );
};

export const editSmsTemplate = (
  request: SmsTemplateRequest,
  smsTemplateId: string,
) => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  return doPut<SmsTemplate>(
    generateNextPath(ENDPOINTS.SMS_TEMPLATE, {
      accountId,
      projectId,
      smsTemplateId,
    }),
    LOGGER_TYPE.JUPITER,
    request,
    { version: VERSION },
  );
};

export const deleteSmsTemplate = (smsTemplateId: string) => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  return doDelete(
    generateNextPath(ENDPOINTS.SMS_TEMPLATE, {
      accountId,
      projectId,
      smsTemplateId,
    }),
    LOGGER_TYPE.JUPITER,
    undefined,
    { version: VERSION },
  );
};
