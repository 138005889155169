import React, { memo, ReactNode, useCallback } from "react";

import { Node as MenuNode } from "@polyai/common/types/helpers";

import Checkbox, { CheckboxState } from "components/atoms/Checkbox";
import Popover from "components/atoms/Popover";
import { PopoverList } from "components/atoms/Popover/PopoverList";
import { PopoverListItem } from "components/atoms/Popover/PopoverListItem";
import {
  PopoverTrigger,
  PopoverTriggerProps,
} from "components/atoms/Popover/PopoverTrigger";
import { PopoverProps } from "components/atoms/Popover/types";
import { Caption } from "components/atoms/Text";

import * as Styled from "./HierarchicalMenuPopover.styled";

export interface HierarchicalMenuPopoverProps<T extends string> {
  open: boolean;
  onOpenChange: PopoverProps["onOpenChange"];
  onClickTrigger: () => void;
  trigger: PopoverTriggerProps["children"];
  names: { [P in T]: string };
  values: MenuNode<T>[];
  checkedValues: T[];
  handleCheck: (values: T[], checked: boolean) => void;
  groupParentValues?: T[]; // for grouping values without being a toggle-able value itself
}

export const getSubtreeValues = <T extends string>(node: MenuNode<T>): T[] => {
  if (typeof node === "string") {
    return [node];
  }

  const [parent, children] = Object.entries(node)[0] as [T, MenuNode<T>[]];
  return [parent, ...children.flatMap((child) => getSubtreeValues(child))];
};

const HierarchicalMenuPopover = <T extends string>({
  open,
  onOpenChange,
  onClickTrigger,
  trigger,
  names,
  values,
  checkedValues,
  handleCheck,
  groupParentValues,
}: HierarchicalMenuPopoverProps<T>) => {
  const getIsValueChecked = useCallback(
    (value: T) => checkedValues.includes(value),
    [checkedValues]
  );

  const generateHierarchicalMenu = useCallback(
    (node: MenuNode<T>, depth: number, path: T[]): ReactNode => {
      // resolve leaf nodes
      if (typeof node === "string") {
        const value = node;
        const isValueChecked = getIsValueChecked(value);

        // if already checked, only uncheck this leaf node
        // else if unchecked, check all required parent nodes
        const valuesToToggle = isValueChecked ? [value] : [...path, value];

        return (
          <PopoverListItem
            key={node}
            onClick={() => handleCheck(valuesToToggle, !isValueChecked)}
          >
            <Styled.NodeMenuItem key={node} $depth={depth}>
              <Checkbox
                state={
                  isValueChecked
                    ? CheckboxState.CHECKED
                    : CheckboxState.UNCHECKED
                }
                isDarkBackground
              />
              <Caption>{names[node] || value}</Caption>
            </Styled.NodeMenuItem>
          </PopoverListItem>
        );
      }

      // resolve parent nodes
      const subtreeValues = getSubtreeValues(node);
      const parentValue = subtreeValues[0];

      const isParentGroup = groupParentValues?.includes(parentValue);

      const isParentValueChecked = isParentGroup
        ? subtreeValues.slice(1).every((value) => getIsValueChecked(value))
        : getIsValueChecked(parentValue);

      const isSomeChildrenValueChecked = subtreeValues
        .slice(1)
        .some((value) => getIsValueChecked(value));

      // if already checked or a parent group, toggle this node and all children nodes
      // else if unchecked, check all required parent nodes
      const valuesToToggle =
        isParentGroup || isParentValueChecked
          ? subtreeValues
          : [...path, parentValue];

      const childrenNodes = Object.values(node)[0] as MenuNode<T>[];

      return (
        <Styled.TreeContainer key={parentValue}>
          <PopoverListItem
            onClick={() => handleCheck(valuesToToggle, !isParentValueChecked)}
          >
            <Styled.ParentNodeMenuItem $depth={depth}>
              <Checkbox
                state={
                  isParentValueChecked
                    ? CheckboxState.CHECKED
                    : isSomeChildrenValueChecked
                    ? CheckboxState.INDETERMINATE
                    : CheckboxState.UNCHECKED
                }
                isDarkBackground
              />
              <Caption>{names[parentValue] || parentValue}</Caption>
            </Styled.ParentNodeMenuItem>
          </PopoverListItem>
          {childrenNodes.map((childNode) =>
            generateHierarchicalMenu(childNode, depth + 1, [
              ...path,
              parentValue,
            ])
          )}
        </Styled.TreeContainer>
      );
    },
    [groupParentValues, getIsValueChecked, handleCheck, names]
  );

  return (
    <Popover
      autoUpdate={false}
      open={open}
      placement="bottom-start"
      useDismissProps={{
        ancestorScroll: false,
      }}
      list
      onOpenChange={onOpenChange}
    >
      <PopoverTrigger onClick={onClickTrigger}>{trigger}</PopoverTrigger>
      <Styled.PopoverContent>
        <PopoverList>
          <Styled.InnerWrapper>
            {values.map((value) => generateHierarchicalMenu(value, 0, []))}
          </Styled.InnerWrapper>
        </PopoverList>
      </Styled.PopoverContent>
    </Popover>
  );
};

export default memo(HierarchicalMenuPopover);
