import { motion } from "framer-motion";
import styled, { css } from "styled-components";

import themeColors from "styles/config/colors";
import transition from "styles/config/transition";
import rem from "styles/functions/rem";

const HEADER_HEIGHT = 60;

export const Wrapper = styled.div<{ $border?: boolean }>`
  width: 100%;
  ${({ $border }) =>
    $border &&
    css`
      border-bottom: 1px solid ${themeColors.borderSubtle};
    `}
`;

export const Header = styled.div<{
  $noChildren?: boolean;
  $disabled?: boolean;
  headerHeight?: string;
}>`
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: ${(props) => props.headerHeight || rem(HEADER_HEIGHT)};

  &:hover {
    cursor: pointer;
  }

  ${({ $noChildren, $disabled }) =>
    $noChildren &&
    $disabled &&
    css`
      cursor: default;
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      color: ${themeColors.textDisabled};
    `}
`;

export const ToggleFolderIcon = styled.div<{ $isOpen?: boolean }>`
  position: relative;
  transform: rotate(90deg);

  width: 20px;
  height: 20px;

  transition: transform ${transition.easeQuintInOut} 0.2s;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      transform: rotate(180deg);
    `}
`;

export const CollapseContent = styled(motion.div)`
  width: 100%;
`;
