import ENDPOINTS from 'api/endpoints';
import { doPut } from 'api/request';
import { LOGGER_TYPE, PatchAsrCorrection } from 'api/types';
import { generateNextPath } from 'lib/routeHelper';
import { store } from 'reduxStore';
import { AsrCorrectionsResponse } from './types';

export const updateAssistantAsrCorrections = (
  asrCorrections: PatchAsrCorrection[],
) => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  return doPut<AsrCorrectionsResponse>(
    generateNextPath(ENDPOINTS.TRANSCRIPT_CORRECTIONS, {
      accountId,
      projectId,
    }),
    LOGGER_TYPE.JUPITER,
    { asr_corrections: asrCorrections },
    { version: 'v2' },
  );
};
