import { ComponentType, useEffect, useMemo } from 'react';

import useFeatureSwitch, { FeatureFlag } from 'hooks/useFeatureSwitch';
import {
  PROJECT_RESOURCE,
  useProjectResource,
} from 'hooks/useProjectResources';
import useSampleProject from 'hooks/useSampleProjects/useSampleProject';
import ErrorPage, { ErrorStatus } from 'screens/ErrorPage';
import { syncStore } from './useViewMode';

export default function withPermissions<T extends {}>(
  Component: ComponentType<T>,
  resource?:
    | PROJECT_RESOURCE
    | PROJECT_RESOURCE[]
    | ((props: T) => PROJECT_RESOURCE | PROJECT_RESOURCE[]),
  feature?: FeatureFlag | FeatureFlag[] | ((props: T) => FeatureFlag),
) {
  const WrappedComponent: ComponentType<T> = (props) => {
    const { isProjectResourceEnabled } = useProjectResource();
    const { isFeatureOn } = useFeatureSwitch();
    const { inSampleProject } = useSampleProject();

    useEffect(() => {
      syncStore({
        // @ts-ignore
        previewValue: props?.preview,
        // @ts-ignore
        readonlyValue: props?.readonly,
      });
      // @ts-ignore
    }, [props?.readonly, props?.preview]);

    const isResourceAllowed = useMemo(() => {
      if (!resource) {
        return true;
      }

      if (inSampleProject) {
        return true;
      }

      const res = typeof resource === 'function' ? resource(props) : resource;

      if (Array.isArray(res)) {
        return res.some((r) => isProjectResourceEnabled(r));
      }

      return isProjectResourceEnabled(res);
    }, [isProjectResourceEnabled, props, inSampleProject]);

    const isFeatureEnabled = useMemo(() => {
      if (!feature) {
        return true;
      }

      const feat = typeof feature === 'function' ? feature(props) : feature;

      if (Array.isArray(feat)) {
        return feat.some((r) => isFeatureOn(r));
      }

      return isFeatureOn(feat);
    }, [isFeatureOn, props]);

    if (!isFeatureEnabled || !isResourceAllowed) {
      return <ErrorPage type={ErrorStatus.FORBIDDEN} />;
    }

    return <Component {...props} />;
  };

  return WrappedComponent;
}
