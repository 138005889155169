'use client';

import { useEffect } from 'react';

import { useRouter } from '@polyai/common/hooks/useRouter';
import { Loader } from '@polyai/ui/components/atoms/Loader';

const CallbackPage = () => {
  const { replaceUrl } = useRouter();

  useEffect(() => {
    const timer = setTimeout(() => {
      replaceUrl('/');
    }, 2000);

    return () => clearTimeout(timer);
  }, [replaceUrl]);

  return <Loader inProjectPage={false} fullScreen spinner />;
};

export default CallbackPage;
