import React, { useState } from 'react';

import useLockScroll from '@polyai/common/hooks/useLockScroll';
import Button from '@polyai/ui/components/atoms/Button';
import { Logo } from '@polyai/ui/components/atoms/Logo';
import Select, { OptionType } from '@polyai/ui/components/atoms/Select';

import {
  QuestionAnswer,
  Questionnaire,
} from 'api/resources/questionnaire/types';
import { useAppSelector } from 'hooks/reduxHooks';
import useQuestionnaire from 'hooks/useQuestionnaire';

import * as Styled from './UserQuestionnaire.styled';

const questions: (QuestionAnswer &
  ({ type: 'select'; options: string[] } | { type: 'text' }))[] = [
  {
    id: 'role_title',
    question: 'Which title best reflects your role?',
    type: 'select',
    options: [
      'Product',
      'Marketing',
      'Revenue',
      'Engineering and IT',
      'Sales',
      'Business Owner',
    ],
  },
  {
    id: 'company_name',
    question: "What's your company name?",
    type: 'text',
  },
  {
    id: 'company_industry',
    question: 'What industry do you work in?',
    type: 'select',
    options: [
      'Software and IT Services',
      'Education',
      'Construction',
      'Design',
      'Corporate Services',
      'Retail',
      'Energy and Mining',
      'Manufacturing',
      'Finance',
      'Recreation and Travel',
      'Health Care',
      'Hardware',
      'Real Estate',
      'Legal',
      'Consumer Goods',
      'Media and Communications',
      'Transportation and Logistics',
      'Other',
    ],
  },
  {
    id: 'company_size',
    question: "What's your company size?",
    type: 'select',
    options: [
      'Fewer than 10 employees',
      '10 to 49 employees',
      '50 to 249 employees',
      'Greater than 250 employees',
    ],
  },
];

export const UserQuestionnaireContent = ({
  shouldShow,
  isUpdating,
  updateQuestionnaire,
}: {
  shouldShow: boolean;
  updateQuestionnaire: ({
    questionnaire,
  }: {
    questionnaire: Questionnaire;
  }) => void;
  isUpdating: boolean;
}) => {
  const [updatedQuestionnaire, setUpdatedQuestionnaire] = useState(questions);

  useLockScroll(shouldShow);

  if (!shouldShow) {
    return null;
  }

  return (
    <Styled.FullscreenPopup open>
      <Styled.QuestionnaireWrapper>
        <Logo dotsColor="iconPrimary" textColor="iconPrimary" />
        <Styled.WelcomeWrapper>
          <Styled.Title>Welcome</Styled.Title>
          <Styled.Text>
            We&apos;re thrilled you&apos;re here, we&apos;d love to know a bit
            more about you before diving in!
          </Styled.Text>
        </Styled.WelcomeWrapper>
        <Styled.Questions>
          {updatedQuestionnaire.map((questionConfig) => {
            const { id, question, answer } = questionConfig;
            switch (questionConfig.type) {
              case 'text':
                return (
                  <Styled.TextInput
                    data-test-id={id}
                    id={id}
                    label={question}
                    value={answer}
                    onChange={(e) => {
                      setUpdatedQuestionnaire((qs) =>
                        qs.map((q) =>
                          q.id === id ? { ...q, answer: e.target.value } : q,
                        ),
                      );
                    }}
                  />
                );
              case 'select':
                const options = questionConfig.options.map((o) => ({
                  value: o,
                  label: o,
                }));
                const selected = options.find((o) => o.value === answer);
                return (
                  <Select<OptionType, false>
                    data-test-id={id}
                    id={id}
                    label={question}
                    options={options}
                    value={selected}
                    onChange={(value) => {
                      setUpdatedQuestionnaire((qs) =>
                        qs.map((q) =>
                          q.id === id ? { ...q, answer: value?.value } : q,
                        ),
                      );
                    }}
                  />
                );
            }
          })}
        </Styled.Questions>
        <Styled.ButtonContainer>
          <Button
            data-test-id="submit-questionnaire-btn"
            label="Continue"
            loading={isUpdating}
            onClick={() =>
              updateQuestionnaire({
                questionnaire: updatedQuestionnaire.map(
                  ({ id, question, answer }) => ({ id, question, answer }),
                ),
              })
            }
          />
        </Styled.ButtonContainer>
      </Styled.QuestionnaireWrapper>
    </Styled.FullscreenPopup>
  );
};

const UserQuestionnaire = () => {
  const { updateQuestionnaire, isUpdatingQuestionnaire } = useQuestionnaire();
  const shouldShow = useAppSelector(
    (state) => state.auth.showUserQuestionnaire,
  );

  return (
    <UserQuestionnaireContent
      isUpdating={isUpdatingQuestionnaire}
      shouldShow={shouldShow}
      updateQuestionnaire={updateQuestionnaire}
    />
  );
};

export default UserQuestionnaire;
