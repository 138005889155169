import { ComponentType, FC, PropsWithChildren } from "react";

import { FloatingList } from "@floating-ui/react";

import { usePopoverContext } from "./Provider";

interface PopoverListProps extends PropsWithChildren {
  Wrapper?: ComponentType<PropsWithChildren>;
}

export const PopoverList: FC<PopoverListProps> = ({ children }) => {
  const popoverContext = usePopoverContext();
  const {
    listContext: { elementsRef },
  } = popoverContext;

  return <FloatingList elementsRef={elementsRef}>{children}</FloatingList>;
};
