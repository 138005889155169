import { useCallback } from 'react';

import { isJupiterLite } from 'constants/Global.constants';

import { hasFeatureAccess } from 'lib/featuresHelper';
import { PROJECT_RESOURCE } from './constants';
import { useProjectResources } from './useProjectResources';
import { useUserAccess } from './useUserAccess';

// This is super custom just for flows, we will build this behaviour into feature
// flagging in the future.
const isFlows = (resource: PROJECT_RESOURCE) =>
  resource === PROJECT_RESOURCE.FLOWS;
const checkFlows = (resource: PROJECT_RESOURCE, isInternalUser: boolean) =>
  !isFlows(resource) || isInternalUser || isJupiterLite;

export const useProjectResource = () => {
  const { isInternalUser } = useUserAccess();
  const { features, restrictedFeatures } = useProjectResources();

  const isProjectResourceEnabled = useCallback(
    (resource: PROJECT_RESOURCE) =>
      hasFeatureAccess(resource, features, restrictedFeatures) &&
      checkFlows(resource, isInternalUser),
    [features, isInternalUser, restrictedFeatures],
  );

  return {
    isProjectResourceEnabled,
  };
};
