import React from "react";

import { ClickableProps } from "../Clickable";
import { ArrowLeftIcon } from "../Icons";

import * as Styled from "./BackButton.styled";

const BackButton = (props: ClickableProps) => {
  return (
    <Styled.BackButtonWrapper
      hoverSize="md"
      hoverTargetColor="iconPrimaryHover"
      hoverTarget
      {...props}
    >
      <ArrowLeftIcon />
    </Styled.BackButtonWrapper>
  );
};

export default BackButton;
