import { KeyboardEvent } from "react";

import { ComponentWithChildren } from "@polyai/common/types/helpers";

import { ConfirmationIcon } from "components/atoms/Icons";
import PlayAudioCell from "components/molecules/TableCell/PlayAudioCell";

import * as Styled from "./Card.styled";

interface CardProps {
  title: string;
  description?: string;
  audioUrl?: string;
  selected?: boolean;
  onClick?: () => void;
  fullWidth?: boolean;
  className?: string;
  titleSize?: Styled.TitleSize;
  avatar?: JSX.Element;
  readonly?: boolean;
  onPlayAudio?: (audio: Howl | undefined) => void;
}

const Card: ComponentWithChildren<CardProps> = ({
  title,
  titleSize = "sm",
  children,
  description,
  selected,
  audioUrl = "",
  onClick,
  fullWidth,
  className,
  avatar = undefined,
  readonly = false,
  onPlayAudio,
}) => {
  const handleOnKeyDown = (event: KeyboardEvent) =>
    (event.key === "Enter" || event.key === "Space") && onClick && onClick();

  return (
    <Styled.CardWrapper
      $fullWidth={fullWidth}
      $selected={selected}
      className={className}
      tabIndex={onClick && 0}
      onClick={readonly ? undefined : onClick}
      onKeyDown={readonly ? undefined : handleOnKeyDown}
    >
      <Styled.CardTitleSection>
        <Styled.TopLevelSection>
          {audioUrl && (
            <div>
              <PlayAudioCell url={audioUrl} onPlayAudio={onPlayAudio} />
            </div>
          )}
          {avatar && <Styled.LogoWrapper>{avatar}</Styled.LogoWrapper>}
          <Styled.Title $size={titleSize}>{title}</Styled.Title>
        </Styled.TopLevelSection>

        {selected && <ConfirmationIcon />}
      </Styled.CardTitleSection>
      <Styled.CardDescription>{description}</Styled.CardDescription>
      {children}
    </Styled.CardWrapper>
  );
};

export default Card;
