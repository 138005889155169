import React, {
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { components, MultiValue } from "react-select";
import CreatableSelect from "react-select/creatable";
import { useTheme } from "styled-components";

import { CloseCircleIcon } from "components/atoms/Icons/CloseCircleIcon";
import InputWrapper, { InputWrapperProps } from "components/atoms/InputWrapper";

import { baseColors } from "styles/config/colors";
import { OptionType } from "../Select";

import * as Styled from "./CreateSelect.styled";

type CreateSelectProps = {
  options: OptionType[];
  defaultValue: OptionType[];
  placeholder: string;
  handleCreateOptionFail: () => void;
  onBlur?: (value: string[]) => void;
  onChange?: (value: string[]) => void;
  onCreateOption?: (value: string) => void;
  formatCreateLabel?: (userInput: string) => string;
  allowWhiteSpaceOnCreate?: boolean;
  charLimit?: number;
  charLimitWarningMessage?: string;
  noOptionsMessage?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  disableCreateOption?: boolean;
  hideOptionMenu?: boolean;
} & InputWrapperProps;

const CreateSelect: React.FC<CreateSelectProps> = ({
  options,
  placeholder,
  defaultValue,
  handleCreateOptionFail,
  onBlur,
  onChange,
  onCreateOption = () => {},
  formatCreateLabel,
  allowWhiteSpaceOnCreate = true,
  charLimit = 0,
  charLimitWarningMessage,
  noOptionsMessage = "No options",
  isDisabled = false,
  isLoading = false,
  disableCreateOption = false,
  hideOptionMenu = false,
  ...props
}) => {
  const [selected, setSelected] =
    useState<MultiValue<OptionType>>(defaultValue);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isOverCharLimit, setIsOverCharLimit] = useState(false);

  const {
    activeColorScheme: { colors },
  } = useTheme();

  useEffect(() => {
    setSelected(defaultValue);
  }, [defaultValue]);

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };
  const handleMenuClosed = () => {
    setMenuOpen(false);
    setIsOverCharLimit(false);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    // react-select issue: workaround to avoid refreshing the page when pressing enter
    if (!isMenuOpen && e.key === "Enter") {
      handleOnBlur();
      e.preventDefault();
    }

    e.stopPropagation();
  };

  const handleOnChange = useCallback(
    (selected: MultiValue<OptionType>) => {
      setSelected(selected);
      if (onChange) {
        onChange?.(selected.map((opt) => opt.value));
      }
    },
    [onChange]
  );

  const handleOnBlur = useCallback(() => {
    if (!onBlur) {
      return;
    }
    const values = selected.map((opt) => opt.value);
    onBlur(values);
  }, [onBlur, selected]);

  const handleOnCreateOption = useCallback(
    (value: string) => {
      if (onCreateOption && !isOverCharLimit) {
        try {
          const cleanedValue = allowWhiteSpaceOnCreate
            ? value
            : value.replace(/ /g, "_");

          onCreateOption(cleanedValue);
          handleOnChange([
            ...selected,
            { label: cleanedValue, value: cleanedValue },
          ]);
        } catch (e) {
          handleCreateOptionFail();
        }
      }
    },
    [
      allowWhiteSpaceOnCreate,
      handleCreateOptionFail,
      handleOnChange,
      isOverCharLimit,
      onCreateOption,
      selected,
    ]
  );

  const handleCreateLabel = useCallback(
    (inputValue: string) => {
      if (isOverCharLimit && charLimitWarningMessage) {
        return charLimitWarningMessage;
      }

      return formatCreateLabel
        ? formatCreateLabel(inputValue)
        : `Create "${inputValue}"`;
    },
    [charLimitWarningMessage, formatCreateLabel, isOverCharLimit]
  );

  const customStyles = useMemo(
    () => ({
      control: (base: any) => ({
        ...base,
        borderRadius: "4px",
        fontFamily: "Proxima Nova",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "14px",
        borderColor: colors.borderStrong,
        padding: "0.5rem",
        boxShadow: "none",
        "&:focus-within": {
          borderColor: isOverCharLimit
            ? baseColors.red["20"]
            : colors.borderPrimary,
        },
        "&:hover": {
          borderColor: colors.borderStrong,
          cursor: "text",
        },
      }),
      option: (base: any) => ({
        ...base,
        fontFamily: "Proxima Nova",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "14px",
        backgroundColor: isOverCharLimit ? "none" : base.backgroundColor,
      }),
      multiValue: (base: any) => ({
        ...base,
        borderRadius: "4px",
        border: `1px solid ${colors.borderStrong}`,
        padding: "0px",
        overflow: "hidden",
      }),
      multiValueLabel: (base: any) => ({
        ...base,
        backgroundColor: colors.backgroundPage,
        borderRadius: "0px",
        border: "none",
        padding: "0px",
        paddingTop: "2px",
      }),
      multiValueRemove: (base: any) => ({
        ...base,
        backgroundColor: colors.backgroundPage,
        borderRadius: "0px",
        padding: "0px",
        "&:hover": {
          backgroundColor: colors.backgroundPage,
          cursor: "pointer",
        },
      }),
      noOptionsMessage: (base: any) => ({
        ...base,
        textAlign: "left",
        fontFamily: "Proxima Nova",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "14px",
      }),
      menuList: (base: any) => ({
        ...base,
        color: isOverCharLimit ? colors.textHelper : base.color,
      }),
    }),
    [
      colors.borderStrong,
      colors.borderPrimary,
      colors.backgroundPage,
      colors.textHelper,
      isOverCharLimit,
    ]
  );

  const MultiValueRemove = (props: any) => (
    <div data-test-id={`remove-${props.data.label}`}>
      <components.MultiValueRemove {...props}>
        <CloseCircleIcon />
      </components.MultiValueRemove>
    </div>
  );

  const Menu = (props: any) => {
    const charLength = props.selectProps.inputValue.length;

    if (charLimit) {
      if (charLength > charLimit) {
        setIsOverCharLimit(true);
      } else {
        setIsOverCharLimit(false);
      }
    }

    if (hideOptionMenu) {
      return <></>;
    }

    return (
      <>
        <components.Menu {...props} />
        {charLimit && charLength > charLimit ? (
          <Styled.CharLimitError>
            {charLength}/{charLimit}
          </Styled.CharLimitError>
        ) : null}
      </>
    );
  };

  return (
    <InputWrapper {...props}>
      <CreatableSelect
        className="create-select-dropdown"
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          ClearIndicator: () => null,
          MultiValueRemove,
          Menu,
        }}
        defaultValue={defaultValue}
        formatCreateLabel={(inputValue) => handleCreateLabel(inputValue)}
        isDisabled={isLoading || isDisabled}
        isValidNewOption={disableCreateOption ? () => false : undefined}
        noOptionsMessage={({ inputValue }) => !inputValue && noOptionsMessage}
        options={options}
        placeholder={isLoading ? "Processing" : placeholder}
        styles={customStyles}
        value={isLoading ? [] : selected}
        isMulti
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        onCreateOption={handleOnCreateOption}
        onKeyDown={handleKeyDown}
        onMenuClose={handleMenuClosed}
        onMenuOpen={handleMenuOpen}
        {...props}
      />
    </InputWrapper>
  );
};

export default CreateSelect;
