import { FC, useEffect } from 'react';

import { useFieldArray, UseFormReturn } from 'react-hook-form';

import IconButton from '@polyai/ui/components/atoms/IconButton';
import { TrashCanIcon } from '@polyai/ui/components/atoms/Icons';
import Select  from '@polyai/ui/components/atoms/Select';
import { Stack } from '@polyai/ui/components/atoms/Stack';

import * as Styled from './EntityCollection.styled';

export enum EntityType {
  ALPHANUMERIC = 'alphanumeric',
  NAME = 'name',
  ADDRESS = 'address',
  YES_OR_NO = 'yes_or_no',
  NUMBER_OF_PEOPLE = 'pax',
  DATE = 'date',
  TIME = 'time',
}

enum ReadableEntityType {
  ALPHANUMERIC = 'Alphanumeric',
  NAME = 'Name',
  ADDRESS = 'Address',
  YES_OR_NO = 'Yes or No',
  NUMBER_OF_PEOPLE = 'Number of People',
  DATE = 'Date',
  TIME = 'Time',
}

export type Entity = {
  name: EntityType | '';
  values: string[];
};

export type AsrTasksFormData = {
  transcript: string;
  entities: Entity[];
};

type EntityCollectionProps = {
  formMethods: UseFormReturn<AsrTasksFormData>;
  asrTaskData: AsrTasksFormData;
};

const EntityCollection: FC<EntityCollectionProps> = ({
  formMethods,
  asrTaskData,
}) => {
  const { control, register, setValue } = formMethods;

  const fieldMethods = useFieldArray({
    control,
    name: 'entities',
  });

  const { fields, append, remove } = fieldMethods;

  // Appends additional row in entity collection box if the last row is filled
  // Only appends up to the number of entities available
  useEffect(() => {
    if (
      !!asrTaskData?.entities?.at(-1)?.name &&
      !!asrTaskData?.entities?.at(-1)?.values?.length &&
      asrTaskData.entities.length < Object.keys(EntityType).length
    ) {
      append(
        {
          name: '',
          values: [],
        },
        { shouldFocus: false },
      );
    }
  }, [asrTaskData, append]);

  const entityOptions = Object.entries(EntityType).map(([key, entity]) => ({
    label: ReadableEntityType[key as keyof typeof EntityType],
    value: entity,
  }));

  // If an option has been used then it will not be available for selection
  const availableEntityOptions = entityOptions.filter(
    (option) =>
      !asrTaskData?.entities?.find((entity) => entity.name === option.value),
  );

  const getOptionsForEntity = (idx: number) => {
    if (asrTaskData?.entities[idx]?.name === EntityType.YES_OR_NO) {
      return [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
      ]
    }
    return []
  }

  return (
    <Styled.EntityCollectionWrapper>
      {fields.map((row, index) => {
        const options = getOptionsForEntity(index);
        return (
          <Stack key={row.id} direction="row" spacing="sm1">
            <Select
              {...register(`entities.${index}.name`)}
              defaultValue={entityOptions.find(
                (option) => option.value === row.name,
              )}
              label={index === 0 ? 'Entity' : undefined}
              options={availableEntityOptions}
              fullWidth
              onChange={(newValue) => {
                setValue(
                  `entities.${index}.name`,
                  (newValue as { value: EntityType })?.value || '',
                );
              }}
            />
            <Styled.EntityValueMultiInput
              {...register(`entities.${index}.values`)}
              defaultValue={asrTaskData?.entities[index]?.values?.map(
                (value) => ({
                  label: value,
                  value,
                }),
              )}
              formatCreateLabel={(userInput) => userInput}
              handleCreateOptionFail={() => {}}
              help="Press enter after typing to add the value"
              hideOptionMenu={options.length === 0}
              label={index === 0 ? 'Values' : undefined}
              options={options}
              placeholder="Type in value and hit enter"
              fullWidth
              onBlur={(value) => {
                setValue(`entities.${index}.values`, value);
              }}
              onChange={(value) => {
                setValue(`entities.${index}.values`, value);
              }}
            />
            <IconButton
              disabled={
                availableEntityOptions.length
                  ? index === fields.length - 1
                  : false
              }
              onClick={() => {
                remove(index);
              }}
            >
              <TrashCanIcon />
            </IconButton>
          </Stack>
        )
      })}
    </Styled.EntityCollectionWrapper>
  );
};

export default EntityCollection;
