import { useTheme } from "styled-components";

import { ComponentWithChildren } from "@polyai/common/types/helpers";

import { ColorName } from "styles/config/colors";

import * as Styled from "./Badge.styled";

type BadgeProps = {
  background?: ColorName;
  color?: ColorName;
  className?: string;
  "data-test-id"?: string;
};

const Badge: ComponentWithChildren<BadgeProps> = ({
  background = "backgroundPage",
  color = "textSecondary",
  className,
  children,
  ...props
}) => {
  const {
    activeColorScheme: { colors: activeColors },
  } = useTheme();

  return (
    <Styled.Badge
      {...props}
      $background={activeColors[background]}
      $color={activeColors[color]}
      className={className}
    >
      {children}
    </Styled.Badge>
  );
};

export default Badge;
