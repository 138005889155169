import ENDPOINTS from 'api/endpoints';
import { doDelete, doGet, doPatch, doPost } from 'api/request';
import { LOGGER_TYPE } from 'api/types';
import { generateNextPath } from 'lib/routeHelper';
import { store } from 'reduxStore';
import {
  PatchStopKeyword,
  PostStopKeyword,
  StopKeywordResponse,
  StopKeywordsResponse,
} from './types';

export const getStopKeywords = () => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  return doGet<StopKeywordsResponse>(
    generateNextPath(ENDPOINTS.STOP_KEYWORDS, { accountId, projectId }),
    LOGGER_TYPE.JUPITER,
    { version: 'v2' },
  );
};

export const getStopKeyword = (stopKeywordId: string) => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  return doGet<StopKeywordResponse>(
    generateNextPath(ENDPOINTS.STOP_KEYWORD, {
      accountId,
      projectId,
      stopKeywordId,
    }),
    LOGGER_TYPE.JUPITER,
    { version: 'v2' },
  );
};

export const postStopKeyword = (stopKeywords: PostStopKeyword) => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  return doPost<StopKeywordResponse>(
    generateNextPath(ENDPOINTS.STOP_KEYWORDS, {
      accountId,
      projectId,
    }),
    LOGGER_TYPE.JUPITER,
    stopKeywords,
    { version: 'v2' },
  );
};

export const patchStopKeyword = ({ id, ...stopKeywords }: PatchStopKeyword) => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  return doPatch<StopKeywordResponse>(
    generateNextPath(ENDPOINTS.STOP_KEYWORD, {
      accountId,
      projectId,
      stopKeywordId: id,
    }),
    LOGGER_TYPE.JUPITER,
    stopKeywords,
    { version: 'v2' },
  );
};

export const deleteStopKeyword = (id: StopKeywordResponse['id']) => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  return doDelete<{ success: boolean }>(
    generateNextPath(ENDPOINTS.STOP_KEYWORD, {
      accountId,
      projectId,
      stopKeywordId: id,
    }),
    LOGGER_TYPE.JUPITER,
    undefined,
    { version: 'v2' },
  );
};
