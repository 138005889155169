import ENDPOINTS from 'api/endpoints';
import { doPost } from 'api/request';
import { doGet, doPatch } from 'api/request';
import { LOGGER_TYPE } from 'api/types';
import { generateNextPath } from 'lib/routeHelper';
import { store } from 'reduxStore';
import {
  ImportKnowledgeBaseSpreadSheetResponse,
  KnowledgeBaseRes,
  KnowledgeBaseUpdate,
} from './types';

export const postImportTopics = (file: File) => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;
  const url = `accounts/${accountId}/projects/${projectId}/knowledge-base/import?type=csv`;
  const bodyFormData = new FormData();
  const blob = new Blob([file], { type: 'csv' });
  bodyFormData.append('csv', blob);

  return doPost<ImportKnowledgeBaseSpreadSheetResponse>(
    url,
    LOGGER_TYPE.JUPITER,
    bodyFormData,
    {
      additionalHeaders: {
        Accept: '*/*',
        'Accept-Encoding': 'gzip, deflate, br',
      },
      postingFile: true,
      version: 'v2',
      parseResponseToJSON: true,
    },
  );
};

export const getKnowledgeBase = (projectId: string) => {
  const accountId = store.getState().account.id;

  return doGet<KnowledgeBaseRes>(
    generateNextPath(ENDPOINTS.KNOWLEDGE_BASE, { accountId, projectId }),
    LOGGER_TYPE.JUPITER,
    { version: 'v2' },
  );
};

export const updateKnowledgeBase = ({
  projectId,
  update,
}: {
  projectId: string;
  update: KnowledgeBaseUpdate;
}) => {
  const accountId = store.getState().account.id;

  return doPatch<KnowledgeBaseRes>(
    `accounts/${accountId}/projects/${projectId}/knowledge-base`,
    LOGGER_TYPE.JUPITER,
    update,
    { version: 'v2' },
  );
};
