import {
  ConfirmationModal,
  ConfirmationModalProps,
} from '@polyai/ui/components/molecules/ConfirmationModal';

import { UnsavedChangesModalUICopy } from './types';
import { UNSAVED_CHANGES_MODAL } from './UnsavedChangesModal.uiCopy';

interface UnsavedChangesModalType extends ConfirmationModalProps {
  uiCopy?: UnsavedChangesModalUICopy;
}

const UnsavedChangesModal: React.FC<UnsavedChangesModalType> = ({
  uiCopy = UNSAVED_CHANGES_MODAL,
  ...props
}) => {
  return (
    <ConfirmationModal
      buttonText={uiCopy.button.confirm}
      cancelText={uiCopy.button.cancel}
      closeOnDocumentClick={false}
      closeOnEscape={false}
      confirmButtonVariant="DANGER"
      subtitle={uiCopy.description}
      title={uiCopy.title}
      disableConfirmOnEnter
      hasCancelButton
      {...props}
    />
  );
};

export default UnsavedChangesModal;
