import { datadogLogs } from '@datadog/browser-logs';
import queryString from 'query-string';
import { v4 as uuidv4 } from 'uuid';

import { store } from '../reduxStore';
import { HTTPError, HTTPErrorResponse } from './error';
import { HttpStatusCode, LOG_ERROR_CATEGORY, LOGGER_TYPE } from './types';

async function _fetch(
  resource: string,
  logger: LOGGER_TYPE,
  opts: any,
  overwriteUrl: string = '',
  additionalHeaders: any = {},
  downloadingFile: boolean = false,
  formData: boolean = false,
  returnStatusCode: boolean = false,
  version: string = 'v1',
  noAuth: boolean = false,
) {
  const correlationID = uuidv4();
  const ddLogger = datadogLogs.getLogger(logger);

  opts = {
    ...opts,
    credentials: 'same-origin', // pass cookies, for authentication
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Polyai-Correlation-Id': correlationID,
    },
  };

  if (formData) {
    delete opts.headers['Content-Type'];
  }

  opts['headers'] = { ...opts.headers, ...additionalHeaders };

  const token = await store.getState().auth.getAuthToken();
  const login = store.getState().auth.login;
  if (token && !noAuth) {
    opts.headers['Authorization'] = `Bearer ${token}`;
  } else if (!!ddLogger && !noAuth) {
    const project_id = store.getState().project.id || '*';
    const account_id = store.getState().account.id || '*';

    ddLogger.error('Auth token not found', {
      correlation_id: correlationID,
      resource,
      error_message: 'Auth token not found',
      error_category: LOG_ERROR_CATEGORY.SYSTEM,
      project_id,
      account_id,
    });
  }

  const res = await fetch(
    overwriteUrl ||
      `${process.env.NEXT_PUBLIC_POLYAI_API}/jupiter/${version}/${resource}`,
    opts,
  );

  if (res.ok) {
    const message = `Successful response from ${resource}`;
    if (!!ddLogger) {
      const project_id = store.getState().project.id || '*';
      const account_id = store.getState().account.id || '*';
      ddLogger.info(message, {
        correlation_id: correlationID,
        resource,
        success_message: message,
        status_code: res.status,
        project_id,
        account_id,
      });
    } else {
      console.log(`Missing DataDog Logger: ${logger}`);
    }

    if (downloadingFile) {
      const response = await res.blob();

      if (returnStatusCode) {
        return {
          data: response,
          headers: res.headers,
          statusCode: res.status as HttpStatusCode,
        };
      }

      return response;
    }
    const json =
      res.status !== HttpStatusCode.SuccessNoContent ? await res.json() : {};

    if (returnStatusCode) {
      return {
        data: json,
        statusCode: res.status as HttpStatusCode,
      };
    }

    return json;
  } else {
    if (res.status === HttpStatusCode.ClientErrorUnauthorized) {
      login(window.location.href);
      return;
    }

    const {
      error_message: message,
      error_code: code,
      error_id: id,
      data,
    }: HTTPErrorResponse = await res.json();

    const error = new HTTPError(code, message, res.status, code, id, data);

    // log responses on DataDog
    if (!!ddLogger) {
      const project_id = store.getState().project.id || '*';
      const account_id = store.getState().account.id || '*';

      ddLogger.error(error.message, {
        correlation_id: correlationID,
        resource,
        error_message: error,
        project_id,
        account_id,
      });
    } else {
      console.log(`Missing DataDog Logger: ${logger}`);
    }
    throw error;
  }
}

export async function doGet<T extends any = any>(
  resource: string,
  logger: LOGGER_TYPE,
  {
    params,
    query = '',
    acceptHeader = {},
    downloadingFile = false,
    returnStatusCode,
    version,
    overwriteUrl = '',
    noAuth = false,
  }: {
    params?: any;
    query?: string;
    acceptHeader?: any;
    downloadingFile?: boolean;
    returnStatusCode?: boolean;
    version?: string;
    overwriteUrl?: string;
    noAuth?: boolean;
  } = {},
): Promise<T> {
  const opts = {
    method: 'GET',
  };
  const queryString = generateQueryString(params, query);
  return _fetch(
    resource + queryString,
    logger,
    opts,
    overwriteUrl,
    acceptHeader,
    downloadingFile,
    false,
    returnStatusCode,
    version,
    noAuth,
  );
}

function generateQueryString(params: any, query: string) {
  const qs = params
    ? queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
      })
    : '';

  const cleanedQueryString = qs ? '?' + qs : '';

  let _query = cleanedQueryString ? `&${query}` : `?${query}`;
  if (_query.length === 1) {
    _query = '';
  }

  return cleanedQueryString + _query;
}

export async function doPatch<T extends any = any>(
  resource: string,
  logger: LOGGER_TYPE,
  body: any = {},
  {
    additionalHeaders = {},
    postingFile = false,
    returnStatusCode,
    version,
  }: {
    additionalHeaders?: any;
    postingFile?: boolean;
    returnStatusCode?: boolean;
    version?: string;
  } = {},
) {
  const opts = {
    method: 'PATCH',
    body: postingFile ? body : JSON.stringify(body),
  };

  return _fetch(
    resource,
    logger,
    opts,
    undefined,
    additionalHeaders,
    false,
    postingFile,
    returnStatusCode,
    version,
  ) as Promise<T>;
}

export async function doPut<T extends any = any>(
  resource: string,
  logger: LOGGER_TYPE,
  body: any = {},
  {
    overwriteUrl = '',
    additionalHeaders = {},
    postingFile = false,
    returnStatusCode,
    version,
  }: {
    overwriteUrl?: string;
    additionalHeaders?: any;
    postingFile?: boolean;
    returnStatusCode?: boolean;
    version?: string;
  } = {},
) {
  const opts = {
    method: 'PUT',
    body: postingFile ? body : JSON.stringify(body),
  };

  return _fetch(
    resource,
    logger,
    opts,
    overwriteUrl,
    additionalHeaders,
    false,
    postingFile,
    returnStatusCode,
    version,
  ) as Promise<T>;
}

export async function doPost<T extends any = any>(
  resource: string,
  logger: LOGGER_TYPE,
  body: any = {},
  {
    overwriteUrl = '',
    additionalHeaders = {},
    postingFile = false,
    downloadingFile = false,
    returnStatusCode,
    version,
    parseResponseToJSON,
  }: {
    overwriteUrl?: string;
    additionalHeaders?: any;
    postingFile?: boolean;
    downloadingFile?: boolean;
    returnStatusCode?: boolean;
    version?: string;
    /* Posting a file would result in a blob response, use this to make it JSON format */
    parseResponseToJSON?: boolean;
  } = {},
) {
  const opts = {
    method: 'POST',
    body: postingFile ? body : JSON.stringify(body),
  };

  return _fetch(
    resource,
    logger,
    opts,
    overwriteUrl,
    additionalHeaders,
    (postingFile && !parseResponseToJSON) || downloadingFile,
    postingFile,
    returnStatusCode,
    version,
  ) as Promise<T>;
}

export async function doDelete<T extends any = any>(
  resource: string,
  logger: LOGGER_TYPE,
  body: any = {},
  {
    returnStatusCode,
    version,
  }: { returnStatusCode?: boolean; version?: string } = {},
) {
  const opts = {
    method: 'DELETE',
    body: JSON.stringify(body),
  };

  return _fetch(
    resource,
    logger,
    opts,
    '',
    {},
    false,
    false,
    returnStatusCode,
    version,
  ) as Promise<T>;
}
