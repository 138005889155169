import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { projectInit } from 'actions';

export interface AccountState {
  id: string;
}

const initialState: AccountState = {
  id: '',
};

const accountSlice = createSlice({
  name: 'accountInfo',
  initialState,
  reducers: {
    setAccountId: (state, id: PayloadAction<string>) => {
      state.id = id.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(projectInit, () => {
      return { id: '' };
    });
  },
});

export const { setAccountId } = accountSlice.actions;

export default accountSlice.reducer;
