import { css } from "styled-components";

import themeColors from "styles/config/colors";
import themeSpacing from "styles/config/spacing";

export const ScrollbarStyles = css`
  &::-webkit-scrollbar {
    width: ${themeSpacing.xs2};
    border-radius: ${themeSpacing.radiusFull};
  }

  &::-webkit-scrollbar-thumb {
    background: ${themeColors.textSecondary};
    border-radius: ${themeSpacing.radiusFull};
  }

  /* Make the scrollbar always visible */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  }
`;
